import dayjs from 'dayjs';
import { forEach, invert, isNumber } from 'lodash/fp';
import { fastProp } from '@betalpha/stdlib/lib/base';
import { EChartsOption } from 'echarts';

export const getLastDateOfYear = (processedDates: string[]) => {
  const dates: Record<string, string> = {};
  forEach((date: string) => {
    const dateYear = dayjs(date).get('year').toString();
    const prevDate = fastProp(dateYear.toString())(dates);
    if (!prevDate || date > prevDate) {
      dates[dateYear] = date;
    }
  })(processedDates);
  return invert(dates);
};

export const getChartDataZoomConfig = () => ({
  start: 0,
  end: 100,
  moveHandleSize: 0,
  realtime: false,
  brushSelect: false,
  showDetail: false,
  fillerColor: 'rgba(212, 230, 255, 0.6)',
  handleIcon:
    'path://M8,0.5C8.414213180541992,0.5,8.789213180541992,0.6678932309150696,9.060660362243652,0.939339816570282C9.332106590270996,1.2107864618301392,9.5,1.5857864618301392,9.5,2L9.5,2L9.5,22C9.5,22.414213180541992,9.332106590270996,22.789213180541992,9.060660362243652,23.060659408569336C8.789213180541992,23.332107543945312,8.414213180541992,23.5,8,23.5L8,23.5L2,23.5C1.5857864618301392,23.5,1.2107864618301392,23.332107543945312,0.939339816570282,23.060659408569336C0.6678932309150696,22.789213180541992,0.5,22.414213180541992,0.5,22L0.5,22L0.5,2C0.5,1.5857864618301392,0.6678932309150696,1.2107864618301392,0.939339816570282,0.939339816570282C1.2107864618301392,0.6678932309150696,1.5857864618301392,0.5,2,0.5L2,0.5Z',
  handleStyle: {
    shadowColor: 'rgba(194, 15, 15, 1)'
  },
  dataBackground: {
    areaStyle: {
      opacity: 0
    }
  },
  selectedDataBackground: {
    lineStyle: {
      color: '#90C1FF'
    },
    areaStyle: {
      opacity: 0
    }
  },
  moveHandleStyle: {
    borderCap: 'square'
  }
});

export const getBarChartDataZoomConfig = (bottom: number, end = 50): EChartsOption['dataZoom'] => {
  if (end >= 100) return { show: false };
  return [
    {
      type: 'inside',
      start: 0,
      end,
      xAxisIndex: 0,
      throttle: 0,
      filterMode: 'none',
      preventDefaultMouseMove: false,
      zoomLock: true
    },
    {
      type: 'slider',
      start: 0,
      end,
      bottom,
      realtime: true,
      filterMode: 'none',
      throttle: 0,
      zoomLock: true,
      brushSelect: false,
      showDetail: false,
      fillerColor: '#CFB47F',
      height: 10,
      width: 140,
      left: 'center',
      borderColor: '#fff',
      backgroundColor: '#E6ECF5',
      moveHandleSize: 0,
      selectedDataBackground: {
        lineStyle: {
          opacity: 0
        },
        areaStyle: {
          opacity: 0
        }
      },
      dataBackground: {
        lineStyle: {
          opacity: 0
        },
        areaStyle: {
          opacity: 0
        }
      },
      handleIcon: `path://M 100, 100
    m -75, 0
    a 75,75 0 1,0 150,0
    a 75,75 0 1,0 -150,0`,
      handleSize: '70%',
      handleStyle: {
        color: '#CFB47F',
        borderWidth: 0
      },
      z: 2,
      zlevel: 2
    }
  ];
};

export const DEFAULT_WIDTH = '100%';
export const DEFAULT_HEIGH = 400;

export const getStyle = (width: string | number = DEFAULT_WIDTH, height: string | number = DEFAULT_HEIGH) => ({
  width: isNumber(width) ? `${width}px` : width,
  height: isNumber(height) ? `${height}px` : height
});
