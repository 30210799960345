import { getMessage } from '@betalpha/intl';
import { useMemo } from 'react';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';
import { CHART_COLOR } from '@/views/fund-evaluation-detail/constants';
import { ChartProps } from '@/base-components/charts/yieldTrendChart/type';
import { ETFPerformanceTrendBody, SectionReturnType } from '@/interface/etfFund';

export const useGetPerformanceTrend = (data: ETFPerformanceTrendBody | undefined) => {
  const { cumulativePerformanceTrend } = data || {};

  const { fundCumulativeReturn, benchmarkCumulativeReturn, dates, excessCumulativeReturn } =
    cumulativePerformanceTrend || {};

  const series = useMemo(() => {
    return [
      {
        name: getMessage('theFund'),
        color: CHART_COLOR[0],
        dates,
        returns: fundCumulativeReturn
      },
      {
        name: getMessage('csIndex800'),
        color: CHART_COLOR[1],
        dates,
        returns: benchmarkCumulativeReturn
      },
      {
        name: getMessage('returnExcess'),
        color: CHART_COLOR[2],
        dates,
        returns: excessCumulativeReturn
      }
    ];
  }, [benchmarkCumulativeReturn, dates, excessCumulativeReturn, fundCumulativeReturn]);

  const chartOptions = useMemo<Partial<ChartProps>>(
    () => ({
      xAxisOptions: {
        axisLabel: {
          color: '#666',
          fontSize: 10,
          margin: 14
        },
        axisTick: { show: false },
        axisLine: { onZero: true }
      },
      yAxisOptions: {
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'dotted'
          }
        },
        axisLabel: {
          formatter: (val: number) => percentage(val, { digit: 0 }),
          fontFamily: 'DINAlternate',
          fontWeight: 400,
          fontSize: 10,
          color: '#666'
        }
      }
    }),
    []
  );

  return useMemo(
    () => ({
      series,
      dates: dates || [],
      chartOptions
    }),
    [chartOptions, dates, series]
  );
};

export const PERIOD: (keyof SectionReturnType)[] = ['RECENT_WEEK', 'RECENT_MONTH', 'RECENT_THREE_MONTH', 'RECENT_YEAR'];
