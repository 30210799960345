import React, { useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { isEmpty, merge, reverse } from 'lodash/fp';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';

import YieldTrendChart from '@/base-components/charts/yieldTrendChart';
import { useGetFundPerformanceQuery } from '@/infra/api/funds';
import { ChartProps } from '@/base-components/charts/yieldTrendChart/type';

import CardLayout from '@/components/cardLayout';
import EmptyContent from '@/components/emptyContent';
import ColorNumber from '../../../../components/colorNumber';
import style from './index.module.less';
import { useGetPerformanceTrend } from './hooks';
import YearYieldTable from './year-yield-table';

interface HomeViewProps {
  fundId?: string;
}

const useColumns = () => {
  return useMemo(
    () => [
      { dataIndex: 'year', title: getMessage('period') },
      {
        dataIndex: 'five',
        title: getMessage('fiveStarPortfolio'),
        render: (value: number) => <ColorNumber formatter={percentage} value={value} />
      },
      {
        dataIndex: 'one',
        title: getMessage('oneStarPortfolio'),
        render: (value: number) => <ColorNumber formatter={percentage} value={value} />
      },
      {
        dataIndex: 'benchmark',
        title: getMessage('csIndex800'),
        render: (value: number) => <ColorNumber formatter={percentage} value={value} />
      }
    ],
    []
  );
};

export default React.memo<HomeViewProps>(() => {
  const { data, isFetching } = useGetFundPerformanceQuery();
  const { dates, series, chartOptions } = useGetPerformanceTrend(data);
  const { yearlyReturn } = data || {};
  const dataSource = useMemo(() => {
    return reverse(
      yearlyReturn?.dates?.map((year, index) => ({
        year,
        five: yearlyReturn?.fiveStarFundCumulativeReturn?.[index],
        one: yearlyReturn?.oneStarFundCumulativeReturn?.[index],
        benchmark: yearlyReturn?.benchmarkCumulativeReturn?.[index]
      })) || []
    );
  }, [yearlyReturn]);
  const columns = useColumns();

  const finalChartOptions = merge(chartOptions)({
    legendOptions: {
      bottom: 0,
      top: 16,
      left: 'left',
      itemWidth: 11,
      itemGap: 12,
      textStyle: {
        fontSize: 10
      }
    },
    gridOptions: {
      top: 48,
      bottom: 0,
      left: 'left'
    },
    yAxisOptions: {
      axisLabel: {
        color: '#666',
        formatter: (val: number) => percentage(val, { digit: 0 }),
        fontWeight: 400,
        fontSize: 10
      }
    },
    xAxisOptions: {
      axisLabel: {
        color: '#666',
        fontSize: 10
      },
      axisTick: { show: false }
    }
  } as Partial<ChartProps>);

  return (
    <CardLayout
      title={<h1>{getMessage('recentPerformanceOfFiveStarFund')}</h1>}
      className={style.RecentPerformanceWrap}
      loading={isFetching}
    >
      <div className={style.ChartWrap}>
        {!(isEmpty(dates) || isEmpty(series)) ? (
          <YieldTrendChart
            dates={dates}
            series={series}
            showDataZoom={false}
            height={200}
            onZero
            {...finalChartOptions}
          />
        ) : (
          <EmptyContent message={getMessage('noData')} />
        )}
      </div>
      <YearYieldTable dataSource={dataSource || []} columns={columns} />
    </CardLayout>
  );
});
