// TO-DO ===> swc的browserslist的polyfill似乎暂时不生效，目前先全部引入polyfill，后续再使用usage的方式
import 'core-js';
import React from 'react';
import { Provider as IntlProvider } from '@betalpha/intl';
import { useMemoizedFn } from 'ahooks';
import { ConfigProvider as MobileConfigProvider } from 'antd-mobile';
import ZhCn from 'antd-mobile/es/locales/zh-CN';
import { ConfigProvider } from '@betalpha/alpha-design';
import ReactDOM from 'react-dom/client';
import { ReduxStoreProvider } from './infra/store';
import { ReduxRouter } from './infra/router';
import './index.less';
import ErrorBoundary from './components/error-boundary';
import './infra/api/setup';
import reportWebVitals from './reportWebVitals';

const App = ({ children }: { children: React.ReactNode }) => {
  const importI18nFunc = useMemoizedFn((i18nKey: string) => import(`@/locales/${i18nKey}.json`));
  return (
    <ErrorBoundary>
      <ConfigProvider>
        <MobileConfigProvider locale={ZhCn}>
          <IntlProvider importI18nFunc={importI18nFunc}>
            <ReduxStoreProvider>
              <ReduxRouter>{children}</ReduxRouter>
            </ReduxStoreProvider>
          </IntlProvider>
        </MobileConfigProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export const appCreator = (Routes: React.ReactElement) => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <App>{Routes}</App>
    </React.StrictMode>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
