import { getMessage } from '@betalpha/intl';
import {
  includes,
  isEmpty,
  keys,
  map,
  prop,
  values,
  identity,
  orderBy,
  flow,
  take,
  concat,
  takeRight,
  split,
  join
} from 'lodash/fp';
import React, { useMemo, useRef } from 'react';
import { EChartsOption } from 'echarts';
import { mapIndexed } from '@betalpha/stdlib/base';
import { useSize } from 'ahooks';
import EmptyContent from '@/components/emptyContent';
import BaseEcharts from '@/components/baseEcharts';
import { fixedHundredMillion, formatPercentage } from '@/util/numberFormatter';
import { useGetMarketMacroSummaryQuery } from '@/infra/api/etf-funds';
import triangleSvg from '@/assets/etfCompass/triangle.svg';

import { getBarChartDataZoomConfig } from '@/util/chart';
import style from '../index.module.less';

const triangleImg = new Image();
triangleImg.src = triangleSvg;

export default React.memo(() => {
  const { data } = useGetMarketMacroSummaryQuery();

  const { marketLiquidity, marketExpansionTrend } = data || {};

  const { industrySectionIncrease, industrySectionNetValueInflow, sharesIncrease } = marketLiquidity || {};

  const { top3IncreaseIndustry } = marketExpansionTrend || {};

  const sortSharesIncrease = useMemo(() => {
    const sortValue = values(sharesIncrease)?.sort((a, b) => b - a);
    const res: Record<string, any> = {};
    const keyData = keys(sharesIncrease);

    mapIndexed((_: any, i: number) => {
      keyData?.forEach((item) => {
        if (sortValue[i] === sharesIncrease?.[item]) {
          res[item] = sortValue[i];
        }
      });
    })(sortValue);
    return res;
  }, [sharesIncrease]);

  const inflowData = useMemo(
    () =>
      flow(
        mapIndexed((flowVal: number, industry: string) => [industry, flowVal]),
        orderBy('1', 'desc')
      )(industrySectionIncrease),
    [industrySectionIncrease]
  );

  const [top3, last3] = useMemo(() => {
    const inflowIndustries = map('0')(inflowData);
    return [take(3)(inflowIndustries), takeRight(3)(inflowIndustries)];
  }, [inflowData]);

  const chartWrapRef = useRef(null);
  const { width: chartWrapWidth } = useSize(chartWrapRef) ?? {};

  const options = useMemo<EChartsOption>(
    () => ({
      grid: {
        top: 24,
        right: 20,
        bottom: 120,
        left: 40
      },
      tooltip: {
        show: false
      },
      xAxis: {
        type: 'category',
        axisLine: {
          symbol: ['none', 'triangle'],
          symbolSize: [8, 8],
          onZero: false,
          lineStyle: {
            color: '#000'
          }
        },
        axisTick: { show: false },
        splitLine: {
          show: false
        },
        axisLabel: {
          color: '#666666',
          hideOverlap: false,
          fontSize: 9,
          interval: 0,
          lineHeight: 12,
          formatter: (industry: string) => {
            const res = flow(split(''), join('\n'))(industry);
            if (includes(industry)(top3)) return `{topIndustryFont|${res}}`;
            return res;
          },
          rich: {
            topIndustryFont: { color: '#0c172d', fontSize: 9, lineHeight: 12, fontWeight: 400 }
          },
          fontWeight: 400
        }
      },
      yAxis: [
        {
          type: 'value',
          axisLine: {
            show: true,
            symbol: ['none', 'triangle'],
            symbolSize: [8, 8],
            lineStyle: {
              color: '#000'
            }
          },
          splitLine: {
            show: false
          },
          axisTick: { show: false },
          name: flow(split(''), join('\n'))(getMessage('rangeDeclineIncrease')),
          nameLocation: 'middle',
          nameGap: 24,
          nameRotate: 0,
          nameTextStyle: {
            color: '#0C172D',
            lineHeight: 18,
            fontSize: 10,
            fontWeight: 400
          },
          axisLabel: {
            color: '#666666',
            fontSize: 10,
            fontWeight: 400,
            formatter: (value: number) => formatPercentage(value, { digit: 0 })
          }
        }
      ],
      dataZoom: getBarChartDataZoomConfig(4, ((chartWrapWidth ?? 339) / 339) * 30),
      animation: false,
      series: [
        {
          silent: true,
          symbolSize: ([industry]: [string, number]) => {
            if (includes(industry)(top3) || includes(industry)(last3)) {
              return 8;
            }
            return 6;
          },
          data: inflowData,
          type: 'scatter',
          labelLayout: () => {
            return {
              dy: -10
            };
          },
          label: {
            show: true,
            opacity: 0.6,
            align: 'center',
            rich: {
              positiveNumber: {
                backgroundColor: '#EAEAEA',
                fontWeight: 400,
                padding: 2,
                borderRadius: 3,
                color: '#DD314C'
              },
              negativeNumber: {
                backgroundColor: '#EAEAEA',
                fontWeight: 400,
                padding: 2,
                borderRadius: 3,
                color: '#0A9F5B'
              },
              triangle: {
                backgroundColor: {
                  image: triangleImg
                }
              }
            },
            formatter: (param: any) => {
              const netValue = industrySectionNetValueInflow?.[param.name] ?? NaN;

              if (netValue >= 0) {
                return `{positiveNumber|+${fixedHundredMillion(netValue)}}\n{triangle|}`;
              }
              return `{negativeNumber|${fixedHundredMillion(netValue)}}\n{triangle|}`;
            }
          },
          itemStyle: {
            color: (params: any) => {
              const industryName = params?.name;
              if (includes(industryName)(top3)) {
                return '#CFB47F';
              }
              if (includes(industryName)(last3)) {
                return '#1A396A';
              }
              return '#9CACBC';
            }
          }
        }
      ]
    }),
    [chartWrapWidth, industrySectionNetValueInflow, inflowData, last3, top3]
  );

  const sharesOptions = useMemo<EChartsOption>(
    () => ({
      grid: { top: 23, left: 30, bottom: 110 },
      legend: { show: false },
      yAxis: {
        axisTick: { show: false },
        axisLine: { show: false },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#D2D5D9',
            type: 'dotted'
          }
        },
        axisLabel: {
          fontSize: 10,
          color: '#666666',
          fontWeight: 400,
          formatter: (value: number) => formatPercentage(value, { digit: 0 })
        }
      },
      xAxis: {
        boundaryGap: true,
        axisLabel: {
          fontSize: 10,
          color: (industryName?: any) => {
            const val = prop(industryName || '')(sharesIncrease);
            const valueTop5 = flow(values, orderBy(identity, 'desc'), take(5))(sharesIncrease);
            if (includes(val || null)(valueTop5 as any)) {
              return '#222222';
            }
            return '#666666';
          },
          fontWeight: 400,
          overflow: 'break',
          hideOverlap: false,
          interval: 0,
          lineHeight: 13,
          formatter: (value: string) => {
            return value?.split('').join('\n');
          }
        }
      },
      series: {
        type: 'bar',
        silent: true,
        itemStyle: {
          color: (param: any) => {
            const valueTop5 = flow(values, orderBy(identity, 'desc'), take(5))(sharesIncrease);
            if (includes(param?.value[1])(valueTop5)) {
              return '#CFB47F';
            }
            return '#1A396A';
          }
        },
        label: {
          show: true
        },
        data: flow(
          keys,
          map((key: string) => [key, prop(key)(sortSharesIncrease)]),
          (res) => concat(take(5)(res))(takeRight(5)(res)),
          map((item: [string, number]) => ({
            value: item,
            label: {
              position: item[1] > 0 ? 'top' : 'bottom'
            }
          }))
        )(sortSharesIncrease) as [string, number][]
      }
    }),
    [sortSharesIncrease, sharesIncrease]
  );

  return (
    <div>
      <div className={style.MarketValuationSummary}>
        {getMessage('etfIndustryRecentSituationSummary', {
          industryName: keys(top3IncreaseIndustry)?.join('、')
        })}
      </div>
      <div className={style.EchartName}>{getMessage('IndustryETFRangeGrowthAndNetInflow')}</div>
      <div className={style.ChartTip}>{getMessage('IndustryRecentSituationChartTip')}</div>
      {isEmpty(industrySectionIncrease) ? (
        <EmptyContent className={style.EmptyContent}>
          <strong>{getMessage('noData')}</strong>
        </EmptyContent>
      ) : (
        <div className={style.ChartWrap} ref={chartWrapRef}>
          <BaseEcharts options={options} height={340} notMerge />
          <div className={style.ScrollBarMask} style={{ bottom: 3 }} />
        </div>
      )}
      <div className={style.EchartName}>{getMessage('shareIncrease')}</div>
      {isEmpty(industrySectionIncrease) ? (
        <EmptyContent className={style.EmptyContent}>
          <strong>{getMessage('noData')}</strong>
        </EmptyContent>
      ) : (
        <BaseEcharts options={sharesOptions} height={450} />
      )}
    </div>
  );
});
