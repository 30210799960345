import { getMessage } from '@betalpha/intl';
import { useNavigate } from '@betalpha/router';
import { map, isEmpty, prop } from 'lodash/fp';
import React, { useCallback } from 'react';
import { RootState, useDispatch, useSelector } from '@/infra/store';
import { IndustryModelIcons, modelIndustryType } from '@/views/fund-compass-home/contants';
import ColorNumber from '@/components/colorNumber';
import EmptyContent from '@/components/emptyContent';
import Icon from '@/components/icon';
import { ReactComponent as DetailIndustryModelIcon } from '@/assets/industryModel/detailIndustryIcon.svg';
import industryIcons from '@/assets/industryModel';
import { changeIndustryKey } from '@/infra/store/etfFundSlice';

import { formatPercentage } from '@/util/numberFormatter';
import { useGetIndustryModelQuery } from '@/infra/api/etf-funds';
import { IndustryProp } from '@/interface/etfFund';

import style from '../index.module.less';

type industryTabProp = {
  id: string;
  message: string;
};

export default React.memo(() => {
  const dispatch = useDispatch();

  const currentTab = useSelector((state: RootState) => state?.etfFundHome?.industryKey);

  const handleTabClick = useCallback(
    (val: string) => {
      dispatch(changeIndustryKey(val));
    },
    [dispatch]
  );

  const { data } = useGetIndustryModelQuery();

  const { focusIndustries, topFocusIndustries } = data || {};

  const navigateTo = useNavigate();

  const handleFundClick = useCallback(
    (fundId: string) => {
      navigateTo(`${fundId}`);
    },
    [navigateTo]
  );

  return (
    <div>
      <div className={style.Tabs}>
        {map((item: industryTabProp) => {
          const { id, message } = item ?? {};
          return (
            <div key={id} className={id === currentTab ? style.ActiveTab : ''} onClick={() => handleTabClick(id)}>
              {getMessage(message)}
            </div>
          );
        })(modelIndustryType)}
      </div>
      {currentTab === 'keyFocusIndustry' && (
        <div className={style.FocusIndustry}>
          {!isEmpty(topFocusIndustries) ? (
            map((item: any) => (
              <div key={item.industryName} className={style.keyFocusIndustryItem}>
                <div className={style.keyFocusIndustryItemLeft}>
                  <Icon
                    size={42}
                    svg={prop(prop(item?.industryId)(IndustryModelIcons))(industryIcons) || DetailIndustryModelIcon}
                  />
                  <div className={style.IndustryName}>{item.industryName}</div>
                </div>
                <div className={style.keyFocusIndustryItemRight}>
                  <div
                    className={style.keyFocusIndustryItemRightContent}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFundClick(item.fundId);
                    }}
                  >
                    <div className={style.FundName}>{item?.fundName}</div>
                    <div className={style.Increase}>
                      <span className={style.IncreaseName}>{getMessage('recentOneYearYield')}</span>
                      <ColorNumber value={item.return1Y} formatter={formatPercentage} className={style.ColorNumber} />
                    </div>
                  </div>
                </div>
              </div>
            ))(topFocusIndustries)
          ) : (
            <EmptyContent className={style.EmptyContent}>
              <strong>{getMessage('noData')}</strong>
            </EmptyContent>
          )}
        </div>
      )}
      {currentTab === 'focusIndustry' && (
        <div className={style.FocusIndustry}>
          {!isEmpty(focusIndustries) ? (
            map((item: IndustryProp) => (
              <div key={item.industryName} className={style.FocusIndustryItem}>
                <div className={style.FocusIndustryItemLeft}>{item.industryName}</div>
                <div
                  className={style.FocusIndustryItemRight}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFundClick(item.fundId);
                  }}
                >
                  {item?.fundName}
                </div>
              </div>
            ))(focusIndustries)
          ) : (
            <EmptyContent className={style.EmptyContent}>
              <strong>{getMessage('noData')}</strong>
            </EmptyContent>
          )}
        </div>
      )}
    </div>
  );
});
