import { getMessage } from '@betalpha/intl';
import { isEmpty } from 'lodash/fp';
import React from 'react';
import { formatNilMessage, LEVEL_MESSAGE_KEY_MAP_2 } from '@/views/fund-evaluation-detail/constants';
import EmptyContent from '@/components/emptyContent';
import { useGetMarketMacroSummaryQuery } from '@/infra/api/etf-funds';
import { formatPercentage } from '@/util/numberFormatter';
import { HistoryPoint } from '@/views/fund-compass-home/contants';
import style from '../index.module.less';
import QuantileChart from './quantileChart';

export default React.memo(() => {
  const { data } = useGetMarketMacroSummaryQuery();
  const { marketValuationLevel } = data || {};
  const { dates, values, peQuantile, valuationLevel, historicalPoint, investRisk } = marketValuationLevel || {};

  return (
    <div>
      <div className={style.MarketValuationSummary}>
        {getMessage('etfMarketValuationSummary', {
          valuationLevel: formatNilMessage(LEVEL_MESSAGE_KEY_MAP_2, valuationLevel),
          peRankScore: formatPercentage(peQuantile),
          historicalPoint: formatNilMessage(HistoryPoint, historicalPoint),
          investRisk: formatNilMessage(LEVEL_MESSAGE_KEY_MAP_2, investRisk)
        })}
      </div>
      {isEmpty(dates) ? (
        <EmptyContent className={style.EmptyContent}>
          <strong>{getMessage('noData')}</strong>
        </EmptyContent>
      ) : (
        <QuantileChart benchmarkInfo={{ dates, values, peQuantile }} />
      )}
    </div>
  );
});
