import React, { useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import { EChartsOption, PieSeriesOption } from 'echarts';
import {
  chunk,
  compact,
  concat,
  filter,
  first,
  flow,
  isEmpty,
  map,
  orderBy,
  prop,
  replace,
  sum,
  sumBy,
  toArray
} from 'lodash/fp';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';
import { mapIndexed } from '@betalpha/stdlib/base';
import ColorBlockTitle from '@/components/color-block-title';
import CardLayout from '@/components/cardLayout';
import BaseEcharts from '@/components/baseEcharts';
import { useGetETFPositionCompareQuery } from '@/infra/api/etf-funds';
import { decimalSubtract } from '@/util/math';
import ColorNumber from '@/components/colorNumber';
import DetailModal from '@/components/detail-modal';
import EmptyContent from '@/components/emptyContent';
import { CHART_COLOR } from '../../constants';
import style from './index.module.less';

type fundIndustryWeightObjectType = { value: number; industry: string };
const PositionCompare = () => {
  const { fundId } = useParams();
  const { data, isFetching } = useGetETFPositionCompareQuery(fundId ?? '', { skip: !fundId });
  const { fundIndustryWeight, sameTypeFundsIndustryWeight } = data || {};

  const sortedFundIndustryWeight = useMemo(
    () =>
      flow(
        mapIndexed((value: number, industry: string) => ({ value, industry })),
        filter((item: { value: number }) => Math.round(item.value * 100) > 0),
        orderBy(prop('value'))('desc'),
        chunk(4),
        (arr) => {
          const restIndustriesWeight =
            (100 -
              flow(
                first,
                map((item: { value: number }) => percentage(item.value, { digit: 0 })),
                sumBy((item) => +replace('%', '')(item))
              )(arr)) /
            100;

          if (isEmpty(first(arr))) return [];
          return concat(first(arr))(
            restIndustriesWeight > 0 ? { industry: getMessage('other'), value: restIndustriesWeight } : []
          );
        },
        compact
      )(fundIndustryWeight) as fundIndustryWeightObjectType[],
    [fundIndustryWeight]
  );

  const totalWeight = useMemo(() => sum(toArray(fundIndustryWeight)), [fundIndustryWeight]);

  const options = useMemo<EChartsOption>(
    () => ({
      color: CHART_COLOR,
      tooltip: {
        show: false
      },
      legend: {
        show: false
      },
      grid: { bottom: 0, top: 'auto' },
      xAxis: { show: false },
      series: [
        {
          type: 'pie',
          radius: ['40%', '80%'],
          center: ['55%', '50%'],
          avoidLabelOverlap: false,
          emphasis: {
            disabled: true
          },
          data: map(({ value }: fundIndustryWeightObjectType) => {
            const noEnoughSpace = value / totalWeight <= 0.05;
            return {
              value,
              label: {
                show: true,
                position: noEnoughSpace ? 'outside' : 'inside',
                color: noEnoughSpace ? '#666' : 'white',
                fontSize: 10,
                fontWeight: 600,
                fontFamily: 'PingFangSC-Semibold, PingFang SC',
                formatter: (param: any) => percentage(param?.value, { digit: 0 })
              },
              labelLine: { show: noEnoughSpace, length2: 8 }
            };
          })(sortedFundIndustryWeight) as PieSeriesOption['data']
        }
      ]
    }),
    [sortedFundIndustryWeight, totalWeight]
  );

  return (
    <CardLayout
      title={
        <div className={style.TitleWrap}>
          <ColorBlockTitle>{getMessage('sameTypePositionCompare')}</ColorBlockTitle>
          <DetailModal
            title={getMessage('sameAverage')}
            content={getMessage('positionCompareTips')}
            triggerClassName={style.Trigger}
          />
        </div>
      }
      className={style.PositionCompareWrap}
      loading={isFetching}
    >
      <div className={style.ContentWrap}>
        {!isEmpty(sortedFundIndustryWeight) ? (
          <>
            <div className={style.ChartWrap}>
              <BaseEcharts options={options} width={140} height={180} notMerge />
            </div>
            <div className={style.CustomLegendWrap}>
              {mapIndexed((item: fundIndustryWeightObjectType, idx: number) => {
                const { industry, value } = item ?? {};
                const difference = decimalSubtract(value ?? NaN, sameTypeFundsIndustryWeight?.[industry] ?? NaN);
                return (
                  <React.Fragment key={industry}>
                    <div className={style.Legend} style={{ background: CHART_COLOR[idx] }} />
                    <div className={style.Industry}>{industry}</div>
                    <div className={style.Desc}>
                      {!!difference &&
                        `${getMessage(
                          // eslint-disable-next-line no-nested-ternary
                          difference > 0 ? 'higherThan' : difference === 0 ? 'EQUAL' : 'lowerThan'
                        )}${getMessage('average')}`}
                    </div>
                    <div className={style.Diff}>
                      {!!difference && <ColorNumber value={difference} formatter={percentage} />}
                    </div>
                  </React.Fragment>
                );
              })(sortedFundIndustryWeight)}
            </div>
          </>
        ) : (
          <EmptyContent message={getMessage('noData')} />
        )}
      </div>
    </CardLayout>
  );
};

export default React.memo(PositionCompare);
