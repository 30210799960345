import React, { useMemo } from 'react';
import { Link, useParams } from '@betalpha/router';
import { getMessage } from '@betalpha/intl';
import { map } from 'lodash/fp';
import { mapIndexed } from '@betalpha/stdlib/base';
import CardLayout from '@/components/cardLayout';
import { useGetETFFundBasicInfoQuery, useGetETFSimilarETFCompareQuery } from '@/infra/api/etf-funds';
import DetailModal from '@/components/detail-modal';
import Tabs, { Tab } from '@/components/tabs';
import { NO_SIMILAR_ETF_SUMMARY, SIMILAR_ETF_COMPARE_TABS, SimilarETFCompareTabsType } from '../../constants';
import style from './index.module.less';
import CompareDetail from './components/compare-detail';
import FeatureFactorsCompare from './components/feature-factors-compare';

const SimilarETFCompare = () => {
  const { fundId } = useParams();
  const { data, isFetching } = useGetETFSimilarETFCompareQuery(fundId ?? '', { skip: !fundId });

  const { compareSummary, similarIndustryFund, featureFactor } = data ?? {};

  const { data: basicInfo, isFetching: isBasicInfoFetching } = useGetETFFundBasicInfoQuery(fundId ?? '', {
    skip: !fundId
  });
  const { etfTypeName } = basicInfo ?? {};
  const similarETFTitle = useMemo(() => {
    if (compareSummary && NO_SIMILAR_ETF_SUMMARY.includes(compareSummary)) return getMessage(compareSummary);
    return etfTypeName
      ? `${getMessage('similar')}${etfTypeName.slice(3, -2)}${getMessage('ETFTypeSuffix')}：`
      : getMessage('noData');
  }, [compareSummary, etfTypeName]);

  return (
    <CardLayout title={getMessage('similarEtfCompare')} loading={isFetching || isBasicInfoFetching}>
      <div className={style.SimilarETFCompareWrap}>
        <div className={style.CompareSummaryWrap}>
          <div className={style.MultiDimention}>
            <div>{getMessage('multidimensionalComparisonOfSameCategory')}</div>
            <div>
              <DetailModal
                title={getMessage('multidimensionalComparisonOfSameCategory')}
                content={getMessage('multidimensionalComparisonOfSameCategoryTips')}
              />
            </div>
            <div className={style.Summary}>
              {NO_SIMILAR_ETF_SUMMARY.includes(compareSummary ?? '')
                ? getMessage('noSimilarFund')
                : getMessage(compareSummary ?? 'noData')}
            </div>
          </div>
          <div className={style.SimilarIndustriesWrap}>
            <div className={style.Title}>{similarETFTitle}</div>
            {!NO_SIMILAR_ETF_SUMMARY.includes(compareSummary ?? '') && (
              <div className={style.Industries}>
                {mapIndexed((etfName: string, code: string) => <Link to={`/${code}`}>{etfName}</Link>)(
                  similarIndustryFund as any
                ) || getMessage('noData')}
              </div>
            )}
          </div>
        </div>
        <div className={style.CompareWrap}>
          <Tabs className={style.Tabs} defaultActiveKey={SIMILAR_ETF_COMPARE_TABS[0]}>
            {map((item: SimilarETFCompareTabsType[number]) => (
              <Tab title={getMessage(item)} description="" key={item}>
                <CompareDetail currentTab={item} />
              </Tab>
            ))(SIMILAR_ETF_COMPARE_TABS)}
          </Tabs>
        </div>
        <FeatureFactorsCompare featureFactor={featureFactor} compareSummary={compareSummary} />
      </div>
    </CardLayout>
  );
};

export default React.memo(SimilarETFCompare);
