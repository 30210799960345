import React, { useMemo } from 'react';
import { merge, map, flow, prop, flatten, max, min, isEmpty } from 'lodash/fp';
import BaseChart from '../baseChart';
import { SeriesOption, ChartProps } from './type';
import { getChartOption, getSplitNumber, generateDataFieldInSerie } from './helper';

export const getYxiasFixedNumberSplitLineConfit = (series: SeriesOption[], splitLineNumber: number) => {
  if (isEmpty(series)) {
    return {};
  }
  const values: number[] = flow(
    map((item: SeriesOption) => map((v: number[]) => v?.[1])(prop('data')(item))),
    flatten
  )(series);
  const maxValue = max(values) || 0;
  const minValue = min(values) || 0;
  const distance = Math.round((((maxValue - minValue) * 1.2) / splitLineNumber) * 10000) / 10000;
  const targetMin = minValue - (maxValue - minValue) * 0.1;
  return {
    max: splitLineNumber * distance + targetMin,
    min: targetMin,
    interval: distance
  };
};

const generateSerieConfig = (serie: SeriesOption) => ({
  showSymbol: false,
  lineStyle: {
    width: 1
  },
  type: 'line',
  smooth: false,
  ...serie

  // symbol: "none",
});

export default React.memo<ChartProps>(
  ({
    dates = [],
    series,
    height = 300,
    showDataZoom = true,
    showLegend = true,
    saveImgEnable = false,
    type = 'yield',
    splitNumber,
    fillEmptyDate = false,
    boundaryGap = false,
    formatter,
    gridOptions = {},
    legendOptions = {},
    xAxisOptions = {},
    yAxisOptions = {},
    graphicOptions,
    onZero = false,
    tooltipOptions = { show: false },
    ...restProps
  }) => {
    const finalSeries = useMemo(
      () => map(flow(generateDataFieldInSerie('cumulative')(dates, type, fillEmptyDate), generateSerieConfig))(series),
      [series, dates, type, fillEmptyDate]
    );
    const chartOption = useMemo(
      () =>
        merge(
          getChartOption({
            height,
            showLegend,
            showDataZoom,
            saveImgEnable,
            dates,
            series: finalSeries,
            type,
            splitNumber,
            boundaryGap,
            formatter,
            gridOptions,
            legendOptions,
            xAxisOptions,
            yAxisOptions,
            graphicOptions,
            tooltipOptions
          })
        )({
          // eslint-disable-next-line no-nested-ternary
          yAxis: showDataZoom
            ? { scale: true }
            : !onZero
            ? getYxiasFixedNumberSplitLineConfit(
                finalSeries,
                getSplitNumber({
                  splitNumber,
                  showDataZoom,
                  showLegend,
                  height
                })
              )
            : {}
        }),
      [
        height,
        showLegend,
        showDataZoom,
        saveImgEnable,
        dates,
        finalSeries,
        type,
        splitNumber,
        boundaryGap,
        formatter,
        gridOptions,
        legendOptions,
        xAxisOptions,
        yAxisOptions,
        graphicOptions,
        tooltipOptions,
        onZero
      ]
    );

    // const chartStyle = useMemo(
    //   () => ({
    //     width: "100%",
    //     height: `${height}px`,
    //   }),
    //   [height]
    // );
    return <BaseChart option={chartOption} height={height} {...restProps} />;
  }
);
