import dayjs from 'dayjs';
import { forEach, invert, prop } from 'lodash/fp';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);

export const MarketCapabilityLevel = {
  HIGH: 'upstream',
  MEDIUM: 'midstream',
  LOW: 'downstream'
};
export const AbilityLevel = {
  HIGH: 'Strong',
  MEDIUM: 'ORDINARY',
  LOW: 'POOR'
};

export const getDateOfQuarter = (date: string) => {
  const dateYear = dayjs(date).get('year').toString();
  const dateQuarter = dayjs(date).quarter();
  return `${dateYear}Q${dateQuarter}`;
};
export const getLastDateOfQuarter = (processedDates: string[]) => {
  const dates: Record<string, string> = {};
  let dateCount = 0;
  forEach((date: string) => {
    const quarterDate = getDateOfQuarter(date);
    const prevDate = prop(quarterDate)(dates);

    if (prevDate) {
      if (dateCount < 33) {
        dates[quarterDate] = date;
        // eslint-disable-next-line no-plusplus
        dateCount++;
      }
    } else {
      dateCount = 0;
      dates[quarterDate] = date;
    }
  })(processedDates);
  return invert(dates);
};

export const getTextColor = (num: number) => {
  if (num > 0) return '#DD314C';
  if (num < 0) return '#5BA06B';
  return '#666';
};

export const getTooltipColorInfo = (type: string) => {
  if (type === 'RISE') {
    return {
      title: '#F7F3EC',
      border: '#E5E0D6',
      background: '#FAF8F4'
    };
  }
  if (type === 'FALL') {
    return {
      title: '#ECF3EF',
      border: '#D9E3DD',
      background: '#F4F9F6'
    };
  }
  return {
    title: undefined,
    border: undefined,
    background: undefined
  };
};
