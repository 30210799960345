import { useLocation } from '@betalpha/router';
import { useEffect } from 'react';

type optionProp = boolean | ScrollIntoViewOptions | undefined;

export const useScrollByAnchorPoint = (option?: optionProp) => {
  const location = useLocation();
  const { hash } = location || {};

  useEffect(() => {
    if (hash) {
      const anchorPointName = document?.querySelector(hash);
      anchorPointName?.scrollIntoView(option || true);
    }
  }, [hash, option]);
};
