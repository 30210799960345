import { getMessage } from '@betalpha/intl';
import React, { ReactNode, useMemo } from 'react';
import { useParams } from '@betalpha/router';
import { join, map, prop, toLower } from 'lodash/fp';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';
import CardLayout from '@/components/cardLayout';
import { useGetETFRiskProbeQuery } from '@/infra/api/etf-funds';
import Rate from '@/components/rate';
import transactionDimentionIcon from '@/assets/etfCompass/transaction-dimention.svg';
import valueDimentionIcon from '@/assets/etfCompass/value-dimention.svg';
import prosperityDimentionIcon from '@/assets/etfCompass/industry-prosperity.svg';
import crowdingDimentionIcon from '@/assets/etfCompass/industry-crowding.svg';
import {
  LEVEL_MESSAGE_KEY_MAP_11,
  LEVEL_MESSAGE_KEY_MAP_7,
  LEVEL_MESSAGE_KEY_MAP_8
} from '@/views/fund-evaluation-detail/constants';
import Tabs, { Tab } from '@/components/tabs';
import { etfRiskValuationEvaluateSummary } from '../../constants';
import style from './index.module.less';
import { TAB_TITLES, getNotNillMessage } from './hooks';
import TransactionDimension from './components/transaction-dimension';
import ValuationDimension from './components/valuation-dimension';
import IndustryProsperity from './components/industry-prosperity';
import IndustryCrowding from './components/industry-crowding';

type SummaryItemType = { icon: string; node: ReactNode };
const Risk = () => {
  const { fundId } = useParams();
  const { data, isFetching } = useGetETFRiskProbeQuery(fundId ?? '', { skip: !fundId });

  const summaryItems = useMemo<SummaryItemType[]>(
    () => [
      {
        icon: transactionDimentionIcon,
        node: (
          <div>
            <div className={style.RatingWrap}>
              {getMessage('etfTransactionDimensionRating')}{' '}
              <Rate value={data?.transactionDimension?.transactionRating?.rating} wrapClassName={style.Rating} />
            </div>
            <div>
              {getMessage('futureExpectedRisk', {
                riskLevel: getNotNillMessage(data?.transactionDimension?.transactionRating?.level)
              })}
            </div>
          </div>
        )
      },
      {
        icon: valueDimentionIcon,
        node: `${getMessage('etfRiskSummaryValuationDimension', {
          valuationLevel: getNotNillMessage(data?.valuationDimension?.valuationLevel),
          valuationValue: percentage(data?.valuationDimension?.peQuantile),
          evaluate: data?.valuationDimension?.valuationLevel
            ? getMessage(prop(data?.valuationDimension?.valuationLevel)(etfRiskValuationEvaluateSummary))
            : '--'
        })}。`
      },
      {
        icon: prosperityDimentionIcon,
        node: getMessage('etfRiskSummaryIndustryProsperityDimension', {
          holdIndustry: join('、')(data?.industryProsperity?.topIndustry),
          prosperityLevel: getNotNillMessage(data?.industryProsperity?.prosperityLevel, LEVEL_MESSAGE_KEY_MAP_7),
          roe: percentage(data?.industryProsperity?.currentRoe),
          roeLevel: getNotNillMessage(data?.industryProsperity?.roeLevel, LEVEL_MESSAGE_KEY_MAP_8),
          growthRate: getNotNillMessage(data?.industryProsperity?.growthRateRank, LEVEL_MESSAGE_KEY_MAP_11)
        })
      },
      {
        icon: crowdingDimentionIcon,
        node: getMessage('etfRiskSummaryIndustryCrowdingDimension', {
          level: getNotNillMessage(data?.industryCrowding?.industryCrowding),
          advice: data?.industryCrowding?.investAdvice
            ? getMessage(`etfIndustryCrowdingAdvice_${toLower(data?.industryCrowding?.investAdvice)}`)
            : '--'
        })
      }
    ],
    [data]
  );

  return (
    <CardLayout title={getMessage('riskAssessment')} loading={isFetching} id="risk-evaluation">
      <div className={style.RiskWrap}>
        <div className={style.SummaryWrap}>
          <div className={style.Summary}>
            {getMessage('currentETFSummary', {
              level: data?.summaryText ? getMessage(`etfRiskSummary_${toLower(data?.summaryText)}`) : '--'
            })}
          </div>
          <div className={style.SummaryItemsWrap}>
            {map((item: SummaryItemType) => (
              <div key={item.icon} className={style.SummaryItem}>
                <img src={item.icon} />
                {item.node}
              </div>
            ))(summaryItems)}
          </div>
        </div>
        <Tabs className={style.Tab} defaultActiveKey={TAB_TITLES.TRANSACTIONAL_DIMENSION}>
          <Tab
            key={TAB_TITLES.TRANSACTIONAL_DIMENSION}
            title={getMessage(TAB_TITLES.TRANSACTIONAL_DIMENSION)}
            description={null}
          >
            <TransactionDimension data={data?.transactionDimension} />
          </Tab>
          <Tab
            key={TAB_TITLES.VALUATION_DIMENSION}
            title={getMessage(TAB_TITLES.VALUATION_DIMENSION)}
            description={null}
          >
            <ValuationDimension data={data?.valuationDimension} />
          </Tab>
          <Tab key={TAB_TITLES.industryProsperity} title={getMessage(TAB_TITLES.industryProsperity)} description={null}>
            <IndustryProsperity data={data?.industryProsperity} />
          </Tab>
          <Tab key={TAB_TITLES.industryCrowding} title={getMessage(TAB_TITLES.industryCrowding)} description={null}>
            <IndustryCrowding data={data?.industryCrowding} />
          </Tab>
        </Tabs>
      </div>
    </CardLayout>
  );
};

export default React.memo(Risk);
