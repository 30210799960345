import React, { useMemo } from 'react';
import { identity, isEmpty, isNumber, last, merge, zip } from 'lodash/fp';
import { EChartsOption, graphic } from 'echarts';
import { getMessage } from '@betalpha/intl';
import EmptyContent from '@/components/emptyContent';
import BaseEcharts from '@/components/baseEcharts';
import { formatPercentage } from '@/util/numberFormatter';
import {
  useCalculateMarkLabelPosition,
  useGetDefaultChartOptions
} from '@/views/fund-compass-detail/components/risk/hooks';

import style from '../index.module.less';

export interface benchmarkInfoBody {
  dates?: string[];
  values?: number[];
  peQuantile?: number;
}

export default React.memo(({ benchmarkInfo }: { benchmarkInfo: benchmarkInfoBody }) => {
  const { dates, values, peQuantile } = benchmarkInfo;

  const { onChartReady, markLabelPositionInfo, triangleNode } = useCalculateMarkLabelPosition('#244579', '#1a396a');

  const chartData = useMemo(() => zip(dates || [])(values || []) as [string, number][], [dates, values]);

  const defaultOptions = useGetDefaultChartOptions(dates ?? []);
  const options = useMemo<EChartsOption>(
    () =>
      merge({
        grid: { left: 22, top: 32, bottom: 28, right: 0 },
        legend: { top: 0, bottom: 'auto', left: 0, icon: 'path://M30 0 L200 0 Q 230 30 200 60 L30 60 Q 0 30 30 0 Z' },
        yAxis: {
          axisLabel: { formatter: identity, fontWeight: 'lighter', fontSize: 10, color: '#666' },
          axisLine: { show: false, onZero: false },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ECECEC',
              type: 'dotted'
            }
          }
        },
        xAxis: {
          axisLine: { show: false }
        },
        series: {
          type: 'line',
          name: getMessage('cs800PERatio'),
          color: '#CFB47F',
          data: chartData,
          silent: true,
          markLine: {
            symbol: 'none',
            lineStyle: {
              type: 'dotted',
              color: '#244579',
              width: 2
            },
            data: [
              {
                yAxis: last(values) ?? NaN,
                label: {
                  show: false
                }
              }
            ]
          },
          areaStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#FDF5E7'
              },
              {
                offset: 1,
                color: '#FFFFFF'
              }
            ])
          }
        }
      })(defaultOptions),
    [chartData, defaultOptions, values]
  );
  return isEmpty(dates) ? (
    <EmptyContent className={style.EmptyContent}>
      <strong>{getMessage('noData')}</strong>
    </EmptyContent>
  ) : (
    <div className={style.QuanChartWrap}>
      <BaseEcharts className={style.Chart} options={options} height={184} onChartReady={onChartReady} />
      {isNumber(peQuantile) && (
        <div className={style.MarkWrap}>
          <div className={style.MarkLabel} style={{ top: markLabelPositionInfo?.[0].top }}>
            <div className={style.Value}>{formatPercentage(peQuantile)}</div>
            <div className={style.Label}>{getMessage('currentQuantile')}</div>
          </div>
          {triangleNode}
        </div>
      )}
    </div>
  );
});
