import React, { useRef } from 'react';
import Icon from '@/components/icon';
import { ReactComponent as SearchIcon } from '@/assets/icon-search.svg';
import SearchPopup, { SearchPopupProps, SearchPopupRef } from '../fundSearch/search-popup';

export const useSearchPopup = (type: SearchPopupProps['type'] = 'FUND_EVALUATION') => {
  const searchPopup = useRef<SearchPopupRef>(null);
  return (
    <>
      <SearchPopup ref={searchPopup} type={type} />
      <Icon size={20} svg={SearchIcon} onClick={() => searchPopup.current?.openPopup()} />
    </>
  );
};
