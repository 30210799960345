import { useNavigate } from '@betalpha/router';
import { includeStr } from '@betalpha/stdlib/base';
import { Popup, List, Input } from 'antd-mobile';
import { filter, toLower, trim } from 'lodash/fp';
import React, { useCallback, useImperativeHandle, useState, useDeferredValue, useEffect, useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { HighlightText } from '@betalpha/alpha-design';
import VirtualList, { Props as VirtualListProps } from 'react-tiny-virtual-list';
import { useGetAllFundQuery } from '@/infra/api/funds';
import { AllFundBody } from '@/interface/fundDetail';
import searchIcon from '@/assets/icon-search.svg';
import { useGetAllETFFundQuery } from '@/infra/api/etf-funds';
import { useGetAllBondFundQuery } from '@/infra/api/bond';
import style from './index.module.less';

export interface SearchPopupRef {
  openPopup: () => void;
  closePopup: () => void;
}
export interface SearchPopupProps {
  className?: string;
  type?: 'FUND_EVALUATION' | 'ETF_COMPASS' | 'BOND_FUND';
}

export const useTargetFunds = (type: SearchPopupProps['type']) => {
  const { data: fundData } = useGetAllFundQuery(undefined, { skip: type !== 'FUND_EVALUATION' });
  const { data: etfData } = useGetAllETFFundQuery(undefined, { skip: type !== 'ETF_COMPASS' });
  const { data: bondData } = useGetAllBondFundQuery(undefined, { skip: type !== 'BOND_FUND' });
  const result = useMemo(() => {
    switch (type) {
      case 'FUND_EVALUATION':
        return fundData;
      case 'ETF_COMPASS':
        return etfData;
      case 'BOND_FUND':
        return bondData;
      default:
        return [];
    }
  }, [type, fundData, etfData, bondData]);
  return result;
};

const SearchPopup = React.forwardRef<SearchPopupRef, SearchPopupProps>(
  ({ type = 'FUND_EVALUATION', className }, ref) => {
    const navigateTo = useNavigate();
    const allFund = useTargetFunds(type);
    const [searchStr, setSearchStr] = useState('');
    const deferredSearchStr = useDeferredValue(searchStr);

    const [popUpOpen, setPopUpOpen] = useState(false);
    const openPopup = useCallback(() => setPopUpOpen(true), []);
    const closePopup = useCallback(() => {
      setSearchStr('');
      setPopUpOpen(false);
    }, []);
    useImperativeHandle(ref, () => ({
      openPopup,
      closePopup
    }));

    const searchFunds = useMemo(() => {
      if (!deferredSearchStr) return [];
      const value = trim(toLower(deferredSearchStr));
      return filter(
        (item: AllFundBody) => includeStr(value)(toLower(item.fundId)) || includeStr(value)(toLower(item.fundName))
      )(allFund);
    }, [allFund, deferredSearchStr]);
    const renderItem: VirtualListProps['renderItem'] = ({ index, style: itemStyle }) => {
      const item = searchFunds[index];
      return (
        <List.Item key={item.fundId} style={itemStyle} className={style.ListItem}>
          <div
            className={style.TextWrap}
            onClick={() => {
              navigateTo(`/${item.fundId}`);
              closePopup();
            }}
          >
            <HighlightText
              style={{ color: '#666', fontSize: '13px' }}
              pattern={searchStr}
              className={style.FundNameText}
              value={item.fundName}
            />
            <HighlightText style={{ color: '#999', fontSize: '13px' }} pattern={searchStr} value={item.fundId} />
          </div>
        </List.Item>
      );
    };
    const [height, setHeight] = useState(window.innerHeight - 270);
    useEffect(() => {
      setTimeout(() => {
        // 键盘弹出后，页面高度变化，重制页面高度
        const visualHeight = window.visualViewport?.height || window.innerHeight - 270;
        if (visualHeight < window.innerHeight) {
          setHeight(visualHeight);
        }
      }, 800);
    }, []);
    return popUpOpen ? (
      <Popup className={className} visible={popUpOpen} onMaskClick={closePopup} position="top">
        <div className={style.FundSearchWrap}>
          <div className={style.InputWrap}>
            <img className={style.SearchIcon} src={searchIcon} alt="" />
            <Input
              className={style.FundSearch}
              onChange={(value) => setSearchStr(value)}
              autoFocus
              value={searchStr}
              placeholder={getMessage('fundSearchPlaceholderMobile')}
              clearable
            />
          </div>
          <span onClick={closePopup} style={{ color: '#999999', cursor: 'pointer' }}>
            {getMessage('cancel')}
          </span>
        </div>
        <List className={style.ResultList}>
          <VirtualList
            itemCount={searchFunds.length}
            renderItem={renderItem}
            width="100%"
            height={height}
            itemSize={44}
          />
        </List>
      </Popup>
    ) : null;
  }
);

export default React.memo(SearchPopup);
