import { getMessage } from '@betalpha/intl';
import { useMemoizedFn } from 'ahooks';
import TinyColor from '@ctrl/tinycolor';
import { isEmpty, map, orderBy, size } from 'lodash/fp';
import { useMemo, useState } from 'react';
import { EChartsInstance } from '@betalpha/alpha-design/es/charts';
import { EChartsOption } from 'echarts';
import { LEVEL_MESSAGE_KEY_MAP_2 } from '@/views/fund-evaluation-detail/constants';

const MarkLabelNodeHeightDefaultHeight = 44;

export enum TAB_TITLES {
  TRANSACTIONAL_DIMENSION = 'TRANSACTIONAL_DIMENSION',
  VALUATION_DIMENSION = 'VALUATION_DIMENSION',
  industryProsperity = 'industryProsperity',
  industryCrowding = 'industryCrowding'
}

export const getNotNillMessage = (key: string | undefined, mapper: Record<string, string> = LEVEL_MESSAGE_KEY_MAP_2) =>
  key ? getMessage(mapper[key]) : '--';

// 两个markLabel之间的间隔
const MarkLabelMinSpacing = 6;
// 图表高度
const CHART_HEIGHT = 184;

type PositionInfo = {
  top: number;
  bottom: number;
  height: number;
};

const calculateMarkLabelPosition = {
  // MarkLabelNodeHeight: MarkLabelNodeHeightDefaultHeight,
  adaptor(linePosition: LinePositionInfoItemType, minTop: number, maxBottom: number) {
    const adaptTop = Math.min(linePosition.position - linePosition.nodeHeight / 2, maxBottom);
    const adaptBottom = adaptTop + linePosition.nodeHeight;
    return {
      top: adaptTop < minTop ? minTop : adaptTop,
      bottom: adaptBottom,
      height: linePosition.nodeHeight
    };
  },
  // 处理相邻两个markLabel的位置信息
  generateNearByTwoPointPosition(
    topMarkLinePosition: LinePositionInfoItemType,
    bottomMarkLinePosition: LinePositionInfoItemType
  ) {
    if (!bottomMarkLinePosition) {
      const topMarkLabelAdaptor = this.adaptor(topMarkLinePosition, 0, CHART_HEIGHT - topMarkLinePosition.nodeHeight);
      return { topMarkLabelAdaptor };
    }

    const distance = bottomMarkLinePosition.position - topMarkLinePosition.position;
    const twoMarkLabelHeight = topMarkLinePosition.nodeHeight + bottomMarkLinePosition.nodeHeight + MarkLabelMinSpacing;

    // 如果两个markLine之间的距离大于两个markLabel的高度，那么高度比较充裕，这时候是最理想的情况，将markLabel放在markLine中间
    if (distance > (twoMarkLabelHeight + MarkLabelMinSpacing) / 2) {
      const topMarkLabelAdaptor = this.adaptor(topMarkLinePosition, 0, CHART_HEIGHT - topMarkLinePosition.nodeHeight);
      const bottomMarkLabelAdaptor = this.adaptor(
        bottomMarkLinePosition,
        topMarkLabelAdaptor.bottom + MarkLabelMinSpacing,
        CHART_HEIGHT - bottomMarkLinePosition.nodeHeight
      );
      return { topMarkLabelAdaptor, bottomMarkLabelAdaptor };
    }
    // 如果两个markLine之间的距离小于两个markLabel的高度，说明高度比较窄，这时候需要根据前一个markLabel的位置，以及高度之差来调整两个markLabel的位置
    const remainWidth = twoMarkLabelHeight - distance;
    // 取他们中的较大值 （即不超过边界的情况下最靠上的值）
    const adaptTopPosition = Math.max(topMarkLinePosition.position - remainWidth / 2, 0);

    let topMarkLabelAdaptor = {
      top: adaptTopPosition,
      height: topMarkLinePosition.nodeHeight,
      bottom: adaptTopPosition + topMarkLinePosition.nodeHeight
    };

    // 只要决定了上边的位置，就能够算出下边的位置
    // 下边的距离则是上边的距离加上间距再加上markLabel的高度
    const adaptBottom = topMarkLabelAdaptor.bottom + MarkLabelMinSpacing + bottomMarkLinePosition.nodeHeight;
    let bottomMarkLabelAdaptor = null;
    if (adaptBottom <= CHART_HEIGHT)
      bottomMarkLabelAdaptor = {
        top: topMarkLabelAdaptor.bottom + MarkLabelMinSpacing,
        height: bottomMarkLinePosition.nodeHeight,
        bottom: adaptBottom
      };
    else {
      const offset = adaptBottom - CHART_HEIGHT;
      topMarkLabelAdaptor = {
        top: adaptTopPosition - offset,
        height: topMarkLinePosition.nodeHeight,
        bottom: adaptTopPosition + topMarkLinePosition.nodeHeight - offset
      };
      bottomMarkLabelAdaptor = {
        top: CHART_HEIGHT - bottomMarkLinePosition.nodeHeight,
        height: bottomMarkLinePosition.nodeHeight,
        bottom: CHART_HEIGHT
      };
    }
    return { topMarkLabelAdaptor, bottomMarkLabelAdaptor };
  },
  generate(lineYPosition: LinePositionInfoType) {
    const keepPosition: PositionInfo[] = [];
    const { topMarkLabelAdaptor, bottomMarkLabelAdaptor } = this.generateNearByTwoPointPosition(
      lineYPosition[0],
      lineYPosition[1]
    );
    if (bottomMarkLabelAdaptor) keepPosition.push(topMarkLabelAdaptor, bottomMarkLabelAdaptor);
    else keepPosition.push(topMarkLabelAdaptor);
    return keepPosition;
  }
};

const useDrawTriangleNode = (
  markLabelPositionInfo: PositionInfo[] | undefined,
  linePositionInfo: LinePositionInfoType | undefined,
  triangleColor: string | undefined
) => {
  if (!linePositionInfo || !markLabelPositionInfo) return null;
  return markLabelPositionInfo.map((it, idx) => {
    // 左
    const leftPointX = 0;
    const leftPointY = linePositionInfo[idx].position;
    // 右上
    const topRightPointX = 8;
    const topRightPointY = it.top + it.height / 2 - 6;
    // 右下
    const bottomPointX = 8;
    const bottomPointY = it.top + it.height / 2 + 6;
    // 画三角形
    return (
      <svg
        key={it.top}
        style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', height: '100%' }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <path
          d={`M${bottomPointX} ${bottomPointY} L${leftPointX} ${leftPointY} L${topRightPointX} ${topRightPointY} Z`}
          fill={triangleColor}
        />
      </svg>
    );
  });
};

export const useCalculateMarkLabelPosition = (
  markLineColor: string,
  svgBackgroundColor: string,
  MarkLabelNodeHeight = MarkLabelNodeHeightDefaultHeight
) => {
  const [markLabelPositionInfo, setMarkLabelPositionInfo] = useState<PositionInfo[] | undefined>(undefined);
  const [linePositionInfo, setLinePositionInfo] = useState<LinePositionInfoType | undefined>(undefined);
  const updateLineYPositionInfo = useMemoizedFn((positionInfo: LinePositionInfoType) => {
    if (!isEmpty(positionInfo)) {
      const orderedLinePositionInfo = orderBy<LinePositionInfoItemType>('position', 'asc')(positionInfo);
      setLinePositionInfo(orderedLinePositionInfo);
      setMarkLabelPositionInfo(calculateMarkLabelPosition.generate(orderedLinePositionInfo));
    }
  });
  const onChartReady = useMemoizedFn((instance: any) => {
    // 找到标记线的位置
    const calculateMarkLinePosition = () => {
      const dom = instance.getDom();
      if (dom) {
        const tinyLineColor = TinyColor(markLineColor);
        const lineYPosition: LinePositionInfoType = [];
        [...dom.querySelectorAll('path')].forEach((it) => {
          const fillColor = TinyColor(it.getAttribute('stroke') ?? '');
          if (
            tinyLineColor?.r === fillColor?.r &&
            tinyLineColor?.g === fillColor?.g &&
            tinyLineColor?.b === fillColor?.b
          ) {
            const itemRect = it.getBoundingClientRect();
            const chartRect = dom.getBoundingClientRect();
            if (!(itemRect.y === 0 && chartRect.y === 0))
              lineYPosition.push({ position: itemRect.y - chartRect.y, nodeHeight: MarkLabelNodeHeight });
          }
        });
        updateLineYPositionInfo(lineYPosition);
      }
    };

    instance.on('finished', () => {
      setTimeout(calculateMarkLinePosition);
    });
  });

  // 画三角形
  const triangleNode = useDrawTriangleNode(markLabelPositionInfo, linePositionInfo, svgBackgroundColor);

  return { onChartReady, markLabelPositionInfo, triangleNode };
};

type LinePositionInfoItemType = { position: number; nodeHeight: number };
type LinePositionInfoType = LinePositionInfoItemType[];
export type ValueType = { yAxisId: string; value: number; nodeHeight: number };
export const useCalculateMarkLabelPositionWithoutMarkLine = ({
  svgColor,
  values
}: {
  svgColor: string;
  values: [ValueType, ValueType];
}) => {
  const [markLabelPositionInfo, setMarkLabelPositionInfo] = useState<PositionInfo[] | undefined>(undefined);
  const [linePositionInfo, setLinePositionInfo] = useState<LinePositionInfoType | undefined>(undefined);
  const updateLineYPositionInfo = useMemoizedFn((positionInfo: LinePositionInfoType) => {
    if (!isEmpty(positionInfo)) {
      // 返回的markLabelPosition默认从上往下排序的；应该将其顺序保持和传入values在y方向上像素坐标顺序同步
      const sortOrder = positionInfo[1]?.position > positionInfo[0]?.position ? 'asc' : 'desc';
      const orderedLinePositionInfo = orderBy<LinePositionInfoType['0']>('position', 'asc')(positionInfo);
      setLinePositionInfo(orderedLinePositionInfo);
      const tmp = calculateMarkLabelPosition.generate(orderedLinePositionInfo);
      setMarkLabelPositionInfo(orderBy<PositionInfo>('top', sortOrder)(tmp));
    }
  });
  const onChartReady = useMemoizedFn((instance: EChartsInstance) => {
    // 找到位置
    const calculateMarkLinePosition = () => {
      const yPositions = map((item: ValueType) => ({
        position: instance?.convertToPixel({ yAxisId: item.yAxisId }, item.value),
        nodeHeight: item.nodeHeight
      }))(values);
      updateLineYPositionInfo(yPositions);
    };

    instance.on('finished', () => {
      setTimeout(calculateMarkLinePosition);
    });
  });

  // 画三角形
  const triangleNode = useDrawTriangleNode(markLabelPositionInfo, linePositionInfo, svgColor);

  return { onChartReady, markLabelPositionInfo, triangleNode };
};

export const useGetDefaultChartOptions = (dates: string[], paddingVal = 48) => {
  return useMemo<EChartsOption>(
    () => ({
      xAxis: {
        axisLabel: {
          margin: 14,
          color: '#666',
          hideOverlap: true,
          interval: Math.floor(size(dates) / 2 - 1),
          fontFamily: 'DINAlternate',
          showMinLabel: true,
          fontWeight: 400,
          fontSize: 10,
          showMaxLabel: true,
          formatter(value: string, idx: number) {
            if (idx === size(dates) - 1) {
              return `{alignRightLabel|${value}}`;
            }
            if (idx === 0) {
              return `{alignLeftLabel|${value}}`;
            }
            return value;
          },
          rich: {
            alignRightLabel: {
              fontFamily: 'DINAlternate',
              color: '#666',
              fontWeight: 400,
              padding: [0, paddingVal, 0, 0],
              fontSize: 10
            },
            alignLeftLabel: {
              fontFamily: 'DINAlternate',
              color: '#666',
              fontWeight: 400,
              padding: [0, 0, 0, paddingVal],
              fontSize: 10
            }
          }
        }
      }
    }),
    [dates, paddingVal]
  );
};
