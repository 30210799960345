import { Card } from 'antd';
import React, { ReactNode } from 'react';
import cn from 'classnames';

import { CustomSpin } from '../custom-spin';
import style from './index.module.less';

interface CardLayoutProps {
  title: ReactNode;
  children: ReactNode;
  className?: string;
  loading?: boolean;
  expandAll?: boolean;
  id?: string;
}

export default React.memo<CardLayoutProps>(({ title, children, className = '', loading = false, id }) => {
  return (
    <div className={style.Wrapper} id={id}>
      <CustomSpin loading={loading} />
      <Card title={title} className={cn(style.Card, className)} bordered={false}>
        <div className={style.ContentWrap}>{children}</div>
      </Card>
    </div>
  );
});
