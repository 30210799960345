import { getMessage } from '@betalpha/intl';
import React from 'react';
import cn from 'classnames';
import { isEmpty, keys, prop } from 'lodash/fp';
import { getSummaryLevel, RiskColor, tipModalMessage } from '@/views/fund-compass-home/contants';
import { formatNilMessage, LEVEL_MESSAGE_KEY_MAP_2 } from '@/views/fund-evaluation-detail/constants';
import DetailModal from '@/components/detail-modal';
import { MarketMacroSummary } from '@/interface/etfFund';
import style from '../index.module.less';

export default React.memo(({ data }: { data?: MarketMacroSummary }) => {
  const { summaryText, marketValuationLevel, marketLiquidity, marketExpansionTrend } = data || {};
  const { valuationLevel } = marketValuationLevel || {};
  const { marketAmountChange } = marketLiquidity || {};
  const { top3IncreaseIndustry, collectEtfMarketInfo } = marketExpansionTrend || {};
  const { keyWords } = collectEtfMarketInfo || {};

  return (
    <div className={style.MarketMacroDiv}>
      <div className={style.MarketMacroContent}>
        <div className={cn(style.MarketMacroItem, style.MarketValuationPart)}>
          <div className={style.MarketMacroName}>
            <span className={style.MarketValuationPartName}>{getMessage('marketValuation')}</span>
            <DetailModal
              needTrigger
              title={getMessage('marketValuation')}
              content={prop('marketValuation')(tipModalMessage)}
              triggerClassName={style.Question}
            />
          </div>
          <div className={style.MarketMacroDetail}>
            {getMessage('marketValuationShowDetail', {
              level: formatNilMessage(LEVEL_MESSAGE_KEY_MAP_2, valuationLevel)
            })}
          </div>
        </div>
        <div className={cn(style.MarketMacroItem, style.MarketLiquidityPart)}>
          <div className={cn(style.MarketMacroName, style.MarketMacroNameRight)}>
            <span className={style.MarketValuationPartName}>{getMessage('marketLiquidity')}</span>
            <DetailModal
              needTrigger
              title={getMessage('marketLiquidity')}
              content={prop('marketLiquidity')(tipModalMessage)}
              triggerClassName={style.Question}
            />
          </div>
          <div className={cn(style.MarketMacroDetail, style.MarketMacroDetailRight)}>
            {(marketAmountChange || 0) > 0
              ? getMessage('marketLiquidityIncreaseTip')
              : getMessage('marketLiquidityDecreaseTip')}
          </div>
        </div>
      </div>
      <div className={style.MarketMacroContent}>
        <div className={cn(style.MarketMacroItem, style.marketExpansionPart)}>
          <div className={style.MarketMacroName}>
            <span className={style.MarketValuationPartName}>{getMessage('marketExpansionTrends')} </span>
            <DetailModal
              needTrigger
              title={getMessage('marketExpansionTrends')}
              content={prop('marketExpansionTrends')(tipModalMessage)}
              triggerClassName={style.Question}
            />
          </div>
          <div className={style.MarketMacroDetail}>
            {!isEmpty(keyWords)
              ? getMessage('marketExpansionShowDetail', {
                  industries: keyWords?.join('、')
                })
              : getMessage('noObviousSubject')}
          </div>
        </div>
        <div className={cn(style.MarketMacroItem, style.IndustryRecentSituationPart)}>
          <div className={cn(style.MarketMacroName, style.MarketMacroNameRight)}>
            <span className={style.MarketValuationPartName}>{getMessage('industryRecentSituation')}</span>
            <DetailModal
              needTrigger
              title={getMessage('industryRecentSituation')}
              content={prop('industryRecentSituation')(tipModalMessage)}
              triggerClassName={style.Question}
            />
          </div>
          <div className={cn(style.MarketMacroDetail, style.MarketMacroDetailRight)}>
            {getMessage('industryRecentSituationShowDetail', {
              industries: keys(top3IncreaseIndustry).join('、')
            })}
          </div>
        </div>
      </div>
      <div className={style.RiskCenter} style={{ color: prop(summaryText as string)(RiskColor) }}>
        {getSummaryLevel(summaryText)}
      </div>
    </div>
  );
});
