import React from 'react';
import { Routes as InnerRoutes, Outlet, Route } from '@betalpha/router';

/**
 * register routes start
 */
import NotFoundPage from '@/components/not-found';
import ETFCompassHome from '@/views/fund-compass-home';
import ETFCompassDetail from '@/views/fund-compass-detail';

/**
 * register routes end
 */

const Routes = () => {
  /**
   * 兼容旧版路由:
   *  请求路径示例：api4.betalpha.com/etf-compass
   *  请求路径示例：api4.betalpha.com/etf-compass/00022_OF
   *  */
  if (window.location.pathname.includes('/etf-compass')) {
    return (
      <InnerRoutes>
        <Route path="/etf-compass" element={<Outlet />}>
          <Route index element={<ETFCompassHome />} />
          <Route path=":fundId" element={<ETFCompassDetail />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </InnerRoutes>
    );
  }

  /**
   * 域名请求:
   *  请求路径示例：etf.betalpha.com
   *  请求路径示例：etf.betalpha.com/00022_OF
   *  */
  return (
    <InnerRoutes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<ETFCompassHome />} />
        <Route path=":fundId" element={<ETFCompassDetail />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </InnerRoutes>
  );
};

export default Routes;
