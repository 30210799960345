import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useSize } from 'ahooks';
import { EChartsOption, SeriesOption } from 'echarts';
import ReactEcharts, { EChartsReactProps } from 'echarts-for-react';
import { flow, isArray, isNil, map, merge, prop } from 'lodash/fp';

import { fastNth } from '@betalpha/stdlib/base';
import { formatPercentage } from '@/util/numberFormatter';
import { getChartDataZoomConfig, getStyle } from '@/util/chart';
import { CHART_COLOR } from '../../views/fund-evaluation-detail/constants';

const mergeSeries = (item: SeriesOption) => {
  if (isNil(item)) return item;
  if (item.type === 'line') {
    return merge(item)({
      symbol: 'none'
    });
  }
  if (item.type === 'bar') {
    return merge(item)({
      barGap: '6%',
      label: {
        show: true,
        fontSize: 12,
        color: '#666666',
        formatter: ({ value }: any) => {
          if (isArray(value)) return formatPercentage(fastNth(1)(value));
          return formatPercentage(value);
        }
      }
    });
  }
  return item;
};

const getChartBaseOptions = (options?: EChartsOption, showDataZoom?: boolean) => {
  let mergedSeries = prop('series')(options);
  if (isArray(mergedSeries)) {
    mergedSeries = map(mergeSeries)(mergedSeries);
  } else mergedSeries = mergeSeries(mergedSeries);
  const defaultLegendConfig: EChartsOption['legend'] = {
    left: 'center',
    bottom: 0,
    top: 'auto',
    itemGap: 30,
    padding: 0,
    icon: 'roundRect',
    itemWidth: 15,
    itemHeight: 10,
    textStyle: { fontWeight: 400, color: '#666666', fontSize: 10 }
  };
  const mergedLegend = flow(prop('legend'), (legend) => {
    if (isArray(legend)) {
      return map(merge(defaultLegendConfig))(legend);
    }
    return merge(defaultLegendConfig)(legend);
  })(options);
  return merge<EChartsOption>({
    grid: { left: 0, top: 0, bottom: 64, right: 0 },
    textStyle: {
      fontFamily: 'DINAlternate',
      fontWeight: 'bold'
    },
    legend: mergedLegend,
    tooltip: {
      show: false
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      splitLine: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: '#333'
        }
      },
      axisLabel: {
        hideOverlap: true,
        color: '#999999',
        fontSize: 14
      },
      axisTick: { show: false }
    },
    yAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLabel: {
        formatter: (value: number) => formatPercentage(value)
      }
    },
    color: CHART_COLOR,
    series: mergedSeries,
    ...(showDataZoom
      ? {
          dataZoom: [getChartDataZoomConfig()]
        }
      : {})
  })(options);
};

export type WrapperProps = {
  className?: string;
  options?: EChartsOption;
  showDataZoom?: boolean;
  width?: number | string;
  height?: number | string;
  events?: Record<string, (...args: any[]) => void>;
} & Partial<Omit<EChartsReactProps, 'option'>>;

export default forwardRef<any, WrapperProps>(
  ({ className, options, showDataZoom, width, height, events, opts, ...restProps }, ref) => {
    const style = useMemo(() => getStyle(width, height), [height, width]);
    const containerRef = useRef<any>(null);
    const size = useSize(containerRef);
    const [chartStyle, setChartStyle] = useState(style);
    useEffect(() => {
      if (size && size?.width && size.height) {
        setChartStyle(getStyle(size.width, size.height));
      }
    }, [size]);
    const option = useMemo(() => getChartBaseOptions(options, showDataZoom), [options, showDataZoom]);

    const chartRef = useRef<any>(null);
    useEffect(() => {
      if (chartRef) chartRef.current?.resize();
    }, []);
    useImperativeHandle(ref, () => chartRef.current);

    const finalOpts = useMemo(() => merge({ renderer: 'svg' })(opts), [opts]);

    return (
      <div style={style} className={className} ref={containerRef}>
        <ReactEcharts
          ref={chartRef}
          option={option}
          onEvents={events}
          style={chartStyle}
          opts={finalOpts}
          {...restProps}
        />
      </div>
    );
  }
);
