import { Mask, SpinLoading } from 'antd-mobile';
import { FC } from 'react';
import style from './index.module.less';

export const CustomSpin: FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <Mask visible={loading} color="white" getContainer={null} className={style.Mask} disableBodyScroll={false}>
      <SpinLoading color="primary" />
    </Mask>
  );
};
