import { getMessage } from '@betalpha/intl';
import React, { ReactNode, useMemo } from 'react';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';
import { useParams } from '@betalpha/router';
import Rate from '@/components/rate';
import { SimilarETFCompareTabsType } from '@/views/fund-compass-detail/constants';
import { useGetETFSimilarETFCompareQuery } from '@/infra/api/etf-funds';
import { fixedNumber } from '@/util/numberFormatter';
import { ETFSimilarETFCompareBody } from '@/interface/etfFund';
import CompareTable, { CompareTableDataType } from '../compare-table';
import Tips from '../tips';
import style from './index.module.less';

const useGetData = (currentTab: CompareDetailProps['currentTab']) => {
  const { fundId } = useParams();
  const { data } = useGetETFSimilarETFCompareQuery(fundId ?? '', { skip: !fundId });
  const { indexTrackAbility, liquidity, feeRatio, compareSummary } = data ?? {};
  return useMemo<{
    dataSource: CompareTableDataType[];
    tips: ReactNode;
    compareSummary: ETFSimilarETFCompareBody['compareSummary'];
  }>(() => {
    switch (currentTab) {
      case 'INDEX_TRACKING_ABILITY':
        return {
          dataSource: [
            {
              type: getMessage('indexTrackRating'),
              curerntEtfFund: indexTrackAbility?.trackRating?.fundValue ? (
                <Rate wrapClassName={style.Rate} value={indexTrackAbility?.trackRating?.fundValue} />
              ) : (
                '--'
              ),
              sameCategoryEtfFund: indexTrackAbility?.trackRating?.sameTypeFund ? (
                <Rate wrapClassName={style.Rate} value={indexTrackAbility?.trackRating?.sameTypeFund} />
              ) : (
                '--'
              )
            },
            {
              type: getMessage('trackDiff'),
              curerntEtfFund: percentage(indexTrackAbility?.trackError?.fundValue),
              sameCategoryEtfFund: percentage(indexTrackAbility?.trackError?.sameTypeFund)
            },
            {
              type: getMessage('relativeIndexExcess'),
              curerntEtfFund: percentage(indexTrackAbility?.relativeIndexExcess?.fundValue),
              sameCategoryEtfFund: percentage(indexTrackAbility?.relativeIndexExcess?.sameTypeFund)
            }
          ],
          tips: getMessage('indexTrackAbilityTips'),
          compareSummary: compareSummary as ETFSimilarETFCompareBody['compareSummary']
        };
      case 'ETF_MOBILITY':
        return {
          dataSource: [
            {
              type: getMessage('ETFMobilityRating'),
              curerntEtfFund: liquidity?.liquidityRating?.fundValue ? (
                <Rate wrapClassName={style.Rate} value={liquidity?.liquidityRating?.fundValue} />
              ) : (
                '--'
              ),
              sameCategoryEtfFund: liquidity?.liquidityRating?.sameTypeFund ? (
                <Rate wrapClassName={style.Rate} value={liquidity?.liquidityRating?.sameTypeFund} />
              ) : (
                '--'
              )
            },
            {
              type: getMessage('fundScale'),
              curerntEtfFund: fixedNumber(liquidity?.fundScale?.fundValue),
              sameCategoryEtfFund: fixedNumber(liquidity?.fundScale?.sameTypeFund)
            },
            {
              type: getMessage('fundPortion'),
              curerntEtfFund: fixedNumber(liquidity?.fundShare?.fundValue),
              sameCategoryEtfFund: fixedNumber(liquidity?.fundShare?.sameTypeFund)
            },
            {
              type: getMessage('discountRate'),
              curerntEtfFund: percentage(liquidity?.discountRate?.fundValue),
              sameCategoryEtfFund: percentage(liquidity?.discountRate?.sameTypeFund)
            },
            {
              type: getMessage('portionChange'),
              curerntEtfFund: percentage(liquidity?.shareChange?.fundValue),
              sameCategoryEtfFund: percentage(liquidity?.shareChange?.sameTypeFund)
            },
            {
              type: getMessage('weeklyVolume'),
              curerntEtfFund: fixedNumber(liquidity?.weeklyTurnover?.fundValue),
              sameCategoryEtfFund: fixedNumber(liquidity?.weeklyTurnover?.sameTypeFund)
            },
            {
              type: getMessage('monthNetInflowAmount'),
              curerntEtfFund: fixedNumber(liquidity?.monthlyInflowAmount?.fundValue),
              sameCategoryEtfFund: fixedNumber(liquidity?.monthlyInflowAmount?.sameTypeFund)
            }
          ],
          tips: getMessage('ETFMobilityTips'),
          compareSummary: compareSummary as ETFSimilarETFCompareBody['compareSummary']
        };
      case 'ETF_FEE':
        return {
          dataSource: [
            {
              type: getMessage('ETFFeeRating'),
              curerntEtfFund: feeRatio?.feeRatioRating?.fundValue ? (
                <Rate wrapClassName={style.Rate} value={feeRatio?.feeRatioRating?.fundValue} />
              ) : (
                '--'
              ),
              sameCategoryEtfFund: feeRatio?.feeRatioRating?.sameTypeFund ? (
                <Rate wrapClassName={style.Rate} value={feeRatio?.feeRatioRating?.sameTypeFund} />
              ) : (
                '--'
              )
            },
            {
              type: getMessage('managementFee'),
              curerntEtfFund: percentage(feeRatio?.managementFee?.fundValue),
              sameCategoryEtfFund: percentage(feeRatio?.managementFee?.sameTypeFund)
            },
            {
              type: getMessage('custodialFee'),
              curerntEtfFund: percentage(feeRatio?.custodialFee?.fundValue),
              sameCategoryEtfFund: percentage(feeRatio?.custodialFee?.sameTypeFund)
            }
          ],
          tips: getMessage('ETFFeeTips'),
          compareSummary: compareSummary as ETFSimilarETFCompareBody['compareSummary']
        };
      default:
        return { dataSource: [], tips: '--', compareSummary: 'COMPARE_ALL' };
    }
  }, [currentTab, indexTrackAbility, liquidity, feeRatio, compareSummary]);
};
interface CompareDetailProps {
  currentTab?: SimilarETFCompareTabsType[number];
}
export default React.memo<CompareDetailProps>(({ currentTab }) => {
  const { dataSource, tips, compareSummary } = useGetData(currentTab);
  return (
    <div className={style.IndexTrackAbilityWrap}>
      <Tips>{tips}</Tips>
      <div className={style.TrackAbilityTableWrap}>
        <CompareTable dataSource={dataSource} compareSummary={compareSummary} />
      </div>
    </div>
  );
});
