import React, { createContext, useEffect, useMemo } from 'react';
import { Routes as InnerRoutes, Outlet, Route, useLocation } from '@betalpha/router';

/**
 * register routes start
 */
import { includes, last } from 'lodash/fp';
import FundEvaluationHome from '@/views/fund-evaluation-home';
import FundEvaluationDetail from '@/views/fund-evaluation-detail';
import ETFCompassHome from '@/views/fund-compass-home';
import ETFCompassDetail from '@/views/fund-compass-detail';
import NotFoundPage from '@/components/not-found';
import BondEvaluationHome from '@/views/bond-evaluation-home';
import BondEvaluationDetail from '@/views/bond-evaluation-detail';

/**
 * register routes end
 */

export const HistoryStackContext = createContext<string[]>([]);

const ROOT_PATH = ['/', '/etf-compass'];

const Routes = () => {
  const initialStack = useMemo<string[]>(() => [], []);
  const { pathname } = useLocation();

  useEffect(() => {
    if (last(initialStack) !== pathname) {
      if (includes(pathname)(ROOT_PATH)) initialStack.splice(0, initialStack.length);
      initialStack.push(pathname);
    }
  }, [initialStack, pathname]);

  return (
    <HistoryStackContext.Provider value={initialStack}>
      <InnerRoutes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<FundEvaluationHome />} />
          <Route path="/fund-detail/:fundId" element={<FundEvaluationDetail />} />
          <Route path="/etf-compass" element={<ETFCompassHome />} />
          <Route path="/etf-compass/:fundId" element={<ETFCompassDetail />} />
          <Route path="/bond-fund" element={<BondEvaluationHome />} />
          <Route path="/bond-fund/:fundId" element={<BondEvaluationDetail />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </InnerRoutes>
    </HistoryStackContext.Provider>
  );
};

export default Routes;
