import { getMessage } from '@betalpha/intl';
import React from 'react';
import { mapIndexed } from '@betalpha/stdlib/base';
import CardLayout from '@/components/cardLayout';
import Tabs, { Tab } from '@/components/tabs';
import { useGetMarketMacroSummaryQuery } from '@/infra/api/etf-funds';
import { marketMacroTabItems, marketTabItem } from '../../contants';
import MarketMacro from './components/marketMacro';
import style from './index.module.less';

export default React.memo(() => {
  const { data, isFetching } = useGetMarketMacroSummaryQuery();

  return (
    <CardLayout title={getMessage('marketMacroSummary')} loading={isFetching} className={style.CardLayout}>
      <MarketMacro data={data} />
      <Tabs className={style.Tab} defaultActiveKey="marketValuation">
        {mapIndexed((item: marketTabItem, idx: number) => (
          <Tab key={item?.id || idx} title={getMessage(item?.name)} description={undefined}>
            <item.component />
          </Tab>
        ))(marketMacroTabItems)}
      </Tabs>
    </CardLayout>
  );
});
