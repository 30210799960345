import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from '@betalpha/router';
import { replace } from 'lodash/fp';
import { getMessage } from '@betalpha/intl';
import { useTitle } from 'ahooks';
import { useScrollByAnchorPoint } from '@/hooks/useScrollByAnchorPoint';
import Page from '@/components/page';
import ActionBar from '@/components/action-bar';
import { ETF_COMPASS } from '@/const';
import NoticeBar from '@/components/notice-bar';
import Share from '@/components/share';
import { useSearchPopup } from '@/components/action-bar/hooks';
import { allETFFundsMapSelector } from '@/selectors/etf-funds';
import BasicInfo from './components/basic-info';
import PerformanceTrend from './components/performance-trend';
import PositionCompare from './components/position-compare';
import HeavyHoldings from './components/heavy-holdings';
import SimilarCompare from './components/similar-compare';
import Risk from './components/risk';

export default React.memo(() => {
  useTitle(getMessage('ETFCompass'), { restoreOnUnmount: true });
  const { fundId } = useParams();
  const fundMap = useSelector(allETFFundsMapSelector);

  const fund = useMemo(() => fundMap?.[fundId || ''], [fundMap, fundId]);
  const searchNode = useSearchPopup('ETF_COMPASS');

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [fundId]);

  useScrollByAnchorPoint();

  return (
    <Page
      needFund
      type="ETF_COMPASS"
      actionBar={
        <ActionBar
          rootPath="/"
          title={fund?.fundName}
          subTitle={fund?.fundId ? `(${replace('_OF')('')(fund?.fundId)})` : ''}
          extra={
            <>
              {searchNode}
              {fund ? <Share PDFType="ETF_COMPASS_DETAIL" /> : null}
            </>
          }
        />
      }
    >
      {fund ? (
        <>
          <NoticeBar type={ETF_COMPASS} />
          <BasicInfo />
          <PerformanceTrend />
          <SimilarCompare />
          <PositionCompare />
          <HeavyHoldings />
          <Risk />
        </>
      ) : null}
    </Page>
  );
});
