/**
 * 标题栏
 */
import { memo, FC, useMemo } from 'react';
import { NoticeBar } from 'antd-mobile';
import { useGetBondUpdateCompletedQuery } from '@/infra/api/bond';
import { useGetFundUpdateCompletedQuery } from '@/infra/api/funds';
import { useGetEtfUpdateCompletedQuery } from '@/infra/api/etf-funds';
import { ETF_COMPASS, FUND_EVALUATION, BOND_FUND } from '../../const';

import style from './index.module.less';

type NoticeBarProps = {
  // 先简化属性，有需要用到的再加
  type: string;
};

const ActionBar: FC<NoticeBarProps> = ({ type }) => {
  const { data: fundData } = useGetFundUpdateCompletedQuery(undefined, { skip: type !== FUND_EVALUATION });
  const { data: etfData } = useGetEtfUpdateCompletedQuery(undefined, { skip: type !== ETF_COMPASS });
  const { data: bondData } = useGetBondUpdateCompletedQuery(undefined, { skip: type !== BOND_FUND });

  const hideNotice = useMemo(() => {
    switch (type) {
      case FUND_EVALUATION:
        return fundData?.updateCompleted;
      case ETF_COMPASS:
        return etfData?.updateCompleted;
      case BOND_FUND:
        return bondData?.updateCompleted;
      default:
        return false;
    }
  }, [type, fundData, etfData, bondData]);

  return hideNotice ? null : <NoticeBar content="今日数据更新中，请稍后" color="alert" className={style.Bar} />;
};

export default memo(ActionBar);
