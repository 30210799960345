import { getMessage } from '@betalpha/intl';
import { isEmpty, keys, map } from 'lodash/fp';
import React from 'react';
import { useGetMarketMacroSummaryQuery } from '@/infra/api/etf-funds';
import style from '../index.module.less';

export default React.memo(() => {
  const { data } = useGetMarketMacroSummaryQuery();
  const { marketExpansionTrend } = data || {};
  const { collectEtfMarketInfo, newEtfMarketInfo, top3IncreaseIndustry } = marketExpansionTrend || {};
  const { keyWords: collectKeyWords } = collectEtfMarketInfo || {};

  return (
    <div>
      <div className={style.MarketValuationSummary}>
        {!isEmpty(collectKeyWords)
          ? getMessage('etfMarketExpansionTrendsSummary', {
              expansionTrends: keys(top3IncreaseIndustry).join('、')
            })
          : getMessage('noObviousSubject')}
      </div>
      <div className={style.EchartName}>
        {getMessage('newEtf')}:<span className={style.EtfSize}>{newEtfMarketInfo?.etfSize}</span>
        <span className={style.Pieces}> 支</span>
      </div>
      <div className={style.keyword}>{getMessage('keyword')}:</div>
      <div className={style.keywordPart}>
        {map((key: string) => (
          <div className={style.keywordItem} key={key}>
            {key}
          </div>
        ))(newEtfMarketInfo?.keyWords)}
      </div>
      <div className={style.newETFSummary}>{newEtfMarketInfo?.fundNames?.join('、')}。</div>
      <div className={style.EchartName}>
        {getMessage('fundraisingEtf')}:<span className={style.EtfSize}>{collectEtfMarketInfo?.etfSize}</span>
        <span className={style.Pieces}> 支</span>
      </div>
      <div className={style.keyword}>{getMessage('keyword')}:</div>
      <div className={style.keywordPart}>
        {map((key: string) => (
          <div className={style.keywordItem} key={key}>
            {key}
          </div>
        ))(collectEtfMarketInfo?.keyWords)}
      </div>
      <div className={style.newETFSummary}>{collectEtfMarketInfo?.fundNames?.join('、')}。</div>
    </div>
  );
});
