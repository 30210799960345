import React, { ReactNode } from 'react';
import cn from 'classnames';
import EmptyContent from '@/assets/emptyContent.svg';
import style from './index.module.less';

export default React.memo<{
  className?: string;
  message?: string;
  children?: ReactNode;
  style?: React.CSSProperties;
}>(({ className, message, children, style: styles }) => {
  return (
    <div className={cn(style.Container, className)} style={styles}>
      <img src={EmptyContent} alt="No Content" />
      {message && <div className={style.Message}>{message}</div>}
      {children && <div className={style.Message}>{children}</div>}
    </div>
  );
});
