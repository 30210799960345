import { configureStore, ThunkAction, Action } from '@betalpha/redux-toolkit';
import { routerMiddleware } from '@betalpha/router';
import logger from 'redux-logger';
import baseApi from '../api/base-api';
import reducers from './reducers';

const getDefaultMiddlewareOption = () => {
  if (process.env.NODE_ENV === 'production') {
    // PS: 序列化检查很耗费时间，在生产环境应该关掉
    return { serializableCheck: false };
  }
  return { serializableCheck: true };
};
export const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    const defaults = getDefaultMiddleware(getDefaultMiddlewareOption());
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaults.push(routerMiddleware, baseApi.middleware as any);
    if (process.env.NODE_ENV === 'development') {
      defaults.push(logger);
    }

    return defaults;
  },
  reducer: reducers
});

export type StoreType = typeof store;
export type AppDispatch = typeof store.dispatch;
export type AppGetState = typeof store.getState;
export type RootState = ReturnType<AppGetState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
