import React, { ReactNode, useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { first, isEmpty, last, map } from 'lodash/fp';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';

import { useParams } from '@betalpha/router';
import { mapIndexed } from '@betalpha/stdlib/base';
import YieldTrendChart from '@/base-components/charts/yieldTrendChart';

import CardLayout from '@/components/cardLayout';
import { useGetETFFundPerformanceTrendQuery } from '@/infra/api/etf-funds';
import { SectionReturnType } from '@/interface/etfFund';
import EmptyContent from '@/components/emptyContent';
import DetailModal from '@/components/detail-modal';
import ColorNumber from '../../../../components/colorNumber';
import style from './index.module.less';
import { PERIOD, useGetPerformanceTrend } from './hooks';

export default React.memo(() => {
  const { fundId } = useParams();
  const { data, isFetching } = useGetETFFundPerformanceTrendQuery(fundId ?? '', { skip: !fundId });
  const { dates, series, chartOptions } = useGetPerformanceTrend(data);

  const { benchmarkSectionReturn, trackIndexSectionReturn, fundSectionReturn } = data || {};

  const sectionTableHead = useMemo(() => ['period', 'currentETF', 'trackIndex', 'csIndex800'], []);

  const infosData = useMemo(
    () => [
      {
        label: (
          <>
            {getMessage('trackDiff')}{' '}
            <DetailModal
              title={getMessage('etfDetailTrackDiffTipsTitle')}
              content={getMessage('etfDetailTrackDiffTipsContent')}
            />
          </>
        ),
        value: percentage(data?.trackError)
      },
      {
        label: getMessage('sharpeRatio'),
        value: data?.sharpeRatio ? Math.round((data?.sharpeRatio ?? NaN) * 100) / 100 : '--'
      },
      {
        label: (
          <>
            {getMessage('infoRatio')}{' '}
            <DetailModal
              title={getMessage('etfDetailTrackDiffTipsTitle')}
              content={getMessage('etfDetailTrackDiffTipsContent')}
            />
          </>
        ),
        value: data?.infoRatio ? Math.round((data?.infoRatio ?? NaN) * 100) / 100 : '--'
      },
      {
        label: getMessage('maxDrawDown'),
        value: percentage(data?.maxDrawDown)
      }
    ],
    [data?.infoRatio, data?.maxDrawDown, data?.sharpeRatio, data?.trackError]
  );
  return (
    <CardLayout
      title={
        <div className={style.TitleWrap}>
          <div className={style.Title}>{getMessage('performanceTrend')}</div>
          {!isEmpty(dates) && <div className={style.PerformanceInterval}>{`(${first(dates)}~${last(dates)})`}</div>}
        </div>
      }
      className={style.PerformanceTrendWrap}
      loading={isFetching}
    >
      <div className={style.InfosWrap}>
        {
          mapIndexed(({ label, value }: { label: string; value: ReactNode }) => (
            <div className={style.Item} key={label}>
              <div className={style.Label}>{label}</div>
              <div className={style.Value}>{value}</div>
            </div>
          ))(infosData) as ReactNode
        }
      </div>
      <div className={style.ChartWrap}>
        {!isEmpty(dates) ? (
          <YieldTrendChart dates={dates} series={series} showDataZoom={false} height={216} onZero {...chartOptions} />
        ) : (
          <EmptyContent message={getMessage('noData')} />
        )}
      </div>
      {data && (
        <div className={style.TableWrap}>
          <div className={style.TableHead}>
            {map((item: string) => <div key={item}>{getMessage(item)}</div>)(sectionTableHead)}
          </div>
          <div className={style.Divider} />
          {map((item: keyof SectionReturnType) => (
            <div className={style.TableData} key={item}>
              <div>{getMessage(item)}</div>
              <div>
                <ColorNumber
                  formatter={(val: number | undefined) => percentage(val)}
                  value={fundSectionReturn?.[item]}
                />
              </div>
              <div>
                <ColorNumber
                  formatter={(val: number | undefined) => percentage(val)}
                  value={trackIndexSectionReturn?.[item]}
                />
              </div>
              <div>
                <ColorNumber
                  formatter={(val: number | undefined) => percentage(val)}
                  value={benchmarkSectionReturn?.[item]}
                />
              </div>
            </div>
          ))(PERIOD)}
        </div>
      )}
    </CardLayout>
  );
});
