import React from 'react';
import { ErrorBlock } from 'antd-mobile';
import { getMessage } from '@betalpha/intl';
import { useTitle } from 'ahooks';
import notFoundImg from '@/assets/404.svg';

const NotFoundPage = () => {
  useTitle(getMessage('notFoundPage'), { restoreOnUnmount: true });
  return <ErrorBlock image={notFoundImg} title={getMessage('notFoundPage')} fullPage />;
};

export default NotFoundPage;
