import React from 'react';
import { useTitle } from 'ahooks';
import { getMessage } from '@betalpha/intl';
import ActionBar from '@/components/action-bar';
import NoticeBar from '@/components/notice-bar';
import FundSearch from '@/components/fundSearch';
import { ETF_COMPASS } from '@/const';
import Page from '@/components/page';
import Share from '@/components/share';
import style from './index.module.less';
import MarketMacroSummary from './components/marketMacroSummary';
import IndustryRecommend from './components/IndustryRecommend';

export default React.memo(() => {
  useTitle(getMessage('ETFCompass'), { restoreOnUnmount: true });
  return (
    <Page
      actionBar={
        <ActionBar
          back={false}
          rootPath="/"
          title={<FundSearch type="ETF_COMPASS" />}
          extra={
            <div className={style.ShareWrap}>
              <Share PDFType="ETF_COMPASS_HOME" />
            </div>
          }
        />
      }
      className={style.HomeViewWrap}
    >
      <NoticeBar type={ETF_COMPASS} />
      <MarketMacroSummary />
      <IndustryRecommend />
    </Page>
  );
});
