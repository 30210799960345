import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { getMessage } from '@betalpha/intl';
import { ReactComponent as ArrowDown } from '@/assets/arrow-down.svg';
import Icon from '@/components/icon';
import style from './index.module.less';

interface EvaluationDetailTriggerProps {
  children?: ReactNode;
  text?: ReactNode;
  className?: string;
  collapseText?: string;
  initalizeHeight?: number;
}
const EvaluationDetailTrigger = ({
  children,
  className,
  text = getMessage('showMore'),
  collapseText = getMessage('collapsePanel'),
  initalizeHeight
}: EvaluationDetailTriggerProps) => {
  const [hiddenEvaluation, setHiddenEvaluation] = React.useState(true);
  const height = hiddenEvaluation && initalizeHeight ? initalizeHeight : 'auto';
  return (
    <div className={classNames(className, style.EvaluationDetailTrigger)}>
      <div style={{ height, overflowY: 'hidden' }}>{children}</div>
      <p className={style.Trigger} onClick={() => setHiddenEvaluation(!hiddenEvaluation)}>
        <Icon
          svg={ArrowDown}
          className={style.Icon}
          style={{ transform: hiddenEvaluation ? 'none' : 'rotate(180deg)' }}
          size={14}
        />
        {hiddenEvaluation ? text : collapseText ?? text}
      </p>
    </div>
  );
};

export default React.memo<EvaluationDetailTriggerProps>(EvaluationDetailTrigger);
