export const CHART_COLOR = ['#1A396A', '#CFB47F', '#9CACBC', '#7494B4', '#74A5B4'];

export type SimilarETFCompareTabsType = ['INDEX_TRACKING_ABILITY', 'ETF_MOBILITY', 'ETF_FEE'];
export const SIMILAR_ETF_COMPARE_TABS: SimilarETFCompareTabsType = [
  'INDEX_TRACKING_ABILITY',
  'ETF_MOBILITY',
  'ETF_FEE'
];

export const NOT_PERCENTAGE_FEATURE_FACTORS = [
  'fund_fi_ttm_roe',
  'fund_MY_stockRelativePE_level1_ZJ',
  'fund_FI_TTM_roe',
  'fund_MY_zhaoshang_case9_zx_ZJ',
  'fund_MY_stockRelativePE_level1_ZJ',
  'fund_MY_zhaoshang_case9_zx_ZJ'
];

export const etfRiskValuationEvaluateSummary = {
  HIGH: 'etfRiskValuationEvaluate_high',
  MEDIUM: 'etfRiskValuationEvaluate_middle',
  LOW: 'etfRiskValuationEvaluate_low'
};

export const NO_SIMILAR_ETF_SUMMARY = ['NONE_SAME', 'COMPARE_ALL'];
