import React, { useCallback, useState } from 'react';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import { mapIndexed } from '@betalpha/stdlib/base';
import { isEmpty } from 'lodash/fp';
import CardLayout from '@/components/cardLayout';
import { useGetManagerEvaluationQuery } from '@/infra/api/funds';
import { ManagerEvaluationBody } from '@/interface/fundDetail';
import EmptyContent from '@/components/emptyContent';
import FundHistoryInfo from './fundHistoryInfo';
import style from './index.module.less';
import FundManagerEvaluation from './fundManagerEvaluation';

export default React.memo(() => {
  const { fundId } = useParams();
  const { data: managerEvaluation, isFetching } = useGetManagerEvaluationQuery(fundId || '', { skip: !fundId });

  const { fundManagerInfos } = managerEvaluation || { fundManagerInfos: [] };
  const [currentManagerIndex, setCurrentManagerIndex] = useState(0);

  const handleTabClick = useCallback((index: number) => {
    setCurrentManagerIndex(index);
  }, []);
  return (
    <CardLayout title={getMessage('fundManagerEvaluation')} loading={isFetching}>
      {!isEmpty(fundManagerInfos) ? (
        <>
          <div className={style.Tabs}>
            {mapIndexed((item: ManagerEvaluationBody['fundManagerInfos'][number], index: number) => {
              const { name } = item ?? {};
              return (
                <div
                  key={name}
                  className={index === currentManagerIndex ? style.ActiveTab : ''}
                  onClick={() => handleTabClick(index)}
                >
                  {name}
                </div>
              );
            })(fundManagerInfos)}
          </div>
          <FundManagerEvaluation fundManagerInfo={fundManagerInfos[currentManagerIndex]} fundId={fundId || ''} />
        </>
      ) : (
        <EmptyContent message={getMessage('noData')} />
      )}
      <FundHistoryInfo managerEvaluation={managerEvaluation} />
    </CardLayout>
  );
});
