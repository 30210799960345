import React, { useMemo, useRef } from 'react';
import { getMessage } from '@betalpha/intl';
import { EChartsOption } from 'echarts';
import { first, identity, isEmpty, isNumber, last, merge, size, zip } from 'lodash/fp';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';
import { useSize } from 'ahooks';
import ColorBlockTitle from '@/components/color-block-title';
import { ETFRiskProbeBody } from '@/interface/etfFund';
import BaseEcharts from '@/components/baseEcharts';
import EmptyContent from '@/components/emptyContent';
import { toFixedNumber } from '@/util/numberFormatter';
import { useCalculateMarkLabelPositionWithoutMarkLine, useGetDefaultChartOptions } from '../../hooks';
import style from './index.module.less';

interface IndustryProsperityProps {
  data: ETFRiskProbeBody['industryProsperity'] | undefined;
}
export default React.memo<IndustryProsperityProps>(({ data }) => {
  const { dates: prosperityDates, historyValues: prosperityHistoryValues } = data?.prosperityTrend ?? {
    dates: [],
    historyValues: []
  };
  const { dates: roeDates, historyValues: roeHistoryValues } = data?.roeTrend ?? { dates: [], historyValues: [] };
  const roeChartData = useMemo(() => {
    return zip(roeDates)(roeHistoryValues) as [string, number][];
  }, [roeDates, roeHistoryValues]);
  const prosperityChartData = useMemo(() => {
    return zip(prosperityDates)(prosperityHistoryValues) as [string, number][];
  }, [prosperityDates, prosperityHistoryValues]);

  const defaultOptions = useGetDefaultChartOptions(
    size(prosperityDates) > size(roeDates) ? prosperityDates : roeDates,
    24
  );

  const chartsWrapRef = useRef(null);

  const { width } = useSize(chartsWrapRef) ?? { width: 0 };

  const CHART_LEGEND_WRAP_WIDTH_BONDARY = 330;

  const options = useMemo<EChartsOption>(
    () =>
      merge<EChartsOption>({
        grid: { left: 24, top: 10, bottom: 60, right: 24 },
        legend: {
          selectedMode: false,
          itemGap: width > CHART_LEGEND_WRAP_WIDTH_BONDARY ? 30 : 4,
          bottom: 0,
          top: 'auto',
          orient: width > CHART_LEGEND_WRAP_WIDTH_BONDARY ? 'horizontal' : 'vertical',
          align: 'left',
          left: 'center',
          icon: 'path://M30 0 L200 0 Q 230 30 200 60 L30 60 Q 0 30 30 0 Z'
        },
        xAxis: {
          axisLine: { show: true }
        },
        yAxis: [
          {
            id: 'yAxis0',
            axisLabel: {
              formatter: (val: number) => percentage(val, { digit: 0 }),
              fontWeight: 'lighter',
              fontSize: 10,
              color: '#666'
            },
            axisLine: { show: false, onZero: false },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#ECECEC',
                type: 'dotted'
              }
            }
          },
          {
            id: 'yAxis1',
            axisLabel: {
              formatter: identity,
              fontWeight: 'lighter',
              fontSize: 10,
              color: '#666'
            },
            axisLine: { show: false, onZero: false },
            inverse: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#ECECEC',
                type: 'dotted'
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            yAxisIndex: 0,
            name: getMessage('ROE'),
            color: '#CFB47F',
            data: roeChartData,
            label: { show: false },
            silent: true
          },
          {
            type: 'line',
            yAxisIndex: 1,
            name: getMessage('rankingOfExpectedProfitGrowthRate'),
            color: '#1A396A',
            data: prosperityChartData,
            silent: true
          }
        ]
      })(defaultOptions),
    [defaultOptions, prosperityChartData, roeChartData, width]
  );

  const { onChartReady, markLabelPositionInfo, triangleNode } = useCalculateMarkLabelPositionWithoutMarkLine({
    svgColor: '#1a396a',
    values: [
      { yAxisId: 'yAxis0', value: last(roeHistoryValues) ?? NaN, nodeHeight: 56 },
      { yAxisId: 'yAxis1', value: last(prosperityHistoryValues) ?? NaN, nodeHeight: 44 }
    ]
  });

  return (
    <div className={style.IndustryProsperityWrap}>
      <div className={style.TabDescription}>{getMessage('industryProsperityTips')}</div>
      <div className={style.ContentWrap}>
        <ColorBlockTitle className={style.Title}>
          <div>{getMessage('industryProsperity')}</div>
          {!isEmpty(roeDates) && <div className={style.Range}>{`(${first(roeDates)}~${last(roeDates)})`}</div>}
        </ColorBlockTitle>
        {data ? (
          <div className={style.ChartWrap} ref={chartsWrapRef}>
            <BaseEcharts className={style.Chart} options={options} height={204} onChartReady={onChartReady} />
            {isNumber(last(roeHistoryValues)) && isNumber(last(prosperityHistoryValues)) && (
              <div className={style.MarkWrap}>
                <div
                  className={style.MarkLabel}
                  style={{
                    top: markLabelPositionInfo?.[0]?.top ?? -9999
                  }}
                >
                  <div className={style.Value}>{percentage(last(roeHistoryValues))}</div>
                  <div className={style.Label}>
                    {(() => {
                      const msg = getMessage('currentRoe').split('');
                      msg.splice(5, 0, '\n');
                      return msg.join('');
                    })()}
                  </div>
                </div>
                <div
                  className={style.MarkLabel}
                  style={{
                    top: markLabelPositionInfo?.[1]?.top ?? -9999
                  }}
                >
                  <div className={style.Value}>{toFixedNumber(1)(last(prosperityHistoryValues))}</div>
                  <div className={style.Label}>{getMessage('currentRank')}</div>
                </div>
                {triangleNode}
              </div>
            )}
          </div>
        ) : (
          <EmptyContent message={getMessage('noData')} />
        )}
      </div>
    </div>
  );
});
