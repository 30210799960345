import React, { ReactNode } from 'react';
import cn from 'classnames';
import style from './index.module.less';

interface ColorBlockTitleProps {
  children: ReactNode;
  className?: string;
}
const ColorBlockTitle = ({ children, className }: ColorBlockTitleProps) => {
  return <div className={cn(style.Title, className)}>{children}</div>;
};

export default React.memo(ColorBlockTitle);
