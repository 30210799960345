import React, { useMemo } from 'react';
import { merge } from 'lodash/fp';
import { EChartsOption } from 'echarts';
import { getMessage } from '@betalpha/intl';
import BaseEcharts from '@/components/baseEcharts';
import questionImg from '@/assets/fundEvaluationReport/question.svg';
import EmptyContent from '@/components/emptyContent';
import { formatPercentage } from '@/util/numberFormatter';
import { treeDataType } from '@/interface/fundDetail';

const questionIcon = new Image();
questionIcon.src = questionImg;

export const getTreeChartOptions = (treeData: treeDataType, extraOptions: any): EChartsOption =>
  merge({
    tooltip: {
      show: false
    },
    xAxis: {
      show: false
    },
    yAxis: {
      show: false
    },
    series: [
      {
        type: 'tree',
        data: [treeData],
        left: '10%',
        right: '15%',
        top: '0%',
        bottom: '0%',

        symbol: 'none',
        // orient: "horizontal",
        orient: 'LR',
        // layout: "orthogonal",

        expandAndCollapse: false, // 默认展开

        initialTreeDepth: 3, // 默认：2

        label: {
          normal: {
            rotate: 0,
            verticalAlign: 'middle',
            align: 'center',
            color: '#666666',
            fontWeight: 'normal',
            formatter: (params: any) => {
              const { value, data } = params;
              let question = '';
              if (data.tip) {
                question = '{question|}';
              }
              if (value > 0) {
                return [`{red|${formatPercentage(params.value)}}\n${params.name}${question}`];
              }
              if (value < 0) {
                return [`{green|${formatPercentage(params.value)}}\n${params.name}${question}`];
              }
              return [`{normal|${formatPercentage(params.value)}}\n${params.name}${question}`];
            },
            rich: {
              red: {
                color: '#E86386',
                padding: [0, 6, 4, 6],
                fontWeight: 'normal'
              },
              normal: {
                color: '#101010',
                padding: [0, 6, 4, 6],
                fontWeight: 'normal'
              },
              green: {
                color: '#50BF14',
                padding: [0, 0, 4, 0],
                fontWeight: 'normal'
              },
              grey: {
                color: '#A0A0A0',
                padding: [0, 0, 4, 0],
                fontWeight: 'normal'
              },
              question: {
                fontSize: 13,
                align: 'center',
                verticalAlign: 'bottom',
                backgroundColor: {
                  image: questionIcon
                }
              }
            },
            backgroundColor: '#f3f5fa',
            padding: [7, 15],
            borderRadius: 4
          }
        },
        edgeShape: 'polyline', // 线条形状折线
        leaves: {
          label: {
            normal: {
              position: 'inside',
              rotate: 0,
              verticalAlign: 'middle',
              align: 'center',
              fontSize: 12,
              distance: 20
            }
          }
        },

        animationDurationUpdate: 750
      }
    ]
  })(extraOptions);

const getHandleOptions = (treeData: treeDataType) =>
  getTreeChartOptions(treeData, {
    tooltip: {
      // formatter: attributionTooltipFormatter(formatMessage),
    }
  });

export default React.memo<{
  treeData: treeDataType;
  height?: number;
  isEmpty?: boolean;
  handleChartClick?: any;
}>(({ treeData, height, isEmpty, handleChartClick }) => {
  const finalHeight = height || 180;

  const handleOptions = useMemo(() => getHandleOptions(treeData), [treeData]);

  return isEmpty ? (
    <EmptyContent style={{ height: finalHeight }}>
      <strong>{getMessage('noData')}</strong>
    </EmptyContent>
  ) : (
    <BaseEcharts
      options={handleOptions}
      height={finalHeight}
      opts={{ renderer: 'svg' }}
      events={{ click: handleChartClick }}
    />
  );
});
