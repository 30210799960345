import React, { useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { map } from 'lodash/fp';
import ColorBlockTitle from '@/components/color-block-title';
import { ETFRiskProbeBody } from '@/interface/etfFund';
import Rate from '@/components/rate';
import {
  LEVEL_MESSAGE_KEY_MAP_1,
  LEVEL_MESSAGE_KEY_MAP_10,
  LEVEL_MESSAGE_KEY_MAP_9
} from '@/views/fund-evaluation-detail/constants';
import { getNotNillMessage } from '../../hooks';
import style from './index.module.less';

type RatingItemType = {
  name: string;
  rating: number | undefined;
  summary: string;
};

interface TransactionDimensionProps {
  data: ETFRiskProbeBody['transactionDimension'] | undefined;
}
export default React.memo<TransactionDimensionProps>(({ data }) => {
  const ratingItems = useMemo<RatingItemType[]>(
    () => [
      {
        name: getMessage('industryTractionMomentum'),
        rating: data?.industryRating?.rating,
        summary: getMessage('industryTractionMomentumEvaluate', {
          value: getNotNillMessage(data?.industryRating?.level, LEVEL_MESSAGE_KEY_MAP_9)
        })
      },
      {
        name: getMessage('northernCapitalMovement'),
        rating: data?.capitalRating?.rating,
        summary: getMessage('northernCapitalMovementEvaluate', {
          value: getNotNillMessage(data?.capitalRating?.level, LEVEL_MESSAGE_KEY_MAP_10)
        })
      },
      {
        name: getMessage('industryMicrostructure'),
        rating: data?.microStructureRating?.rating,
        summary: getMessage('industryMicrostructureEvaluate', {
          value: getNotNillMessage(data?.microStructureRating?.level, LEVEL_MESSAGE_KEY_MAP_1)
        })
      }
    ],
    [data]
  );
  return (
    <div className={style.TransactionDimensionWrap}>
      <div className={style.TabDescription}>{getMessage('transactionDimensionTips')}</div>
      <div className={style.ContentWrap}>
        <ColorBlockTitle className={style.TransactionRating}>
          {getMessage('etfTransactionDimensionRatingMobile')}
          <Rate wrapClassName={style.Rating} value={data?.transactionRating?.rating} />
        </ColorBlockTitle>
        <div className={style.RatingItemsWrap}>
          {map((item: RatingItemType) => (
            <div className={style.RatingItem} key={item.name}>
              <div className={style.RatingWrap}>
                {item.name}
                <Rate wrapClassName={style.Rating} value={item.rating} />
              </div>
              <div className={style.Summary}>{item.summary}</div>
            </div>
          ))(ratingItems)}
        </div>
      </div>
    </div>
  );
});
