/**
 * 标题栏
 */
import { FC, memo, useCallback, ReactNode, useContext } from 'react';
import { useNavigate } from '@betalpha/router';
import { findLastIndex } from 'lodash/fp';
import { ReactComponent as BackIcon } from '@/assets/goBack.svg';
import { HistoryStackContext } from '@/infra/router/routes';
import Icon from '../icon';

import style from './index.module.less';

type ActionBarProps = {
  // 先简化属性，有需要用到的再加
  rootPath: string;
  title?: ReactNode;
  subTitle?: string;
  back?: boolean;
  extra?: ReactNode;
};

const ActionBar: FC<ActionBarProps> = ({ rootPath, title, subTitle, back, extra }) => {
  const navigateTo = useNavigate();
  const stack = useContext(HistoryStackContext);
  const { length: stackLength } = stack || {};
  const idx = findLastIndex((item) => item === rootPath)(stack);

  const navigateToRootPath = useCallback(() => {
    const backDelta = idx - stackLength;
    if (idx === -1) navigateTo(rootPath);
    else navigateTo(backDelta);
  }, [idx, navigateTo, rootPath, stackLength]);

  return (
    <div className={style.Container}>
      {back && <Icon size={24} className={style.Back} svg={BackIcon} onClick={navigateToRootPath} />}
      <div className={style.TitleArea}>
        <div className={style.Title}>{title}</div>
        {subTitle && <div className={style.SubTitle}>{subTitle}</div>}
      </div>
      {extra && <div className={style.ActionArea}>{extra}</div>}
    </div>
  );
};

ActionBar.defaultProps = {
  back: true
};

export default memo(ActionBar);
