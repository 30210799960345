export const dataZoomConfig = [
  {
    start: 0,
    end: 100,
    moveHandleSize: 0,
    realtime: false,
    brushSelect: false,
    showDetail: false,
    fillerColor: 'rgba(212, 230, 255, 0.6)',
    handleIcon:
      'path://M8,0.5C8.414213180541992,0.5,8.789213180541992,0.6678932309150696,9.060660362243652,0.939339816570282C9.332106590270996,1.2107864618301392,9.5,1.5857864618301392,9.5,2L9.5,2L9.5,22C9.5,22.414213180541992,9.332106590270996,22.789213180541992,9.060660362243652,23.060659408569336C8.789213180541992,23.332107543945312,8.414213180541992,23.5,8,23.5L8,23.5L2,23.5C1.5857864618301392,23.5,1.2107864618301392,23.332107543945312,0.939339816570282,23.060659408569336C0.6678932309150696,22.789213180541992,0.5,22.414213180541992,0.5,22L0.5,22L0.5,2C0.5,1.5857864618301392,0.6678932309150696,1.2107864618301392,0.939339816570282,0.939339816570282C1.2107864618301392,0.6678932309150696,1.5857864618301392,0.5,2,0.5L2,0.5Z',
    handleStyle: {
      shadowColor: 'rgba(194, 15, 15, 1)'
    },
    dataBackground: {
      areaStyle: {
        opacity: 0
      }
    },
    selectedDataBackground: {
      lineStyle: {
        color: '#90C1FF'
      },
      areaStyle: {
        opacity: 0
      }
    },
    moveHandleStyle: {
      borderCap: 'square'
    }
  }
];

export const tickLineColor = '#333';
export const yAxisLabelColor = '#333';
export const xAxisLabelColor = '#666';
