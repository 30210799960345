import { EChartsOption, BarSeriesOption } from 'echarts';
import { prop, map } from 'lodash/fp';
import React, { useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import {
  formatNilMessage,
  LEVEL_MESSAGE_KEY_MAP_1,
  LEVEL_MESSAGE_KEY_MAP_2,
  LEVEL_MESSAGE_KEY_MAP_3,
  getCompareValueMsgKey
} from '@/views/fund-evaluation-detail/constants';
import { formatPercentage } from '@/util/numberFormatter';
import { useGetStockSelectionAbilityQuery } from '@/infra/api/funds';
import Collapse from '@/components/collapse';
import BaseEcharts from '../../../../../components/baseEcharts';
import SimilarRanking from '../../similarRanking';
import style from '../index.module.less';

export default React.memo(({ fundId }: { fundId: string }) => {
  const { data: stockSelectionAbility } = useGetStockSelectionAbilityQuery(fundId, { skip: !fundId });
  const {
    rank,
    sameTypeCount,
    top10StockWeightAvg,
    positionStockDisperseGrade,
    topStockRetainRatio,
    topStockAdjustGrade,
    selectionAbilitySummary,
    brinsonSelection,
    multiFactorSelection
  } = stockSelectionAbility || {};

  const SerieOptions = useMemo(
    () => [
      {
        name: getMessage('theFund'),
        data: [brinsonSelection?.fundValue ?? 0, multiFactorSelection?.fundValue ?? 0]
      },
      {
        name: getMessage('sameTypeFundAvgValue'),
        data: [brinsonSelection?.sameTypeAvg ?? 0, multiFactorSelection?.sameTypeAvg ?? 0]
      }
    ],
    [
      brinsonSelection?.fundValue,
      brinsonSelection?.sameTypeAvg,
      multiFactorSelection?.fundValue,
      multiFactorSelection?.sameTypeAvg
    ]
  );
  const options = useMemo<EChartsOption>(
    () => ({
      legend: { itemGap: 23 },
      xAxis: {
        type: 'category',
        axisTick: { show: false },
        axisLabel: {
          fontWeight: 400,
          fontSize: 11
        },
        boundaryGap: true,
        data: [getMessage('BRINSON'), getMessage('multifactorAttribution')]
      },
      yAxis: { show: false },
      grid: { top: 40, bottom: 52 },
      series: map(
        (SerieOption: { name: string; data: number[] }): BarSeriesOption => ({
          ...SerieOption,
          type: 'bar',
          barWidth: 40,
          label: {
            show: true,
            position: 'top',
            fontFamily: 'DINAlternate',
            fontSize: 11,
            fontWeight: 500,
            color: '#999'
          }
        })
      )(SerieOptions) as BarSeriesOption[]
    }),
    [SerieOptions]
  );

  return (
    <div className={style.IndividualStockSelectionAbilityWrap}>
      <SimilarRanking rank={rank} count={sameTypeCount} className={style.SimilarRank} />
      <div className={style.TagWrap}>
        <div className={style.Tag}>
          <div className={style.Level}>
            {getMessage('positionStockDisperseGrade', {
              positionStockDisperseGrade: formatNilMessage(LEVEL_MESSAGE_KEY_MAP_1, positionStockDisperseGrade)
            })}
          </div>
          <div className={style.Label}>{getMessage('avgTop10HeavyStockWeight')}</div>
          <div className={style.Value}>{formatPercentage(top10StockWeightAvg)}</div>
        </div>
        <div className={style.Tag}>
          <div className={style.Level}>
            {getMessage('topStockAdjustGrade', {
              topStockAdjustGrade: formatNilMessage(LEVEL_MESSAGE_KEY_MAP_2, topStockAdjustGrade)
            })}
          </div>
          <div className={style.Label}>{getMessage('retentionRateOfFundHeavyHoldings')}</div>
          <div className={style.Value}>{formatPercentage(topStockRetainRatio)}</div>
        </div>
      </div>
      <div className={style.Text}>
        <Collapse
          title={getMessage('selectionAbilityLevel', {
            selectionAbilitySummary: formatNilMessage(LEVEL_MESSAGE_KEY_MAP_3, selectionAbilitySummary)
          })}
        >
          {getMessage('stockSelectionSummaryForMobile', {
            brinsonSelection: formatPercentage(prop('fundValue')(brinsonSelection)),
            brinsonSelectionCmp: formatNilMessage(LEVEL_MESSAGE_KEY_MAP_3, getCompareValueMsgKey(brinsonSelection)),
            multiFactorSelection: formatPercentage(prop('fundValue')(multiFactorSelection)),
            multiFactorSelectionCmp: formatNilMessage(
              LEVEL_MESSAGE_KEY_MAP_3,
              getCompareValueMsgKey(multiFactorSelection)
            )
          })}
        </Collapse>
      </div>
      <div className={style.ChartWrap}>
        <BaseEcharts options={options} height={200} />
      </div>
    </div>
  );
});
