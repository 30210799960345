import { getMessage } from '@betalpha/intl';
import React, { useMemo } from 'react';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { identity, includes, isEmpty, map, prop, set, take } from 'lodash/fp';
import cumulativeReturns from '@betalpha/stdlib/lib/quant/cumulativeReturns';
import { zipWithMinus } from '@betalpha/stdlib/base';

import { ChartProps } from '@/base-components/charts/yieldTrendChart/type';

import YieldTrendChart from '@/base-components/charts/yieldTrendChart';
import { CHART_COLOR } from '@/views/fund-evaluation-detail/constants';
import { fixedNumber, formatPercentage } from '@/util/numberFormatter';
import DetailModal from '@/components/detail-modal';
import { tipModalMessage } from '@/views/fund-compass-home/contants';
import EmptyContent from '@/components/emptyContent';
import BaseEcharts from '@/components/baseEcharts';
import { useGetIndustryModelQuery } from '@/infra/api/etf-funds';

import style from '../index.module.less';

export default React.memo(({ id, summary }: { id: string; summary: string }) => {
  const { data } = useGetIndustryModelQuery();

  const { etfModelIndustry, modelRecommendIndustryReturnComparison } = data || {};

  const { dates, notRecommendIndustryReturn, recommendIndustryReturn } = modelRecommendIndustryReturnComparison || {};

  const modelIndustry = prop(id)(etfModelIndustry);

  const names = useMemo(() => map('industryName')(modelIndustry), [modelIndustry]);
  const industryValues = useMemo(() => map('industryValue')(modelIndustry), [modelIndustry]);

  const options = useMemo<EChartsOption>(
    () => ({
      grid: { top: 23, left: 25, bottom: 60 },
      legend: { show: false },
      yAxis: {
        axisTick: { show: false },
        axisLine: { show: false },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#D2D5D9',
            type: 'dotted'
          }
        },
        axisLabel: {
          fontSize: 10,
          fontWeight: 400,
          formatter: identity
        }
      },
      xAxis: {
        type: 'category',
        data: names,
        axisLabel: {
          padding: [15, 0, 0, 0],
          fontSize: 10,
          fontWeight: 400,
          overflow: 'break',
          hideOverlap: false,
          interval: 0,
          color: (val?: any) => {
            if (includes(val)(take(3)(names))) {
              return '#222222';
            }
            return '#666666';
          }
        }
      },
      series: {
        type: 'bar',
        barWidth: 25,
        silent: true,
        itemStyle: {
          color: (param: any) => {
            if (includes(param?.value)(take(3)(industryValues))) {
              return '#CFB47F';
            }
            return '#1A396A';
          }
        },
        label: {
          show: true,
          formatter: (param: any) => {
            return fixedNumber(param?.value);
          }
          // position: 'top'
        },
        data: map((v: number) => ({
          value: v,
          label: {
            position: v > 0 ? 'top' : 'bottom'
          }
        }))(industryValues || []) as BarSeriesOption['data']
      }
    }),
    [names, industryValues]
  );
  const series = useMemo(() => {
    const recommendIndustryMixReturn = cumulativeReturns(set(0, 0)(recommendIndustryReturn as any), true);
    const notRecommendIndustryCombinationReturn = cumulativeReturns(set(0, 0)(notRecommendIndustryReturn as any), true);

    return [
      {
        name: getMessage('recommendIndustryMix'),
        color: CHART_COLOR[0],
        dates,
        returns: recommendIndustryMixReturn
      },
      {
        name: getMessage('notRecommendIndustryCombination'),
        color: CHART_COLOR[1],
        dates,
        returns: notRecommendIndustryCombinationReturn
      },
      {
        name: getMessage('twoSetsOfHedgingGains'),
        color: CHART_COLOR[2],
        dates,
        returns: zipWithMinus(recommendIndustryMixReturn, notRecommendIndustryCombinationReturn)
      }
    ];
  }, [recommendIndustryReturn, notRecommendIndustryReturn, dates]);
  const chartOptions = useMemo<Partial<ChartProps>>(
    () => ({
      legendOptions: {
        left: 4,
        bottom: 'auto',
        top: 20,
        itemGap: 12,
        padding: 0,
        textStyle: { color: '#666666', fontSize: 12 },
        itemWidth: 12,
        icon: 'roundRect'
      },
      gridOptions: { top: 54, bottom: 10, right: 26 },
      xAxisOptions: {
        boundaryGap: false,
        axisTick: { show: false },
        axisLine: { onZero: true }
      },
      yAxisOptions: {
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'dotted'
          }
        },
        axisLabel: {
          color: '#666666',
          fontSize: 10,
          fontWeight: 400,
          formatter: (v: number) => formatPercentage(v, { digit: 0 })
        }
      }
    }),
    []
  );
  return (
    <div key={id}>
      <div className={style.ModelSummary}>{summary}</div>
      <div className={style.CurrentRank}>
        <span className={style.CurrentRankName}>{getMessage('currentRank')}</span>
        <span className={style.RankName}>
          (<span className={style.RankNameMax}>{getMessage('topNumber', { number: 3 })}</span>/
          <span className={style.RankNameMin}>{getMessage('lastNumber', { number: 3 })}</span>)
        </span>
        <DetailModal
          title={
            <div>
              {getMessage('topNumber', { number: 3 })}
              {getMessage('lastNumber', { number: 3 })}
            </div>
          }
          content={
            <>
              <div className={style.topLastNumberTip}>{prop('topNumber')(tipModalMessage)}</div>
              <div className={style.topLastNumberTip}>{prop('lastNumber')(tipModalMessage)}</div>
            </>
          }
          triggerClassName={style.Question}
        />
      </div>
      {isEmpty(names) ? (
        <EmptyContent className={style.EmptyContent}>
          <strong>{getMessage('noData')}</strong>
        </EmptyContent>
      ) : (
        <BaseEcharts options={options} height={300} />
      )}
      <div className={style.YieldCompare}>{getMessage('industryPortfolioYieldCompare')}</div>
      <div className={style.ModelSummary}>{getMessage('industryPortfolioYieldCompareTip')}</div>
      {isEmpty(dates) ? (
        <EmptyContent className={style.EmptyContent}>
          <strong>{getMessage('noData')}</strong>
        </EmptyContent>
      ) : (
        <YieldTrendChart
          dates={dates || []}
          series={series}
          showDataZoom={false}
          height={220}
          onZero
          {...chartOptions}
        />
      )}
    </div>
  );
});
