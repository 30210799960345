import {
  isArray,
  map,
  isNumber,
  filter,
  size,
  flow,
  sum,
  divide,
  sumBy,
  subtract,
  multiply,
  forEach,
  isNaN
} from 'lodash/fp';
import Big from 'big.js';
import { forEachIndexed, fastNth } from '@betalpha/stdlib/base';
import type { Big as BigType } from 'big.js';

type RealNumber = number | BigType;

export const isRealNumber = (number: RealNumber) => (isNumber(number) && !isNaN(number)) || number instanceof Big;

export const div = (number: number) => (target: number) => divide(target)(number);

export const minus = (number: number) => (target: number) => subtract(target)(number);

export const pow = (number: number) => (target: number) => target ? target ** (isNumber(number) ? number : 1) : 0;

export const sqrt = (target: number) => (target ? Math.sqrt(target) : 0);

export const times = (number: number) => (target: number) => multiply(target)(number);

// 小数精确减法
export const decimalSubtract = (minuend: number, subtrahend: number) => {
  const digit1 = minuend.toString().split('.')[1]?.length ?? 0;
  const digit2 = subtrahend.toString().split('.')[1]?.length ?? 0;
  const targetDigit = digit1 > digit2 ? digit1 : digit2;
  return (minuend * 10 ** targetDigit - subtrahend * 10 ** targetDigit) / 10 ** targetDigit;
};

// 平均值算法
export const toAverage = (numbers: (number | undefined | null)[]) => {
  const filterNumbers = filter(isNumber)(numbers);
  return size(filterNumbers) > 0 ? flow(sum, div(size(filterNumbers)))(filterNumbers) : 0;
};

// 标准差算法
export const toStandardDeviation = (numbers: number[]) => {
  if (size(numbers) === 0) {
    return 0;
  }
  const average = toAverage(numbers);
  const sd = flow(filter(isNumber), sumBy(flow(minus(average), pow(2))), div(size(numbers) - 1), sqrt)(numbers);
  return Math.abs(sd) < Number.EPSILON ? 0 : sd;
};

// 协方差算法
export const getCovariance = (dailyReturns: number[], benchmarkReturns: number[]) => {
  let covariance = 0;
  const dailyReturnsAvg = toAverage(dailyReturns);
  const benchmarkReturnsAvg = toAverage(benchmarkReturns);
  forEachIndexed((dailyReturn: number, index: number) => {
    covariance +=
      (dailyReturn - dailyReturnsAvg) * ((fastNth(index)(benchmarkReturns) as number) - benchmarkReturnsAvg);
  })(dailyReturns);
  return covariance / (size(dailyReturns) - 1);
};

// 方差算法
export const getSampleVariance = (dailyReturns: number[]) => {
  const returnsAvg = toAverage(dailyReturns);
  let variance = 0;
  forEach((dailyReturn: number) => {
    variance += (dailyReturn - returnsAvg) ** 2;
  })(dailyReturns);
  return variance / (size(dailyReturns) - 1);
};

export const approachEqual = (number: number, benchmark: number, precision = 7, waterMark = 1) =>
  Math.abs(number - benchmark) * 10 ** precision < waterMark;

const toBig = (number: RealNumber) => (isRealNumber(number) ? new Big(number) : new Big(0));

export const bigNumber = {
  convert: (target: number | number[]) => (isArray(target) ? map(toBig)(target) : toBig(target)),
  plus: (number: number) => (target: BigType) => target ? target.plus(isRealNumber(number) ? number : 0) : target,
  minus: (number: number) => (target: BigType) => target ? target.minus(isRealNumber(number) ? number : 0) : target,
  times: (number: number) => (target: BigType) => target ? target.times(isRealNumber(number) ? number : 1) : target,
  sqrt: (target: BigType) => (target ? target.sqrt() : 0),
  pow: (number: number) => (target: BigType) => target ? target.pow(isRealNumber(number) ? number : 1) : target,
  multiply: (number: number) => (target: BigType) => target ? target.mul(isRealNumber(number) ? number : 0) : target,
  toNumber: (target: BigType) => {
    if (target instanceof Big) {
      return Number(target);
    }

    return target;
  },
  div: (number: number) => (target: BigType) =>
    target ? target.div(isRealNumber(number) && !new Big(number).eq(0) ? number : 1) : 0
};
