import React, { useCallback, useState } from 'react';
import { Popup } from 'antd-mobile';
import { useBoolean } from 'ahooks';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import { message } from 'antd';
import { ReactComponent as LinkIcon } from '@/assets/link.svg';
import { ReactComponent as PdfIcon } from '@/assets/pdf.svg';
import { ReactComponent as ShareIcon } from '@/assets/icon-share.svg';
import Icon from '@/components/icon';
import CopyLink from '@/util/copy-link';
import { CustomSpin } from '../custom-spin';
import style from './index.module.less';

// const reportAddress = 'http://121.46.249.135:18695';
const reportAddress =
  process.env.REACT_APP_ENV === 'test' ? 'http://172.31.40.99:30554' : 'https://api4.betalpha.com:3005';

const getPDFDownloadLink = ({
  fundId,
  refer,
  PDFType
}: {
  fundId: string;
  refer: string;
  PDFType: ShareProps['PDFType'];
}) => {
  switch (PDFType) {
    case 'FUND_EVALUATION':
      return `${reportAddress}/fund-report/create/fund-evaluate?&referer=${encodeURIComponent(refer)}&code=${fundId}`;
    case 'BOND_FUND':
      return `${reportAddress}/fund-report/create/bond-fund-evaluate?&referer=${encodeURIComponent(
        refer
      )}&code=${fundId}`;
    case 'ETF_COMPASS_HOME':
      return `${reportAddress}/fund-report/create/etf-compass-whole?referer=${encodeURIComponent(refer)}`;
    case 'ETF_COMPASS_DETAIL':
      return `${reportAddress}/fund-report/create/etf-compass?referer=${encodeURIComponent(refer)}&code=${fundId}`;
    default:
      return '';
  }
};

interface ShareProps {
  PDFType: 'FUND_EVALUATION' | 'ETF_COMPASS_HOME' | 'ETF_COMPASS_DETAIL' | 'BOND_FUND';
}
const Share = ({ PDFType }: ShareProps) => {
  const [popUpOpen, { setTrue, setFalse }] = useBoolean(false);
  const onCopyLink = useCallback(() => {
    CopyLink(window.location.href, getMessage('copySuccess'));
    setFalse();
  }, [setFalse]);

  const { fundId } = useParams();

  const [isExportingPdf, setIsExportingPdf] = useState(false);
  const onDownloadPDF = useCallback(async () => {
    setIsExportingPdf(true);
    const link = getPDFDownloadLink({
      fundId: fundId ?? '',
      refer: process.env.NODE_ENV === 'development' ? PROXY_ENV : window.location.origin, // refer 决定从哪个环境导出pdf
      PDFType
    });
    const { default: jsonp } = await import('jsonp');
    jsonp(link, (err, downloadLink) => {
      setFalse();
      setIsExportingPdf(false);
      if (err) message.error(getMessage('systemError'));
      else if (downloadLink) {
        window.location.href = downloadLink;
      }
    });
  }, [PDFType, fundId, setFalse]);

  return (
    <>
      <Icon size={20} svg={ShareIcon} onClick={setTrue} />
      <Popup className={style.SharePopup} visible={popUpOpen} onMaskClick={setFalse} position="bottom">
        <CustomSpin loading={isExportingPdf} />
        <div className={style.ShareLink}>
          <div className={style.ShareIconCtx} onClick={onCopyLink}>
            <Icon size={20} svg={LinkIcon} className={style.ShareIcon} />
            <span>{getMessage('copyLink')}</span>
          </div>
          <div className={style.ShareIconCtx} onClick={onDownloadPDF}>
            <Icon size={20} svg={PdfIcon} className={style.ShareIcon} />
            <span>{getMessage('exportPdfFile')}</span>
          </div>
        </div>
        <footer onClick={setFalse}>{getMessage('cancel')}</footer>
      </Popup>
    </>
  );
};
export default React.memo(Share);
