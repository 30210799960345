import { fastProp, mapIndexed, zipWithMinus } from '@betalpha/stdlib/lib/base';
import cumulativeReturns from '@betalpha/stdlib/lib/quant/cumulativeReturns';
import { flow, keys, map, set, compact, isNil, merge, prop, size, isArray, omit } from 'lodash/fp';
import { getMessage } from '@betalpha/intl';
import { useMemo } from 'react';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { normalize } from '@betalpha/stdlib/base';
import { formatPercentage, formatHundredMillion } from '@/util/numberFormatter';
import { ChartProps } from '@/base-components/charts/yieldTrendChart/type';
import { EvaluationReportInfoBody, FactorPerformanceParam } from '@/interface/fundDetail';
import starImg from '@/assets/fundEvaluationReport/star.svg';
import questionImg from '@/assets/fundEvaluationReport/question.svg';
import { toAverage } from '@/util/math';
import { getFactorsFormatterName, formatter } from '@/constant/factorFormatter';
import {
  useGetComprehensivePerformanceQuery,
  useGetEvaluationReportAbilitySummaryQuery,
  useGetIndustryAbilityEvaluationQuery,
  useGetMarketCapabilityDetailQuery,
  useGetStockSelectionAbilityQuery,
  useGetTurnoverImprovementAbilityQuery
} from '@/infra/api/funds';
import Rate from '../../components/rate';
import { CHART_COLOR, RatePerformanceMap, dispersionDegreeMap, getCompareValueMsgKey } from './constants';

const starIcon = new Image();
starIcon.src = starImg;
const questionIcon = new Image();
questionIcon.src = questionImg;

export const useGetLineChartData = (fundEvaluationReportInfo: EvaluationReportInfoBody | undefined) => {
  const { fundName } = fundEvaluationReportInfo || {};
  const returns = fastProp<EvaluationReportInfoBody['returns']>('returns')(fundEvaluationReportInfo || {});
  const { fundReturns: fundDailyReturns, dates, benchmarkReturns: benchmarkDailyReturns } = returns || {};

  const series = useMemo(() => {
    const fundReturns = cumulativeReturns(set(0, 0)(fundDailyReturns as any), true);
    const benchmarkReturns = cumulativeReturns(set(0, 0)(benchmarkDailyReturns as any), true);
    return [
      {
        name: getMessage('theFund'),
        color: CHART_COLOR[0],
        dates,
        returns: fundReturns
      },
      {
        name: getMessage('csIndex800'),
        color: CHART_COLOR[1],
        dates,
        returns: benchmarkReturns
      },
      {
        name: getMessage('returnExcess'),
        color: CHART_COLOR[2],
        dates,
        returns: zipWithMinus(fundReturns, benchmarkReturns)
      }
    ];
  }, [benchmarkDailyReturns, dates, fundDailyReturns]);

  const chartOptions = useMemo<Partial<ChartProps>>(
    () => ({
      legendOptions: {
        left: 0,
        bottom: 'auto',
        top: 20,
        itemGap: 10,
        padding: 0,
        textStyle: { color: '#666666', fontSize: 12 }
      },
      gridOptions: { top: 54, bottom: 0, right: 0 },
      xAxisOptions: {
        boundaryGap: false,
        axisLabel: {
          color: '#666666',
          hideOverlap: false,
          interval: Math.floor(size(dates) / 2),
          fontFamily: 'DINAlternate',
          fontSize: 10
        },
        axisTick: { show: false },
        axisLine: { onZero: true }
      },
      yAxisOptions: {
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'dotted'
          }
        },
        axisLabel: {
          color: '#666666',
          fontFamily: 'DINAlternate',
          fontSize: 11,
          formatter: (value: number) => formatPercentage(value, { digit: 0 })
        }
      }
    }),
    [dates]
  );

  return { dates: dates || [], chartOptions, fundName, series };
};

export type BaseInfoTableDataType = {
  key: string;
  label: any;
  value: any;
};
export const useGetBasicInfoTableDataSource = (fundEvaluationReportInfo: EvaluationReportInfoBody | undefined) => {
  return useMemo<BaseInfoTableDataType[]>(
    () => [
      {
        key: 'fundEvaluationLevel',
        label: 'fundEvaluationLevel',
        value: !isNil(prop('fundEvaluation')(fundEvaluationReportInfo)) ? (
          <Rate value={prop('fundEvaluation')(fundEvaluationReportInfo)} />
        ) : (
          '--'
        )
      },
      {
        key: 'evaluationFundType',
        label: 'evaluationFundType',
        value: prop('fundTypeName')(fundEvaluationReportInfo) && `${prop('fundTypeName')(fundEvaluationReportInfo)}型`
      },
      {
        key: 'currentScale',
        label: 'currentScale',
        value:
          prop('fundScale')(fundEvaluationReportInfo) &&
          formatHundredMillion(prop('fundScale')(fundEvaluationReportInfo))
      },
      {
        key: 'fundManager',
        label: 'fundManager',
        value: prop('fundManager')(fundEvaluationReportInfo)
      },
      {
        key: 'fundCompany',
        label: 'fundCompany',
        value: prop('fundCompany')(fundEvaluationReportInfo)
      },
      {
        key: 'foundDate',
        label: 'foundDate',
        value: prop('foundDate')(fundEvaluationReportInfo)
      }
    ],
    [fundEvaluationReportInfo]
  );
};

export const useGetFactorEvaluationOptions = (gaugeData: Record<string, any>[], width: number) => {
  const pixelRatio = useMemo(() => width / 260, [width]);
  return useMemo<EChartsOption>(
    () => ({
      graphic: [
        {
          type: 'circle',
          left: 'center',
          top: 'middle',
          shape: {
            r: 75 * 0.5 * pixelRatio
          },
          style: {
            fill: '#E6ECF5'
          }
        }
      ],
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      xAxis: { show: false },
      series: [
        {
          type: 'gauge',
          anchor: {
            show: true,
            showAbove: true,
            size: 20 * pixelRatio,
            itemStyle: {
              color: '#A7B5C7'
            }
          },
          pointer: {
            icon: 'path://M0 1000 L250 0 L500 1000 Z',
            width: 10 * pixelRatio,
            length: '70%',
            offsetCenter: [0, '8%'],
            itemStyle: {
              color: '#aebbc4'
            }
          },
          min: 0,
          max: 100,
          progress: {
            show: true,
            overlap: true,
            roundCap: false,
            width: 10 * pixelRatio
          },
          axisTick: {
            show: true,
            splitNumber: 8,
            length: 8,
            distance: 6,
            lineStyle: {
              width: 2,
              color: {
                type: 'linear',
                x: 50,
                y: 120,
                x2: 200,
                y2: 120,
                global: true,
                colorStops: [
                  {
                    offset: 0,
                    color: '#C1DFCF'
                  },
                  {
                    offset: 0.33,
                    color: '#DDC6A8'
                  },
                  {
                    offset: 0.66,
                    color: '#DDC6A8'
                  },
                  {
                    offset: 1,
                    color: '#D9AEA8'
                  }
                ]
              }
            }
          },
          axisLabel: {
            show: false
          },
          axisLine: {
            roundCap: false
          },
          splitLine: {
            show: false
          },
          data: gaugeData,
          title: {
            fontSize: 18 * pixelRatio
          }
        }
      ]
    }),
    [gaugeData, pixelRatio]
  );
};

export const useGetDefaultRadarOptions = (options: EChartsOption, starSize = 10) =>
  useMemo<EChartsOption>(() => {
    const mergedSeries = isArray(options.series)
      ? map(
          merge({
            type: 'radar',
            lineStyle: {
              opacity: 0
            },
            areaStyle: {
              opacity: 0.85
            },
            symbol: 'none'
          })
        )(options.series)
      : options.series;
    return merge<EChartsOption>({
      legend: {
        right: 'auto',
        left: 0,
        top: 10,
        itemGap: 20,
        itemWidth: 15,
        itemHeight: 10,
        textStyle: {
          fontSize: 12
        }
      },
      xAxis: { show: false },
      color: CHART_COLOR,
      series: mergedSeries,
      radar: {
        radius: 80,
        center: ['50%', '64%'],
        splitArea: {
          show: false
        },
        axisLine: {
          show: false
        },
        splitLine: {
          lineStyle: {
            color: '#D3D4D4'
          }
        },
        axisName: {
          color: '#666666',
          fontSize: 13,
          lineHeight: 13,
          fontWeight: 400,
          rich: {
            star: {
              lineHeight: 20,
              fontSize: starSize,
              align: 'center',
              verticalAlign: 'bottom',
              backgroundColor: {
                image: starIcon
              }
            },
            question: {
              fontSize: 14,
              align: 'center',
              verticalAlign: 'bottom',
              backgroundColor: {
                image: questionIcon
              }
            }
          }
        }
      }
    })(omit('series')(options));
  }, [options, starSize]);

const factorNameMap = {
  avg_return_3y_ann: 'annualizedIncome',
  csi800_excess_rtn_win_ratio_3y: 'marketSuccessRate',
  my_calmar_ratio_3y_2: 'karmaRatio',
  sharpe_ratio_3y_ann: 'sharpeRatio'
};
export const useComprehenPerformance = (fundCode: string) => {
  const { data: comPerformance } = useGetComprehensivePerformanceQuery(fundCode, { skip: !fundCode });
  const { factorPerformance, summary } = comPerformance || { factorPerformance: [] };
  const factorPerformanceMap = useMemo(() => normalize(factorPerformance as any, 'factorCode'), [factorPerformance]);
  const dataSource = useMemo(
    () =>
      map((id: string) => {
        const key = getFactorsFormatterName(id);
        const formatterFunc = formatter[key] || formatPercentage;
        const item = fastProp<FactorPerformanceParam>(id)(factorPerformanceMap);
        return {
          name: flow(fastProp(id), getMessage)(factorNameMap),
          fundPerformance: formatterFunc(item?.fundFactorValue),
          marketPerformance: formatterFunc(item?.benchmarkFactorValue),
          sameTypeFactorRank: item?.sameTypeFactorRank
        };
      })(keys(factorNameMap)),
    [factorPerformanceMap]
  );
  return { dataSource, summary };
};

export const useGetBarChartAndSummaryData = (fundCode: string) => {
  const { data: industryAbilityEvaluation } = useGetIndustryAbilityEvaluationQuery(fundCode, { skip: !fundCode });
  const {
    industryAllocationRating,
    sameTypeRank,
    top3IndustryInEquity,
    top3IndustryWeightRating,
    industry2Name,
    industryAllocationCompareWithSameType,
    brinsonAllocationContribute,
    factorModelAllocationContribute
  } = industryAbilityEvaluation ?? { top3IndustryWeightRating: '', industryAllocationCompareWithSameType: '' };

  const chartDatas = useMemo(
    () => [
      {
        name: getMessage('theFund'),
        data: [brinsonAllocationContribute?.fundContribute, factorModelAllocationContribute?.fundContribute]
      },
      {
        name: getMessage('sameTypeFundAvgValue'),
        data: [brinsonAllocationContribute?.sameTypeContribute, factorModelAllocationContribute?.sameTypeContribute]
      }
    ],
    [
      brinsonAllocationContribute?.fundContribute,
      brinsonAllocationContribute?.sameTypeContribute,
      factorModelAllocationContribute?.fundContribute,
      factorModelAllocationContribute?.sameTypeContribute
    ]
  );

  const options = useMemo<EChartsOption>(
    () => ({
      legend: {
        itemGap: 23,
        data: [getMessage('theFund'), getMessage('sameTypeFundAvgValue')]
      },
      xAxis: {
        data: [getMessage('BRINSON'), getMessage('multifactorAttribution')],
        type: 'category',
        axisTick: { show: false },
        boundaryGap: true,
        axisLabel: {
          fontWeight: 400,
          fontSize: 11,
          margin: 20
        }
      },
      grid: { top: 40 },
      yAxis: { show: false },
      series: map((item: any) => ({
        name: item.name,
        type: 'bar',
        barWidth: 40,
        barGap: '5%',
        label: {
          show: true,
          fontFamily: 'DINAlternate',
          fontSize: 11,
          fontWeight: 500,
          color: '#999',
          formatter: (params: any) => {
            const value = prop('value')(params);
            return formatPercentage(value);
          }
        },
        data: map((v: number) => ({
          value: v,
          label: {
            position: v >= 0 ? 'top' : 'bottom'
          }
        }))(item.data)
      }))(chartDatas) as BarSeriesOption[]
    }),
    [chartDatas]
  );

  return {
    industryAllocationRating,
    sameTypeRank,
    top3IndustryInEquity,
    top3IndustryWeightRating,
    industry2Name,
    industryAllocationCompareWithSameType,
    brinsonAllocationContribute,
    factorModelAllocationContribute,
    options
  };
};

export const useGetAbilitySummary = (fundCode: string) => {
  const { data: fundEvaluationReportAbilitySummary } = useGetEvaluationReportAbilitySummaryQuery(fundCode, {
    skip: !fundCode
  });

  const { factorRatings: factorRatingsOrigin, totalRating } = fundEvaluationReportAbilitySummary || {};
  return {
    factorRatingsMap: normalize(factorRatingsOrigin as any, 'factorCode'),
    totalRating
  };
};

type MapperKeyType = 1 | 3 | 'HIGH' | 'LOW' | 'HIGHER' | 'LOWER';
export const useGetFundTags = (fundId: string) => {
  const { summary } = useComprehenPerformance(fundId);
  const { performanceRating, riskLevel, holdingExperience } = summary || {};

  const { top3IndustryWeightRating, industryAllocationCompareWithSameType } = useGetBarChartAndSummaryData(fundId);

  const { data: stockSelectionAbility } = useGetStockSelectionAbilityQuery(fundId, { skip: !fundId });
  const { positionStockDisperseGrade, topStockAdjustGrade, brinsonSelection, multiFactorSelection } =
    stockSelectionAbility ?? {};

  const { data: turnoverImprovementAbility } = useGetTurnoverImprovementAbilityQuery(fundId, { skip: !fundId });
  const { extendNewStocksAbility } = turnoverImprovementAbility || {};

  const { desireGrade, newStockQuality } = extendNewStocksAbility || {};

  const { data: marketCapabilityDetail } = useGetMarketCapabilityDetailQuery(fundId, { skip: !fundId });
  const { marketCapabilityLevel, offensiveAbilityLevel, defensiveAbilityLevel } = marketCapabilityDetail || {};

  const tagData = useMemo(
    () => ({
      performanceRating,
      riskLevel,
      holdingExperience,
      top3IndustryWeightRating,
      industryAllocationCompareWithSameType,
      positionStockDisperseGrade,
      topStockAdjustGrade,
      stockSelectionAbility: getCompareValueMsgKey({
        fundValue: toAverage([prop('fundValue')(brinsonSelection), prop('fundValue')(multiFactorSelection)]),
        sameTypeAvg: toAverage([prop('sameTypeAvg')(brinsonSelection), prop('sameTypeAvg')(multiFactorSelection)])
      }),
      desireGrade,
      newStockQuality,
      marketCapabilityLevel,
      offensiveAndDefensiveAbilityLevel: offensiveAbilityLevel === defensiveAbilityLevel ? offensiveAbilityLevel : null
    }),
    [
      brinsonSelection,
      defensiveAbilityLevel,
      desireGrade,
      holdingExperience,
      industryAllocationCompareWithSameType,
      marketCapabilityLevel,
      multiFactorSelection,
      newStockQuality,
      offensiveAbilityLevel,
      performanceRating,
      positionStockDisperseGrade,
      riskLevel,
      top3IndustryWeightRating,
      topStockAdjustGrade
    ]
  );

  const mapper = useMemo(
    () => ({
      ...RatePerformanceMap,
      ...dispersionDegreeMap
    }),
    []
  );

  return useMemo(() => {
    const excellentFundTags: string[] = [];
    const poorFundTags: string[] = [];

    mapIndexed((item: MapperKeyType, key: string) => {
      if (item === 1 || item === 'HIGH' || item === 'HIGHER')
        excellentFundTags.push(getMessage(`${key}${mapper[item]}`));
      else if (item === 3 || item === 'LOW' || item === 'LOWER') poorFundTags.push(getMessage(`${key}${mapper[item]}`));
    })(tagData);

    return {
      excellentFundTags: compact(excellentFundTags.join(',').split(',')),
      poorFundTags: compact(poorFundTags.join(',').split(','))
    };
  }, [mapper, tagData]);
};
