import { Toast } from 'antd-mobile';

export default function CopyLink(ctx: string, message: string) {
  // navigator clipboard 需要https等安全上下文
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard 向剪贴板写文本
    navigator.clipboard.writeText(ctx).then(() => {
      Toast.show({
        icon: 'success',
        content: message
      });
    });
  } else {
    // 创建text area
    const textArea = document.createElement('textarea');
    textArea.value = ctx;
    textArea.style.position = 'absolute';
    textArea.style.opacity = '0';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    // 执行复制命令并移除文本框
    document.execCommand('copy');
    Toast.show({
      icon: 'success',
      content: message
    });
    textArea.remove();
  }
}
