import { routerReducer } from '@betalpha/router';
import { combineReducers } from '@betalpha/redux-toolkit';
import globalReducer from '../api/global-slice';
import baseApi from '../api/base-api';
import etfFundHome from './etfFundSlice';

export default combineReducers({
  global: globalReducer,
  router: routerReducer,
  baseApi: baseApi.reducer,
  etfFundHome
});
