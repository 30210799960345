import { getMessage } from '@betalpha/intl';
import React, { ReactNode, useMemo } from 'react';
import { useParams } from '@betalpha/router';
import { drop, isEmpty, map, orderBy, take } from 'lodash/fp';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';
import { mapIndexed } from '@betalpha/stdlib/base';
import CardLayout from '@/components/cardLayout';
import ColorBlockTitle from '@/components/color-block-title';
import { useGetETFTopStockPositionQuery } from '@/infra/api/etf-funds';
import { ETFTopStockPositionBodyItem } from '@/interface/etfFund';
import EmptyContent from '@/components/emptyContent';
import EvaluationDetailTrigger from '@/components/evaluation-detail-trigger';
import style from './index.module.less';

const HeavyHoldings = () => {
  const { fundId } = useParams();
  const { data, isFetching } = useGetETFTopStockPositionQuery(fundId ?? '', { skip: !fundId });

  const tableData = useMemo(() => {
    const sortedData = orderBy('stockWeight')('desc')(data);
    const restData = drop(5)(sortedData);
    const topFiveData = take(5)(sortedData);
    return [topFiveData, restData];
  }, [data]);

  return (
    <CardLayout title={<ColorBlockTitle>{getMessage('heavyHoldings')}</ColorBlockTitle>} loading={isFetching}>
      <div className={style.HeavyHoldingsWrap}>
        {!isEmpty(data) ? (
          <>
            <div className={style.TableHeader}>
              <div>{getMessage('stockName')}</div>
              <div>{getMessage('stockPropotion')}</div>
            </div>
            <div className={style.Divider} />
            <div className={style.TableContent}>
              <EvaluationDetailTrigger initalizeHeight={145}>
                {
                  mapIndexed(
                    map((item: ETFTopStockPositionBodyItem) => (
                      <div key={item.stockCode} className={style.DataItem}>
                        <div>{item.stockName}</div>
                        <div>{percentage(item.stockWeight)}</div>
                      </div>
                    ))
                  )(tableData) as ReactNode
                }
              </EvaluationDetailTrigger>
            </div>
          </>
        ) : (
          <EmptyContent message={getMessage('noData')} />
        )}
      </div>
    </CardLayout>
  );
};

export default React.memo(HeavyHoldings);
