import React, { ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { getMessage } from '@betalpha/intl';
import { map } from 'lodash/fp';
import { ETFSimilarETFCompareBody } from '@/interface/etfFund';
import style from './index.module.less';

export type CompareTableDataType = {
  type: string;
  curerntEtfFund: ReactNode;
  sameCategoryEtfFund: ReactNode;
};

type CompareTableColumnType = {
  title: string;
  key: string;
};

interface CompareTableProps {
  className?: string;
  dataSource: CompareTableDataType[];
  compareSummary?: ETFSimilarETFCompareBody['compareSummary'];
}
export default React.memo<CompareTableProps>(({ className, dataSource, compareSummary }) => {
  const defaultColumns = useMemo<CompareTableColumnType[]>(
    () => [
      {
        title: getMessage('type'),
        key: 'type'
      },
      {
        title: getMessage('curerntEtfFund'),
        key: 'curerntEtfFund'
      },
      {
        title:
          compareSummary && compareSummary === 'COMPARE_ALL'
            ? getMessage('allMarketEtf')
            : getMessage('sameCategoryEtfFund'),
        key: 'sameCategoryEtfFund'
      }
    ],
    [compareSummary]
  );

  return (
    <div className={cn(style.TrackAbilityTable, className)}>
      {map((item: CompareTableColumnType) => (
        <div className={cn(style.TableHead, item.key === 'type' && style.Type)} key={item.key}>
          {item.title}
        </div>
      ))(defaultColumns)}
      {map((item: CompareTableDataType) => (
        <React.Fragment key={item.type}>
          <div className={style.Type}>{item.type}</div>
          <div>{item.curerntEtfFund}</div>
          <div>{item.sameCategoryEtfFund}</div>
        </React.Fragment>
      ))(dataSource)}
    </div>
  );
});
