/* eslint-disable no-underscore-dangle */
import {
  first,
  last,
  filter,
  findIndex,
  findLastIndex,
  slice,
  map,
  isEmpty,
  concat,
  set,
  flow,
  min,
  max,
  size,
  isArray,
  find,
  maxBy,
  update
} from 'lodash/fp';
import { TradingDates, getPreviousTradingDate } from '../tradingDate';
import {
  DatePeriodName,
  getPeriodDateByCommonTime,
  getDatesByDatePeriod,
  getDatesByPeriodDate,
  PeriodDate
} from '../datePeriod';
import { DateReturn, DateReturnAndRangeDates, DateData, DateDataItem } from './type';

/**
 *
 * @param targetDates 目标日期区间
 * @param dateReturn
 *    @param dates 使用区间
 *    @param dailyReturns 使用区间日收益
 * @returns 获取符合目标日期区间的区间范围内的日期和日收益
 */
export function getDateReturnFitTargetDates(targetDates: TradingDates, dateReturn: DateReturn): DateReturn {
  if (!dateReturn.dates || !targetDates) {
    return {
      ...(dateReturn || {}),
      dates: [],
      dailyReturns: []
    };
  }
  // 目标时间和使用区间没有交集
  if ((first(targetDates) as string) > (last(dateReturn.dates) as string)) {
    return {
      ...(dateReturn || {}),
      dates: [],
      dailyReturns: []
    };
  }
  const startDate =
    (first(dateReturn.dates) as string) < (first(targetDates) as string) ? first(targetDates) : first(dateReturn.dates);
  const endDate =
    (last(dateReturn.dates) as string) > (last(targetDates) as string) ? last(targetDates) : last(dateReturn.dates);

  const startIndex = findIndex<string>((date) => date >= (startDate as string))(dateReturn.dates);
  const endIndex = findLastIndex<string>((date) => date <= (endDate as string))(dateReturn.dates);
  return {
    ...(dateReturn || {}),
    dates: slice(startIndex, endIndex + 1)(dateReturn.dates),
    dailyReturns: slice(startIndex, endIndex + 1)(dateReturn.dailyReturns)
  };
}

function increasePreviousOneDayForDateReturn(dateReturn: DateReturn, tradingDates: TradingDates): DateReturn {
  const { dates, dailyReturns } = dateReturn || {};
  if ((first(dates) as string) === (first(tradingDates) as string)) {
    return {
      ...(dateReturn || {}),
      dailyReturns: set(0, 0)(dailyReturns || [])
    };
  }
  if ((first(dates) as string) > (first(tradingDates) as string)) {
    return {
      ...(dateReturn || {}),
      dates: concat(getPreviousTradingDate(first(dates) as string, tradingDates))(dates || []),
      dailyReturns: concat(0)(dailyReturns || [])
    };
  }
  return dateReturn;
}

function increasePreviousOneDayForDates(dates: TradingDates, tradingDates: TradingDates): TradingDates {
  if ((first(dates) as string) === (first(tradingDates) as string)) {
    return dates;
  }
  if ((first(dates) as string) > (first(tradingDates) as string)) {
    return concat(getPreviousTradingDate(first(dates) as string, tradingDates))(dates || []);
  }
  return dates;
}

export function getDateReturnByDatePeriod(dateReturn: DateReturn, name: DatePeriodName): DateReturn {
  const dates = getDatesByDatePeriod(name, dateReturn?.dates || []);
  return getDateReturnFitTargetDates(dates, dateReturn);
}

function getMinDate(datesArray: TradingDates[]) {
  return flow(map(first), min)(datesArray) as string;
}

function getMaxDate(datesArray: TradingDates[]) {
  return flow(map(last), max)(datesArray) as string;
}

/**
 *
 * @param dateReturns
 * @param tradingDates
 */
function getUnionDates(datesArray: TradingDates[], tradingDates: TradingDates): TradingDates {
  if (!size(datesArray)) return [];
  if (size(datesArray) === 1) return datesArray[0] || [];
  const minDate = getMinDate(datesArray);
  const maxDate = getMaxDate(datesArray);
  return filter<string>((date) => date >= minDate && date <= maxDate)(tradingDates);
}

function getTargetDates({
  targetDatesArray,
  targetDates
}: {
  targetDatesArray: TradingDates[];
  targetDates?: TradingDates;
}): TradingDates {
  if (targetDates) {
    return getUnionDates(targetDatesArray, targetDates);
  }
  if (size(targetDatesArray) === 1) {
    return targetDatesArray?.[0] || [];
  }
  if (size(targetDatesArray) > 1) {
    const minDate = getMinDate(targetDatesArray);
    const maxDate = getMaxDate(targetDatesArray);
    const maxRangeDates = find<TradingDates>((dates) => {
      return first(dates) === minDate && last(dates) === maxDate;
    })(targetDatesArray);
    if (maxRangeDates) {
      return maxRangeDates;
    }
    return maxBy<TradingDates>(size)(targetDatesArray) || [];
  }
  return [];
}

function getSlicedDates({
  name,
  periodDate,
  targetDatesArray,
  otherDatesArray,
  targetDates
}: {
  name: DatePeriodName;
  periodDate?: PeriodDate | undefined | null;
  targetDatesArray: TradingDates[];
  otherDatesArray: TradingDates[];
  targetDates?: TradingDates;
}): TradingDates {
  const _targetDates = getTargetDates({
    targetDatesArray,
    targetDates
  });
  const datesArray = [...(targetDatesArray || []), ...(otherDatesArray || [])];
  if (isEmpty(_targetDates)) return [];
  // 使用datePeriod([开始时间， 结束时间])切分时间
  if (isArray(periodDate) && size(periodDate) > 0) {
    return getDatesByPeriodDate(periodDate || ['', ''], _targetDates);
  }
  // 使用DatePeriodName切分时间
  const currentDate = last(_targetDates);
  if (name === 'COMMON_TIME') {
    const [start, end] = getPeriodDateByCommonTime(datesArray);
    return filter<string>((date) => date >= start && date <= end)(_targetDates);
  }
  if (name === 'FROM_THIS_YEAR') {
    // todo currentDate 是否应该是datasourceTime ？
    return getDatesByDatePeriod('FROM_THIS_YEAR', _targetDates, currentDate);
  }
  return getDatesByDatePeriod(name, _targetDates, currentDate);
}

export function getSlicedDateDatas({
  name,
  periodDate,
  targetDateDatas,
  otherDateDatas,
  targetDates,
  dataTransform
}: {
  name: DatePeriodName;
  periodDate?: PeriodDate | undefined | null;
  targetDateDatas: DateData[];
  otherDateDatas?: DateData[];
  targetDates?: TradingDates;
  dataTransform?: (data: DateDataItem) => unknown;
}) {
  const dates: TradingDates = getSlicedDates({
    name,
    periodDate,
    targetDatesArray: map<DateData, TradingDates>((dateData) =>
      map((item: DateDataItem) => item?.date)(dateData?.data || [])
    )(targetDateDatas),
    otherDatesArray: map<DateData, TradingDates>((dateData) =>
      map((item: DateDataItem) => item?.date)(dateData?.data || [])
    )(otherDateDatas),
    targetDates
  });
  const dateDatas = [...(targetDateDatas || []), ...(otherDateDatas || [])];
  const startDate = first(dates) as string;
  const endDate = last(dates) as string;
  return {
    dates,
    dateDatas: map<DateData, DateData>(
      update('data', (data: DateDataItem[]) => {
        const filterDatas = filter(
          (dataItem: DateDataItem) => dataItem?.date >= startDate && dataItem?.date <= endDate
        )(data);
        if (dataTransform) return map(dataTransform)(filterDatas);
        return filterDatas;
      })
    )(dateDatas)
  };
}

/**
 *
 * @param object
 *  {
 *    name 时间段周期
 *    targetDates 目标时间段
 *    dateReturns 用于处理的所有时间和日收益数据
 *  }
 * @returns 针对目标时间段, 根据不同的时间周期， 切分符合时间周期的日期和日收益数据
 */
export function getSlicedDateReturnsAndRangeDates({
  name,
  periodDate,
  targetDateReturns,
  otherDateReturns,
  targetDates
}: {
  name: DatePeriodName;
  periodDate?: PeriodDate | undefined | null;
  targetDateReturns: DateReturn[];
  otherDateReturns?: DateReturn[];
  targetDates?: TradingDates;
}): DateReturnAndRangeDates {
  const dates: TradingDates = getSlicedDates({
    name,
    periodDate,
    targetDatesArray: map<DateReturn, TradingDates>((dateReturn) => dateReturn?.dates || [])(targetDateReturns),
    otherDatesArray: map<DateReturn, TradingDates>((dateReturn) => dateReturn?.dates || [])(otherDateReturns),
    targetDates
  });
  const _targetDates = getTargetDates({
    targetDatesArray: map((item: DateReturn) => item?.dates || [])(targetDateReturns),
    targetDates
  });
  const dateReturns = [...(targetDateReturns || []), ...(otherDateReturns || [])];

  if (isEmpty(_targetDates))
    return {
      dateReturns,
      dates: [],
      datesWithPreviousOneDay: [],
      dateReturnsWithPreviousOneDay: dateReturns
    };

  const _dateReturns = map<DateReturn, DateReturn>((dateReturn) => getDateReturnFitTargetDates(dates, dateReturn))(
    dateReturns
  );
  return {
    dateReturns: _dateReturns,
    dates,
    datesWithPreviousOneDay: increasePreviousOneDayForDates(dates, _targetDates),
    dateReturnsWithPreviousOneDay: map<DateReturn, DateReturn>((dateReturn) =>
      increasePreviousOneDayForDateReturn(dateReturn, _targetDates)
    )(_dateReturns)
  };
}
