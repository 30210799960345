import React, { FC, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { store } from './store';

interface ReduxStoreProviderProps {
  children: ReactNode;
}

const ReduxStoreProvider: FC<ReduxStoreProviderProps> = ({ children }) => <Provider store={store}>{children}</Provider>;

export default ReduxStoreProvider;
