import { getMessage } from '@betalpha/intl';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';
import { map } from 'lodash/fp';
import React, { useMemo } from 'react';
import { ETFSimilarETFCompareBody } from '@/interface/etfFund';
import DetailModal from '@/components/detail-modal';
import Rate from '@/components/rate';
import { NOT_PERCENTAGE_FEATURE_FACTORS } from '@/views/fund-compass-detail/constants';
import { toFixedNumber } from '@/util/numberFormatter';
import CompareTable, { CompareTableDataType } from '../compare-table';
import style from './index.module.less';

interface FeatrueFactorsCompareProps {
  featureFactor?: ETFSimilarETFCompareBody['featureFactor'];
  compareSummary?: ETFSimilarETFCompareBody['compareSummary'];
}
export default React.memo<FeatrueFactorsCompareProps>(({ featureFactor, compareSummary }) => {
  const featureFactorDataSource = useMemo<CompareTableDataType[]>(
    () => [
      {
        type: getMessage('featureFactorEvaluateLevel'),
        curerntEtfFund: featureFactor?.featureFactorRating?.fundValue ? (
          <Rate wrapClassName={style.Rate} value={featureFactor?.featureFactorRating?.fundValue} />
        ) : (
          '--'
        ),
        sameCategoryEtfFund: featureFactor?.featureFactorRating?.sameTypeFund ? (
          <Rate wrapClassName={style.Rate} value={featureFactor?.featureFactorRating?.sameTypeFund} />
        ) : (
          '--'
        )
      },
      ...map((item: ETFSimilarETFCompareBody['featureFactor']['featureFactors'][number]) => {
        const isNotPercentageNumber = NOT_PERCENTAGE_FEATURE_FACTORS.includes(item.code);
        return {
          type: item.name,
          curerntEtfFund: isNotPercentageNumber ? toFixedNumber(2)(item.fundValue) : percentage(item.fundValue),
          sameCategoryEtfFund: isNotPercentageNumber
            ? toFixedNumber(2)(item.sameTypeFund)
            : percentage(item.sameTypeFund)
        };
      })(featureFactor?.featureFactors)
    ],
    [featureFactor]
  );
  return (
    <div className={style.FeatureFactorCompareWrap}>
      <div className={style.Title}>
        {getMessage('featureFactorCompare')}
        <DetailModal
          triggerClassName={style.Question}
          title={getMessage('featureFactorCompare')}
          content={getMessage('featureFactorCompareTips')}
        />
      </div>
      <div className={style.FeatureTableWrap}>
        <CompareTable dataSource={featureFactorDataSource} compareSummary={compareSummary} />
      </div>
    </div>
  );
});
