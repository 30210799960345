import { NamedExoticComponent } from 'react';
import { includes } from 'lodash/fp';
import { getMessage } from '@betalpha/intl';
import MarketValuation from './components/marketMacroSummary/components/marketValuation';
import MarketLiquidity from './components/marketMacroSummary/components/marketLiquidity';
import IndustryRecentSituation from './components/marketMacroSummary/components/industryRecentSituation';
import MarketExpansionTrends from './components/marketMacroSummary/components/marketExpansionTrends';

export type marketTabItem = {
  id: string;
  name: string;
  component: NamedExoticComponent<any>;
};

export const marketMacroTabItems: marketTabItem[] = [
  {
    id: 'marketValuation',
    name: 'marketValuation',
    component: MarketValuation
  },
  {
    id: 'marketLiquidity',
    name: 'marketLiquidity',
    component: MarketLiquidity
  },
  {
    id: 'industryRecentSituation',
    name: 'industryRecentSituation',
    component: IndustryRecentSituation
  },
  {
    id: 'marketExpansionTrends',
    name: 'marketExpansionTrends',
    component: MarketExpansionTrends
  }
];

export const Colors = ['#BAC3D2', '#1A396A', '#CFB47F'];

export const modelIndustryType = [
  {
    id: 'keyFocusIndustry',
    message: 'mainPointFocusIndustry'
  },
  {
    id: 'focusIndustry',
    message: 'focusOnIndustry'
  }
];

export const etfModalType = [
  {
    id: 'momentumModelIndustry',
    message: 'etfIndustryMomentumModel',
    summary: 'ETF行业动量模型对行业内部的头部效应强度进行综合打分。行业总体得分越高，头部效应越强，投资价值越高。'
  },
  {
    id: 'dataSniffingModelIndustry',
    message: 'etfDataSniffingModel',
    summary:
      'ETF高频数据嗅探模型利用行业内部股票在高频交易数据进行综合打分。行业总体得分越高，博弈风险越低，投资价值越高。'
  },
  {
    id: 'northBoundModelIndustry',
    message: 'etfNorthCapitalModal',
    summary:
      'ETF北上资金模型对行业内北上资金越活跃程度进行综合打分。行业总体得分越高，说明该行业越被“聪明资金”偏好，投资价值越高。'
  }
];

export const getSummaryLevel = (level?: string) => {
  if (includes('HIGH')(level)) {
    return getMessage('highRisk');
  }
  if (includes('MEDIUM')(level)) {
    return getMessage('middleRisk');
  }
  if (includes('LOW')(level)) {
    return getMessage('lowRisk');
  }
  return '--';
};

export const HistoryPoint = {
  HIGH: 'highPoint',
  LOW: 'lowPoint',
  MEDIUM: 'middlePosition'
};

export const ThemeRoute = {
  HIGH: 'marketLiquidityTurnoverEvaluate_high',
  LOW: 'marketLiquidityTurnoverEvaluate_low'
};

export const RiskColor = {
  HIGH: '#DD314C',
  LOW: '#328A62',
  MEDIUM: '#CFB47F'
};

export const tipModalMessage = {
  marketValuation: '市场估值越高，市场整体价格越被高估，投资性价比越低。',
  marketLiquidity: '市场流动性越好，市场交易越活跃，投资者的交易成本越低。',
  marketExpansionTrends: '新发ETF和募集期ETF在各个分类标签中出现的频次，预示了ETF市场未来发展的方向。',
  industryRecentSituation: '从涨幅、净流入资金和头部ETF份额涨幅三个维度展示行业类ETF过去一周的表现。',
  ETFModelIndustryRecommend:
    '通过不同维度的多个模型对行业ETF市场进行分析评价，分别筛选出当前配置风险较低的行业，以及需要预警的高风险行业。',
  focusKeyIndustry: '什么是重点关注行业？',
  focusKeyIndustryTip: '重点关注行业为多个ETF行业模型共同的优选行业。',
  focusIndustry: '什么是关注行业？',
  focusIndustryTip: '关注行业为任意ETF行业模型的优选行业。',
  topNumber: '前3指该模型筛选出的投资价值最高的三个行业；',
  lastNumber: '后3指该模型筛选出的配置风险最高的三个行业。'
};

export const getQuadrant = (x: number, y: number) => {
  if (x >= 0 && y >= 0) return 1;
  if (x > 0 && y < 0) return 4;
  if (x < 0 && y > 0) return 2;
  if (x <= 0 && y <= 0) return 3;
  return 1;
};

export type IndustryValue = {
  name: string;
  value: number;
};
export type Industry2Value = Record<string, number>;

export const IndustryModelIcons = {
  // zx20_62: 'computerIcon', // 计算机
  // zx20_35: 'medicalBiologyIcon', // 生物
  // zx20_36: 'foodBeverageIcon' // 食品饮料

  zx20_35: 'medicalBiologyIcon', // 医药
  zx20_27: 'electricityNewEnergyIcon', // 电力设备及新能源
  zx20_11: 'coalIcon', // 煤炭
  zx20_20: 'electricityPublicUtilitiesIcon', // 电力及公用事业
  zx20_41: 'nonBankFinanceIcon', // 非银行金融
  zx20_22: 'chemicalIcon', // 基础化工
  zx20_42: 'realEstateIcon', // 房地产
  zx20_23: 'buildIcon', // 建筑
  zx20_37: 'forestryIcon', // 农林牧渔
  zx20_36: 'foodBeverageIcon', // 食品饮料
  zx20_40: 'bankIcon', // 银行
  zx20_12: 'nonFerrousMetalIcon', // 有色金属
  zx20_61: 'communicationIcon', // 通信
  zx20_60: 'electronIcon', // 电子
  zx20_24: 'buildMaterialsIcon', // 建材
  zx20_30: 'carIcon', // 汽车
  zx20_63: 'mediaIcon', // 传媒
  zx20_62: 'computerIcon', // 计算机
  zx20_21: 'steelIcon', // 钢铁
  zx20_33: 'houseElectricalIcon', // 家电
  zx20_28: 'warIndustryIcon' // 国防军工
};
