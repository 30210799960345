import React, { useMemo } from 'react';
import cn from 'classnames';

import style from './index.module.less';

interface ColorNumberProps {
  value: number | undefined;
  formatter?: (val: number | undefined) => any;
  className?: string;
}
export default React.memo<ColorNumberProps>(({ value, formatter, className = '' }) => {
  const color = useMemo(() => ((value ?? NaN) > 0 ? '#DD314C' : '#5BA06B'), [value]);
  return (
    <div style={{ color }} className={cn(style.ColorNumber, className)}>
      {formatter ? formatter(value) : value}
    </div>
  );
});
