import React, { useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import { map } from 'lodash/fp';
import { fastProp, mapIndexed } from '@betalpha/stdlib/base';
import cn from 'classnames';
import CardLayout from '@/components/cardLayout';
import { useGetEvaluationReportInfoQuery } from '@/infra/api/funds';
import { BaseInfoTableDataType, useGetBasicInfoTableDataSource, useGetFundTags } from '../../hooks';

import style from './index.module.less';

enum FUND_STYLE_TYPE_ENUM {
  mytag_rating23_gh,
  mytag_rating23_mh,
  mytag_rating23_vh,
  mytag_rating23_gm,
  mytag_rating23_mm,
  mytag_rating23_vm,
  mytag_rating23_gl,
  mytag_rating23_ml,
  mytag_rating23_vl
}

export default React.memo(() => {
  const { fundId } = useParams();
  const { data: evaluationReportInfo, isFetching } = useGetEvaluationReportInfoQuery(fundId || '', { skip: !fundId });

  const dataSource = useGetBasicInfoTableDataSource(evaluationReportInfo);

  const fundTypeActiveIdx = useMemo<number>(
    () => FUND_STYLE_TYPE_ENUM[fastProp('fundType')(evaluationReportInfo || {}) as any] as any,
    [evaluationReportInfo]
  );

  const { excellentFundTags, poorFundTags } = useGetFundTags(fundId ?? '');
  return (
    <CardLayout title="" loading={isFetching}>
      <div className={style.BaseInfoRow}>
        <div className={style.TagWrap}>
          <div>
            {mapIndexed((item: string, idx: number) => (
              <div className={style.Excellent} key={idx}>
                {item}
              </div>
            ))(excellentFundTags)}
          </div>
          <div>
            {mapIndexed((item: string, idx: number) => (
              <div className={style.Poor} key={idx}>
                {item}
              </div>
            ))(poorFundTags)}
          </div>
        </div>
        <div className={style.TableWrap}>
          <div className={style.Left}>
            {map((item: BaseInfoTableDataType) => (
              <div className={style.DataRow} key={item.key}>
                <div className={style.Label}>{getMessage(item.label)}</div>
                <div className={style.Value}>{item.value || '--'}</div>
              </div>
            ))(dataSource)}
          </div>
          <div className={style.Right}>
            <div className={style.Row}>
              <div className={style.XAxisItem} />
              <div className={style.XAxisItem}>{getMessage('growth')}</div>
              <div className={style.XAxisItem}>{getMessage('balance')}</div>
              <div className={style.XAxisItem}>{getMessage('value')}</div>
            </div>
            <div className={style.Row}>
              <div className={style.YAxis}>
                <div className={style.YAxisItem}>{getMessage('highRisk')}</div>
                <div className={style.YAxisItem}>{getMessage('middleRisk')}</div>
                <div className={style.YAxisItem}>{getMessage('lowRisk')}</div>
              </div>
              <div className={style.RectChart}>
                {Array(9)
                  .fill(0)
                  .map((_: any, idx: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={cn(style.RectChartItem, idx === fundTypeActiveIdx && style.ActiveItem)} key={idx} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardLayout>
  );
});
