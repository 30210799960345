import React, { FC, ReactNode } from 'react';
import { BetalphaRouter } from '@betalpha/router';
import { store } from '../store';

interface ReduxRouterProps {
  children: ReactNode;
}

const ReduxRouter: FC<ReduxRouterProps> = ({ children }) => <BetalphaRouter store={store}>{children}</BetalphaRouter>;

export default ReduxRouter;
