import React from 'react';
import cn from 'classnames';
import Code from '@/assets/code.png';
// import sfinx from '@/assets/sfinx-logo.svg';
import { ReactComponent as BetalphaLogo } from '@/assets/betalpha-logo.svg';
import { ReactComponent as Sfinx } from '@/assets/sfinx-logo.svg';
import { ReactComponent as Enter } from '@/assets/enter.svg';
import { ReactComponent as Production } from '@/assets/production.svg';
import style from './index.module.less';

const productions =
  process.env.REACT_APP_ENV === 'test'
    ? [
        {
          name: 'SFinx—基金评价2.0',
          key: 'REACT_APP_EQUITY',
          url: 'http://equity-fund-dev.betalpha.com:85'
        },
        {
          name: 'SFinx—ETF指南针',
          key: 'REACT_APP_ETF',
          url: 'http://etf-dev.betalpha.com:85'
        },
        {
          name: 'SFinx—债基稳斗士',
          key: 'REACT_APP_BOND',
          url: 'http://bond-fund-dev.betalpha.com:85'
        }
      ]
    : [
        {
          name: 'SFinx—基金评价2.0',
          key: 'REACT_APP_EQUITY',
          url: 'https://equity-fund.betalpha.com'
        },
        {
          name: 'SFinx—ETF指南针',
          key: 'REACT_APP_ETF',
          url: 'https://etf.betalpha.com'
        },
        {
          name: 'SFinx—债基稳斗士',
          key: 'REACT_APP_BOND',
          url: 'https://bond-fund.betalpha.com'
        }
      ];
export default React.memo(() => {
  // const scrollToTop = useCallback(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  // const handleClick = useCallback(
  //   (url: string) => {
  //     window.location.href = url;
  //     scrollToTop();
  //   },
  //   [scrollToTop]
  // );
  return (
    <div className={style.Footer}>
      <div className={style.Header}>
        <div className={style.Left}>
          <div className={style.Logo}>
            <div className={cn(style.Back)}>
              <BetalphaLogo className={cn(style.Beta)} />
            </div>
            <span>&</span>
            <div className={cn(style.Back)}>
              <Sfinx className={cn(style.Sfinx)} />
              {/* <img src={sfinx} alt="No Content" className={cn(style.Sfinx)} /> */}
            </div>
          </div>
          <div className={style.Des}>
            倍发智数专注于科技体系化赋能财富管理的前瞻性需求，聚焦于科技赋能的财富管理业务在机构落地应用。
          </div>
          <div className={style.Special}>关注倍发智数公众号</div>
        </div>
        <div>
          <img src={Code} className={style.Code} alt="No Content" />
        </div>
      </div>

      <div className={style.Content}>
        <div className={style.LinkTitle}>产品链接：</div>
        {productions.map(({ name, key, url }) => (
          <a href={url} target="_parent" key={key} className={style.Prod}>
            <div className={style.Left}>
              <Production className={style.ProdIcon} />
              {/* <img src={Production} alt="No Content" className={style.ProdIcon} /> */}
              {name}
            </div>
            <Enter className={style.Enter} />
            {/* <img src={Enter} alt="No Content" className={style.Enter} /> */}
          </a>
        ))}
      </div>

      <div className={style.Bottom}>
        <div className={style.Title}>理财有风险，投资需谨慎</div>
        <div className={style.Risk}>
          风险提示：基金有风险，投资需谨慎，过往业绩不代表未来表现。本内容属于倍发公司所有，未经同意请勿引用或转载。以上内容仅供参考，不预示未来表现，也不作为任何投资建议，其中的观点和预测仅代表当时观点，今后可能发生改变。
        </div>
        <div className={style.Des}>蜀ICP备15031820号 © Betalpha Technology Co.,Ltd.</div>
      </div>
    </div>
  );
});
