import { createSelector } from '@betalpha/redux-toolkit';
import { AllFundBody } from '@/interface/fundDetail';
import {
  ETFFundBasicInfoBody,
  ETFPerformanceTrendBody,
  ETFPositionCompareBody,
  ETFRiskProbeBody,
  ETFSimilarETFCompareBody,
  ETFTopStockPositionBodyItem,
  IndustryModelProp,
  MarketMacroSummary
} from '@/interface/etfFund';
import baseApi from './base-api';

export interface UpdateCompletedBody {
  updateCompleted: boolean;
}

const fundsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEtfUpdateCompleted: builder.query<UpdateCompletedBody, void>({
      query: () => ({
        url: `/etf/compass/update-completed`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // ETF指南针-所有etf基金
    getAllETFFund: builder.query<AllFundBody[], void>({
      query: () => ({
        url: `/etf/compass/all/etf_fund/info`,
        method: 'GET',
        options: {
          showGlobalLoading: true,
          needAuth: false
        }
      })
    }),
    // ETF指南针-基础信息
    getETFFundBasicInfo: builder.query<ETFFundBasicInfoBody, string>({
      query: (fundId) => ({
        url: `/etf/compass/fund_basic_info/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // ETF指南针-业绩走势
    getETFFundPerformanceTrend: builder.query<ETFPerformanceTrendBody, string>({
      query: (fundId) => ({
        url: `/etf/compass/performance/trend/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // ETF指南针-同类ETF持仓对比
    getETFPositionCompare: builder.query<ETFPositionCompareBody, string>({
      query: (fundId) => ({
        url: `/etf/compass/compare/position/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // ETF指南针-基金重仓股票持仓
    getETFTopStockPosition: builder.query<ETFTopStockPositionBodyItem[], string>({
      query: (fundId) => ({
        url: `/etf/compass/top_stock_position/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // ETF指南针-相似ETF对比
    getETFSimilarETFCompare: builder.query<ETFSimilarETFCompareBody, string>({
      query: (fundId) => ({
        url: `/etf/compass/similar_etf_compare/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // ETF指南针-风险探针
    getETFRiskProbe: builder.query<ETFRiskProbeBody, string>({
      query: (fundId) => ({
        url: `/etf/compass/risk_probe/summary/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 首页-市场宏观摘要
    getMarketMacroSummary: builder.query<MarketMacroSummary, void>({
      query: () => ({
        url: `/etf/compass/market_macro_summary`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 首页-市场宏观摘要
    getIndustryModel: builder.query<IndustryModelProp, void>({
      query: () => ({
        url: `/etf/compass/industry-model`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    })
  })
});

export const {
  useGetAllETFFundQuery,
  useGetETFFundBasicInfoQuery,
  useGetETFFundPerformanceTrendQuery,
  useGetETFPositionCompareQuery,
  useGetETFTopStockPositionQuery,
  useGetETFSimilarETFCompareQuery,
  useGetETFRiskProbeQuery,
  useGetMarketMacroSummaryQuery,
  useGetIndustryModelQuery,
  useGetEtfUpdateCompletedQuery
} = fundsApi;

const { getAllETFFund } = fundsApi.endpoints;

export const allETFFundsSelector = createSelector(getAllETFFund.select(), (result) => result?.data || []);
