import React from 'react';
import { first, isEmpty, last, merge } from 'lodash/fp';

import { getMessage } from '@betalpha/intl';
import { EvaluationReportInfoBody } from '@/interface/fundDetail';
import EmptyContent from '@/components/emptyContent';
import YieldTrendChart from '@/base-components/charts/yieldTrendChart';
import { ChartProps } from '@/base-components/charts/yieldTrendChart/type';
import { useGetLineChartData } from '../../hooks';
import style from './index.module.less';

export default React.memo(
  ({
    showDate = false,
    fundEvaluationReportInfo
  }: {
    showDate?: boolean;
    fundEvaluationReportInfo?: EvaluationReportInfoBody;
  }) => {
    const { dates, series, chartOptions } = useGetLineChartData(fundEvaluationReportInfo);

    const finalChartOptions = merge(chartOptions)(
      showDate
        ? {
            graphicOptions: {
              top: 20,
              type: 'text',
              right: 0,
              style: {
                text: `（${first(dates)}~${last(dates)}）`,
                fontSize: 9,
                lineHeight: 12,
                fill: '#999999',
                fontFamily: 'PingFangSC-Semibold, PingFang SC',
                fontWeight: 600
              }
            } as ChartProps['graphicOptions']
          }
        : {}
    );
    return isEmpty(dates) ? (
      <EmptyContent className={style.EmptyContent}>
        <strong>{getMessage('noData')}</strong>
      </EmptyContent>
    ) : (
      <YieldTrendChart dates={dates} series={series} showDataZoom={false} height={220} onZero {...finalChartOptions} />
    );
  }
);
