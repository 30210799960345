import { createSlice, getActionType } from '@betalpha/redux-toolkit';

interface State {
  isLoading: number;
}

const slice = createSlice({
  name: getActionType(__filename),
  initialState: {
    isLoading: 0
  },
  reducers: {
    showLoading: (state: State) => {
      state.isLoading += 1;
    },
    hideLoading: (state: State) => {
      state.isLoading -= 1;
    }
  }
});

export const { showLoading, hideLoading } = slice.actions;

export default slice.reducer;
