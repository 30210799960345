import { ErrorBlock } from 'antd-mobile';
import React, { ReactNode } from 'react';
import crashImg from '@/assets/crash.svg';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}
export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error: Error) {
    // Update state with error details
    this.setState({ hasError: true, error });
  }

  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return <ErrorBlock image={crashImg} fullPage description={error?.toString()} />;
    }

    return children;
  }
}
