import { getMessage } from '@betalpha/intl';
import { mapIndexed } from '@betalpha/stdlib/base';
import dayjs from 'dayjs';

import {
  add,
  drop,
  flatten,
  flow,
  fromPairs,
  isEmpty,
  keys,
  map,
  max,
  min,
  multiply,
  nth,
  omit,
  orderBy,
  prop,
  size,
  subtract,
  values,
  zip
} from 'lodash/fp';
import React, { useMemo, useRef } from 'react';
import { BarSeriesOption, EChartsOption, MarkLineComponentOption } from 'echarts';
import { useSize } from 'ahooks';
import EmptyContent from '@/components/emptyContent';
import BaseEcharts from '@/components/baseEcharts';
import { useGetMarketMacroSummaryQuery } from '@/infra/api/etf-funds';
import upIcon from '@/assets/upIcon.png';
import { getBarChartDataZoomConfig } from '@/util/chart';
import { fixed0HundredMillion, fixedHundredMillion, formatPercentage } from '@/util/numberFormatter';
import { formatNilMessage } from '@/views/fund-evaluation-detail/constants';
import { Colors, ThemeRoute } from '../../../contants';
import style from '../index.module.less';

type SerieDataType = {
  date: string;
  name: string;
  data: [string, number][];
};

const ECHART_NORMAL_SCREEN_WIDTH = 345;

export default React.memo(() => {
  const chartWrapRef = useRef(null);

  const { width: chartWrapWidth } = useSize(chartWrapRef) ?? {};

  const { data } = useGetMarketMacroSummaryQuery();

  const { marketLiquidity } = data || {};

  const { monthlyTurnover, marketAmountChange, turnoverIncrease, turnoverIncreaseTypes, turnoverEvaluation } =
    marketLiquidity || {};

  const type = useMemo(() => flow(keys, drop(1))(monthlyTurnover), [monthlyTurnover]);
  const dataValue = useMemo(() => flow(values, drop(1), flatten)(monthlyTurnover), [monthlyTurnover]);

  const { dates } = monthlyTurnover || {};

  const serieData = useMemo(
    () =>
      flow(
        mapIndexed((date: string, index: number) => ({
          date,
          name: `${dayjs(date).month() + 1}月`,
          data: zip(map((key: string) => getMessage(key))(type))(
            map((key: string) => flow(prop(key), nth(index))(monthlyTurnover))(type)
          )
        })),
        orderBy('date', 'asc')
      )(dates),
    [dates, type, monthlyTurnover]
  );

  const monthlyTurnoverMapByName = useMemo(() => {
    const monthlyData = flow(
      keys,
      map((key: string) => [getMessage(key), prop(key)(monthlyTurnover)]),
      fromPairs
    )(monthlyTurnover);
    return monthlyData;
  }, [monthlyTurnover]);

  const isNeedHideZoomConfig = useMemo(
    () => ((chartWrapWidth ?? ECHART_NORMAL_SCREEN_WIDTH) / ECHART_NORMAL_SCREEN_WIDTH) * 85 > 100,
    [chartWrapWidth]
  );

  const markLineHeight = useMemo(
    () => flow(omit(['dates']), values, flatten, max, multiply(0.08))(monthlyTurnover),
    [monthlyTurnover]
  );

  const options = useMemo<EChartsOption>(
    () => ({
      grid: { left: 40, right: 0, top: 35, bottom: 100 },
      legend: { itemGap: 23 },
      xAxis: {
        type: 'category',
        axisTick: { show: false },
        boundaryGap: true,
        animation: false,
        axisLabel: {
          margin: 22,
          hideOverlap: false,
          fontWeight: 400,
          interval: 0
        }
      },
      yAxis: {
        type: 'value',
        max: max(dataValue),
        axisLabel: {
          fontSize: 10,
          color: '#666666',
          fontWeight: 400,
          formatter: fixed0HundredMillion
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'dotted'
          }
        }
      },
      series: mapIndexed(
        (item: SerieDataType, index: number): BarSeriesOption => ({
          name: item.name,
          type: 'bar',
          animation: false,
          barWidth: 15,
          barGap: '5%',
          color: Colors?.[index],
          label: {
            fontWeight: 500,
            fontSize: 10,
            color: '#999',
            show: false
          },
          data: flow(
            omit('name'),
            map((v: [string, number]) => ({
              value: v,
              label: {
                position: v[1] >= 0 ? 'top' : 'bottom',
                formatter: (params: any) => {
                  return params?.value[1];
                }
              }
            }))
          )(item.data),
          markLine:
            index === 1
              ? {
                  silent: true,
                  symbol: ['none', 'triangle'],
                  symbolSize: [9, 9],
                  lineStyle: {
                    width: 2,
                    color: '#D78171',
                    type: [5, 1],
                    dashOffset: 9
                  },
                  data: map((key: string) => {
                    return [
                      {
                        coord: [key, flow(prop(key), nth(1))(monthlyTurnoverMapByName) as number]
                      },
                      {
                        coord: [
                          key,
                          flow(
                            prop(key),
                            (res) => add(nth(1)(res) || 0)(subtract(max(res || []) || 0)(min(res || []) || 0)),
                            add(markLineHeight)
                          )(monthlyTurnoverMapByName) as number
                        ]
                      }
                    ];
                  })(turnoverIncreaseTypes || []) as MarkLineComponentOption['data'],
                  label: {
                    padding: [5, 15],
                    borderWidth: 1,
                    borderColor: '#FFFFFF',
                    backgroundColor: '#FFF',
                    // borderDashOffset:
                    borderRadius: 5,
                    shadowBlur: 2,
                    shadowColor: 'rgba(83, 65, 35, 0.16)',
                    shadowOffsetX: 1,
                    shadowOffsetY: 2,
                    formatter: ['{x|}', `{a|+ ${formatPercentage(turnoverIncrease)}}`].join('\n'),
                    rich: {
                      y: {
                        height: 30
                      },
                      x: {
                        backgroundColor: {
                          image: upIcon
                        },
                        height: 30,
                        width: 20
                      },
                      a: {
                        color: '#1A396A',
                        lineHeight: 20,
                        fontWeight: 'bold',
                        fontSize: 13.5
                      }
                    }
                  }
                }
              : {}
        })
      )(serieData) as BarSeriesOption[],
      dataZoom: isNeedHideZoomConfig
        ? { show: false }
        : getBarChartDataZoomConfig(
            30,
            ((chartWrapWidth ?? ECHART_NORMAL_SCREEN_WIDTH) / ECHART_NORMAL_SCREEN_WIDTH) * 60
          )
    }),
    [
      serieData,
      chartWrapWidth,
      dataValue,
      monthlyTurnoverMapByName,
      turnoverIncreaseTypes,
      turnoverIncrease,
      isNeedHideZoomConfig,
      markLineHeight
    ]
  );
  return (
    <div>
      <div className={style.MarketValuationSummary}>
        {getMessage('etfMarketLiquiditySummary', {
          marketAmountChangeSummary:
            (marketAmountChange || 0) > 0
              ? getMessage('marketLiquidityIncreaseTip')
              : getMessage('marketLiquidityDecreaseTip'),
          transactionType: (marketAmountChange || 0) > 0 ? getMessage('increase') : getMessage('reduce'),
          marketAmountChange: `${fixedHundredMillion(Math.abs(marketAmountChange || 0))}${getMessage('million')}`,
          turnoverIncreaseTypes: turnoverIncreaseTypes?.join('、'),
          turnoverIncrease: formatPercentage(turnoverIncrease),
          turnoverEvaluation: formatNilMessage(ThemeRoute, turnoverEvaluation)
        })}
      </div>
      {isEmpty(type) ? (
        <EmptyContent className={style.EmptyContent}>
          <strong>{getMessage('noData')}</strong>
        </EmptyContent>
      ) : (
        <div className={style.ChartWrap} ref={chartWrapRef}>
          <BaseEcharts options={options} height={400} notMerge />
          {!(size(type) <= 4) && <div className={style.ScrollBarMask} style={{ bottom: 29 }} />}
        </div>
      )}
    </div>
  );
});
