import { createSelector } from '@betalpha/redux-toolkit';
import {
  AllFundBody,
  ComprehensivePerformanceParam,
  EvaluationReportAbilitySummaryBody,
  EvaluationReportInfoBody,
  EvaluationReportRiskSummaryBody,
  FundPerformanceBody,
  HotFundsBody,
  IndustryAbilityEvaluationParam,
  ManagerEvaluationBody,
  MarketCapabilityDetailParam,
  RiskDetailParam,
  StockSelectionAbilityBody,
  TurnoverImprovementAbilityBody
} from '@/interface/fundDetail';
import { UpdateCompletedBody } from './etf-funds';

import baseApi from './base-api';

const fundsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFundUpdateCompleted: builder.query<UpdateCompletedBody, void>({
      query: () => ({
        url: 'open-fund/evaluation-report/mobile/update-completed',
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // // 所有基金，包含不支持基金评价的基金
    // getAllFund: builder.query<FundsInterface[], void>({
    //   query: () => ({
    //     url: `/openfunds/allFund`,
    //     method: 'GET',
    //     options: {
    //       showGlobalLoading: true
    //     }
    //   })
    // }),
    // 获取所有支持基金评价的基金，主要用于基金搜索功能
    getAllFund: builder.query<AllFundBody[], void>({
      query: () => ({
        url: `/mobile/front/page/all/evaluation/funds`,
        method: 'GET',
        options: {
          showGlobalLoading: true,
          needAuth: false
        }
      })
    }),
    getEvaluationReportInfo: builder.query<EvaluationReportInfoBody, string>({
      query: (fundId) => ({
        url: `/open-fund/evaluation-report/mobile/info/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    getEvaluationReportAbilitySummary: builder.query<EvaluationReportAbilitySummaryBody, string>({
      query: (fundId) => ({
        url: `/open-fund/evaluation-report/mobile/ability-summary/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    getEvaluationReportRiskSummary: builder.query<EvaluationReportRiskSummaryBody, string>({
      query: (fundId) => ({
        url: `/open-fund/evaluation-report/mobile/risk-summary/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 评价报告-综合业绩
    getComprehensivePerformance: builder.query<ComprehensivePerformanceParam, string>({
      query: (fundId) => ({
        url: `/open-fund/evaluation-report/mobile/comprehensive-performance/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 评价报告-行业配置能力
    getIndustryAbilityEvaluation: builder.query<IndustryAbilityEvaluationParam, string>({
      query: (fundId) => ({
        url: `/open-fund/evaluation-report/mobile/ability-evaluation-detail/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 评价报告-个股选择能力
    getStockSelectionAbility: builder.query<StockSelectionAbilityBody, string>({
      query: (fundId) => ({
        url: `/open-fund/evaluation-report/mobile/ability/stock-selection/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 评价报告-调仓改善能力
    getTurnoverImprovementAbility: builder.query<TurnoverImprovementAbilityBody, string>({
      query: (fundId) => ({
        url: `/open-fund/evaluation-report/mobile/turnover-improvement-ability/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 评价报告-行情捕获能力
    getMarketCapabilityDetail: builder.query<MarketCapabilityDetailParam, string>({
      query: (fundId) => ({
        url: `/open-fund/evaluation-report/mobile/market-capability-detail/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 评价报告-风险探针详情
    getRiskDetail: builder.query<RiskDetailParam, string>({
      query: (fundId) => ({
        url: `/open-fund/evaluation-report/mobile/risk-detail/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 评价报告-基金经理评价
    getManagerEvaluation: builder.query<ManagerEvaluationBody, string>({
      query: (fundId) => ({
        url: `/open-fund/evaluation-report/mobile/manager-evaluation/${fundId}`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 首页-五星基金近期表现
    getFundPerformance: builder.query<FundPerformanceBody, void>({
      query: () => ({
        url: `/mobile/front/page/fund/performance`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 首页-热门好基
    getHotFunds: builder.query<HotFundsBody, void>({
      query: () => ({
        url: `/mobile/front/page/hot/funds`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    })
  })
});

export const {
  useGetEvaluationReportInfoQuery,
  useGetAllFundQuery,
  useGetEvaluationReportAbilitySummaryQuery,
  useGetComprehensivePerformanceQuery,
  useGetEvaluationReportRiskSummaryQuery,
  useGetIndustryAbilityEvaluationQuery,
  useGetManagerEvaluationQuery,
  useGetMarketCapabilityDetailQuery,
  useGetRiskDetailQuery,
  useGetStockSelectionAbilityQuery,
  useGetTurnoverImprovementAbilityQuery,
  useGetFundPerformanceQuery,
  useGetHotFundsQuery,
  useGetFundUpdateCompletedQuery
} = fundsApi;

const { getAllFund } = fundsApi.endpoints;

export const allFundsSelector = createSelector(getAllFund.select(), (result) => result?.data || []);
