import { createSelector } from '@betalpha/redux-toolkit';
import { AllFundBody } from '@/interface/fundDetail';
import {
  AbilitySummary,
  AntiRiskAbility,
  CompanyAbility,
  FundInfo,
  PerformanceDisassemble,
  Profitability,
  SelectionAbility,
  Stability,
  FundRecentPerformance,
  HotBondFunds
} from '@/interface/bond';
import baseApi from './base-api';
import { UpdateCompletedBody } from './etf-funds';

export const bondFundApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBondUpdateCompleted: builder.query<UpdateCompletedBody, void>({
      query: () => ({
        url: '/bond-fund/evaluation-report/mobile/update-completed',
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    getBondFundInfo: builder.query<FundInfo, string>({
      // 基本信息
      query: (fundCode) => ({
        url: `/bond-fund/evaluation-report/mobile/info/${fundCode}`,
        method: 'GET',
        options: { needAuth: false }
      })
    }),
    getBondProfitability: builder.query<Profitability, string>({
      // 收益能力
      query: (fundCode) => ({
        url: `/bond-fund/evaluation-report/mobile/ability-profitability/${fundCode}`,
        // url: `/bond-fund/evaluation-report/mobile/profitability/${fundCode}`,
        method: 'GET',
        options: { needAuth: false }
      })
    }),
    getBondAbilitySummary: builder.query<AbilitySummary, string>({
      // 能力评价总览
      query: (fundCode) => ({
        url: `/bond-fund/evaluation-report/mobile/ability-summary/${fundCode}`,
        // url: `/open-fund/evaluation-report/mobile/ability-summary/${fundCode}`,
        method: 'GET',
        options: { needAuth: false }
      })
    }),
    getBondDisassemble: builder.query<PerformanceDisassemble, string>({
      // 业绩拆解
      query: (fundCode) => ({
        url: `/bond-fund/evaluation-report/mobile/performance-disassemble/${fundCode}`,
        method: 'GET',
        options: { needAuth: false }
      })
    }),
    getBondStability: builder.query<Stability, string>({
      // 业绩稳定性
      query: (fundCode) => ({
        url: `/bond-fund/evaluation-report/mobile/ability-stability/${fundCode}`,
        method: 'GET',
        options: { needAuth: false }
      })
    }),
    getBondSelectionAbility: builder.query<SelectionAbility, string>({
      // 择券能力
      query: (fundCode) => ({
        url: `/bond-fund/evaluation-report/mobile/ability-selection/${fundCode}`,
        method: 'GET',
        options: { needAuth: false }
      })
    }),
    getBondAntiRiskAbility: builder.query<AntiRiskAbility, string>({
      // 抗风险能力
      query: (fundCode) => ({
        url: `/bond-fund/evaluation-report/mobile/ability-anti-risk/${fundCode}`,
        method: 'GET',
        options: { needAuth: false }
      })
    }),
    getBondCompanyAbility: builder.query<CompanyAbility, string>({
      // 公司实力
      query: (fundCode) => ({
        url: `/bond-fund/evaluation-report/mobile/ability-company/${fundCode}`,
        method: 'GET',
        options: { needAuth: false }
      })
    }),
    // 首页-五星基金近期表现
    getBondFundPerformance: builder.query<FundRecentPerformance, void>({
      query: () => ({
        url: `/bond-fund/evaluation-report/mobile/bond-fund/performance`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 首页-热门好基
    getHotBondFunds: builder.query<HotBondFunds, void>({
      query: () => ({
        url: `/bond-fund/evaluation-report/mobile/hot/bond-funds`,
        method: 'GET',
        options: {
          needAuth: false
        }
      })
    }),
    // 获取所有支持查看详情的bond fund
    getAllBondFund: builder.query<AllFundBody[], void>({
      query: () => ({
        url: `/bond-fund/evaluation-report/mobile/bond-funds`,
        method: 'GET',
        options: {
          showGlobalLoading: true,
          needAuth: false
        }
      })
    })
  })
});

export const {
  useGetBondFundInfoQuery,
  useGetBondProfitabilityQuery,
  useGetBondAbilitySummaryQuery,
  useGetBondDisassembleQuery,
  useGetBondStabilityQuery,
  useGetBondSelectionAbilityQuery,
  useGetBondAntiRiskAbilityQuery,
  useGetBondCompanyAbilityQuery,
  useGetBondFundPerformanceQuery,
  useGetHotBondFundsQuery,
  useGetAllBondFundQuery,
  useGetBondUpdateCompletedQuery
} = bondFundApi;

const { getAllBondFund } = bondFundApi.endpoints;

export const allBondFundsSelector = createSelector(getAllBondFund.select(), (result) => result?.data || []);
