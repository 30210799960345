import { apiSetup } from '@betalpha/redux-toolkit';
import { throttle } from 'lodash/fp';
import { message as Message } from 'antd';
import { getMessage } from '@betalpha/intl';
import { AppDispatch } from '../store';

import { showLoading as showLoadingAction, hideLoading as hideLoadingAction } from './global-slice';

const showLoading = (dispatch: AppDispatch, needShowLoading: boolean) => {
  if (needShowLoading) {
    dispatch(showLoadingAction());
  }
};

const hideLoading = (dispatch: AppDispatch, needShowLoading: boolean) => {
  if (needShowLoading) {
    global.setTimeout(() => {
      dispatch(hideLoadingAction());
    }, 0);
  }
};

const throttledShowError = throttle(60 * 1000)(Message.error);

apiSetup({
  isDev: process.env.NODE_ENV === 'development',
  mockBaseURL: 'https://yapi.betalpha.com/mock/113',
  requestLifeCycles: {
    beforeRequest: (thunkAPI, { showLoading: needShowLoading = true }) => {
      showLoading(thunkAPI?.dispatch as AppDispatch, needShowLoading);
    },
    afterRequestSuccess: (thunkAPI, { showLoading: needShowLoading = true }) => {
      hideLoading(thunkAPI.dispatch as AppDispatch, needShowLoading);
    },
    afterRquestFailure({ dispatch }, { showLoading: needShowLoading = true, showServerError = true }, response) {
      hideLoading(dispatch as AppDispatch, needShowLoading);
      const statusCode = response?.status;

      const error = response?.data;
      const errorMsg = error?.error || error?.errors?.[0]?.message;
      if (showServerError && errorMsg) {
        Message.error(errorMsg);
      } else if (statusCode === 502) {
        throttledShowError(getMessage('serverError'));
      } else if (statusCode === 504) {
        throttledShowError(getMessage('gatewayTimeoutError'));
      } else {
        throttledShowError(getMessage('systemError'));
      }
    }
  }
});
