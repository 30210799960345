import { getMessage } from '@betalpha/intl';
import { toAverage } from '@betalpha/stdlib/lib/math';
import { EChartsOption, BarSeriesOption, LineSeriesOption } from 'echarts';
import { concat, zip, map, flow, omit, prop, min, max, size } from 'lodash/fp';
import React, { useMemo, useRef } from 'react';
import { useSize } from 'ahooks';
import {
  formatNilMessage,
  LEVEL_MESSAGE_KEY_MAP_4,
  LEVEL_MESSAGE_KEY_MAP_5,
  LEVEL_MESSAGE_KEY_MAP_6
} from '@/views/fund-evaluation-detail/constants';
import { formatPercentage } from '@/util/numberFormatter';
import { useGetTurnoverImprovementAbilityQuery } from '@/infra/api/funds';
import { getBarChartDataZoomConfig } from '@/util/chart';
import Collapse from '@/components/collapse';
import ColorBlockTitle from '@/components/color-block-title';
import style from '../index.module.less';
import BaseEcharts from '../../../../../components/baseEcharts';
import SimilarRanking from '../../similarRanking';

const ECHART_NORMAL_SCREEN_WIDTH = 345;

type SerieDataType = {
  name: string;
  data: [string, number][];
};
export default React.memo(({ fundId }: { fundId: string }) => {
  const { data: turnoverImprovementAbility } = useGetTurnoverImprovementAbilityQuery(fundId, { skip: !fundId });

  const { rank, sameTypeCount, tradeAbility, extendNewStocksAbility } = turnoverImprovementAbility ?? {};

  const { turnoverAbility, dates, extraReturn, extraReturnSameAvg } = tradeAbility ?? {
    dates: [],
    extraReturn: [],
    extraReturnSameAvg: []
  };

  const tradeAbilitySerieData = useMemo<SerieDataType[]>(
    () => [
      {
        name: getMessage('theFund'),
        data: concat(zip(dates)(extraReturn))([[getMessage('threeYearsAvg'), toAverage(extraReturn)]]) as [
          string,
          number
        ][]
      },
      {
        name: getMessage('sameTypeFundAvgValue'),
        data: concat(zip(dates)(extraReturnSameAvg))([
          [getMessage('threeYearsAvg'), toAverage(extraReturnSameAvg)]
        ]) as [string, number][]
      }
    ],
    [dates, extraReturn, extraReturnSameAvg]
  );

  const { desireGrade, newStockQuality, newStockRatio, newStockReturnOfMarket, newStockTrend } =
    extendNewStocksAbility || {};

  const {
    dates: newStockTrendDates,
    newStockReturn,
    newStockReturnAvg,
    newStockRatio: newStockRatioTrend,
    newStockRatioAvg: newStockRatioTrendAvg
  } = newStockTrend ?? {
    dates: [],
    newStockReturn: [],
    newStockReturnAvg: [],
    newStockRatio: [],
    newStockRatioAvg: []
  };

  const extendNewStocksAbilityBarSerieData = useMemo<SerieDataType[]>(
    () => [
      {
        name: `${getMessage('theFund')}${getMessage('proceedsFromNewShares')}`,
        data: concat(zip(newStockTrendDates)(newStockReturn))([
          [getMessage('threeYearsAvg'), toAverage(newStockReturn)]
        ]) as [string, number][]
      },
      {
        name: `${getMessage('sameTypeFundAvgValue')}${getMessage('proceedsFromNewShares')}`,
        data: concat(zip(newStockTrendDates)(newStockReturnAvg))([
          [getMessage('threeYearsAvg'), toAverage(newStockReturnAvg)]
        ]) as [string, number][]
      }
    ],
    [newStockReturn, newStockReturnAvg, newStockTrendDates]
  );

  const extendNewStocksAbilityLineSerieData = useMemo<SerieDataType[]>(
    () => [
      {
        name: `${getMessage('theFund')}${getMessage('expansionRatio')}`,
        data: concat(zip(newStockTrendDates)(newStockRatioTrend))([
          [getMessage('threeYearsAvg'), toAverage(newStockRatioTrend)]
        ]) as [string, number][]
      },
      {
        name: `${getMessage('sameTypeFundAvgValue')}${getMessage('expansionRatio')}`,
        data: concat(zip(newStockTrendDates)(newStockRatioTrendAvg))([
          [getMessage('threeYearsAvg'), toAverage(newStockRatioTrendAvg)]
        ]) as [string, number][]
      }
    ],
    [newStockRatioTrend, newStockRatioTrendAvg, newStockTrendDates]
  );

  const chartWrapRef = useRef(null);
  const { width: chartWrapWidth } = useSize(chartWrapRef) ?? {};

  const isNeedHideZoomConfig = useMemo(
    () => ((chartWrapWidth ?? ECHART_NORMAL_SCREEN_WIDTH) / ECHART_NORMAL_SCREEN_WIDTH) * 65 > 100,
    [chartWrapWidth]
  );

  const tradeAbilityOptions = useMemo<EChartsOption>(
    () => ({
      grid: { left: 0, right: 0, top: 35, bottom: 120 },
      legend: { itemGap: 23 },
      xAxis: {
        axisTick: { show: false },
        boundaryGap: true,
        animation: false,
        axisLabel: {
          margin: 52,
          hideOverlap: false,
          fontWeight: 400,
          interval: 0
        }
      },
      yAxis: { show: false },
      series: map(
        (item: SerieDataType): BarSeriesOption => ({
          name: item.name,
          type: 'bar',
          animation: false,
          barWidth: 25,
          barGap: 0,
          label: {
            fontWeight: 500,
            fontSize: 10,
            color: '#999'
          },
          data: flow(
            omit('name'),
            map((v: [string, number]) => ({
              value: v,
              label: {
                position: v[1] >= 0 ? 'top' : 'bottom'
              }
            }))
          )(item.data)
        })
      )(tradeAbilitySerieData) as BarSeriesOption[],
      dataZoom: isNeedHideZoomConfig
        ? { show: false }
        : getBarChartDataZoomConfig(
            30,
            ((chartWrapWidth ?? ECHART_NORMAL_SCREEN_WIDTH) / ECHART_NORMAL_SCREEN_WIDTH) * 65
          )
    }),
    [tradeAbilitySerieData, isNeedHideZoomConfig, chartWrapWidth]
  );

  const barAxisMinVal = useMemo(
    () => min(concat(newStockReturn)(newStockReturnAvg)) ?? 0,
    [newStockReturn, newStockReturnAvg]
  );
  const barAxisMaxVal = useMemo(
    () => max(concat(newStockReturn)(newStockReturnAvg)) ?? 0,
    [newStockReturn, newStockReturnAvg]
  );
  const lineAxisMinVal = useMemo(
    () => min(concat(newStockRatioTrend)(newStockRatioTrendAvg)) ?? 0,
    [newStockRatioTrend, newStockRatioTrendAvg]
  );
  const lineAxisMaxVal = useMemo(
    () => max(concat(newStockRatioTrend)(newStockRatioTrendAvg)) ?? 0,
    [newStockRatioTrend, newStockRatioTrendAvg]
  );

  const extendNewStocksAbilityOptions = useMemo<EChartsOption>(
    () => ({
      grid: { left: 40, right: 50, bottom: 165, top: 40 },
      legend: [
        {
          textStyle: {
            height: 14,
            fontSize: 10,
            ...(chartWrapWidth ? { width: chartWrapWidth * 0.5 - 18 } : {}),
            overflow: 'break',
            padding: [4, 0, 0, 0],
            rich: {
              subText: {
                fontSize: 10,
                fontWeight: 400,
                color: '#ACB6C3'
              }
            }
          },
          // echarts overflow换行时富文本换行有bug; 手动计算宽度不够时加换行符
          formatter: (str) => {
            const textTotalWidth = str.length * 10;
            const textBlockWidth = (chartWrapWidth ?? 0) * 0.5 - 26;
            const isNeedNewLine = Math.abs((textTotalWidth % textBlockWidth) - textBlockWidth) < 18;
            return `${str}{subText|${isNeedNewLine ? '\n' : ''}(${getMessage('leftYAxis')})}`;
          },
          left: 'auto',
          padding: [0, 8, 0, 0],
          right: '50%',
          top: '85%',
          itemGap: 16,
          data: map((item: any) => item.name)(extendNewStocksAbilityBarSerieData)
        },
        {
          textStyle: {
            height: 10,
            fontSize: 10,
            ...(chartWrapWidth ? { width: chartWrapWidth * 0.5 - 26 } : {}),
            overflow: 'break',
            rich: {
              subText: {
                fontSize: 10,
                fontWeight: 400,
                color: '#ACB6C3'
              }
            }
          },
          formatter: (str) => {
            const textTotalWidth = str.length * 10;
            const textBlockWidth = (chartWrapWidth ?? 0) * 0.5 - 26;
            const isNeedNewLine = Math.abs((textTotalWidth % textBlockWidth) - textBlockWidth) < 18;
            return `${str}{subText|${isNeedNewLine ? '\n' : ''}(${getMessage('rightYAxis')})}`;
          },
          left: '50%',
          right: 0,
          padding: [0, 0, 0, 8],
          top: '85%',
          itemGap: 16,
          data: map((item: any) => item.name)(extendNewStocksAbilityLineSerieData),
          itemHeight: 4
        }
      ],
      xAxis: {
        type: 'category',
        axisTick: { show: false },
        animation: false,
        boundaryGap: true,
        axisLabel: {
          margin: 52,
          hideOverlap: false,
          fontWeight: 400,
          color: '#666',
          interval: 0
        }
      },
      yAxis: [
        {
          type: 'value',
          splitLine: { show: false },
          axisLabel: { fontWeight: 400, formatter: (val: number) => formatPercentage(val) },
          min: barAxisMinVal,
          max: barAxisMaxVal,
          interval: +((barAxisMaxVal - barAxisMinVal) / 5).toFixed(4)
        },
        {
          type: 'value',
          splitLine: { show: false },
          axisLabel: { fontWeight: 400, formatter: (val: number) => formatPercentage(val) },
          min: lineAxisMinVal,
          max: lineAxisMaxVal,
          interval: +((lineAxisMaxVal - lineAxisMinVal) / 5).toFixed(4)
        }
      ],
      series: [
        ...(map((item: SerieDataType) => ({
          name: item.name,
          type: 'bar',
          barWidth: 25,
          barGap: 0,
          animation: false,
          label: { fontSize: 10, fontWeight: 500, color: '#999' },
          data: map((v: SerieDataType['data'][number]) => ({
            value: v,
            label: {
              position: v[1] >= 0 ? 'top' : 'bottom'
            }
          }))(item.data)
        }))(extendNewStocksAbilityBarSerieData) as BarSeriesOption[]),
        ...map(
          (item: SerieDataType): LineSeriesOption => ({
            name: item.name,
            type: 'line',
            animation: false,
            yAxisIndex: 1,
            data: item.data
          })
        )(extendNewStocksAbilityLineSerieData)
      ],
      ...(size(newStockReturn) <= 4 && size(newStockRatioTrend) <= 4
        ? {}
        : {
            dataZoom: isNeedHideZoomConfig
              ? { show: false }
              : getBarChartDataZoomConfig(
                  80,
                  ((chartWrapWidth ?? ECHART_NORMAL_SCREEN_WIDTH) / ECHART_NORMAL_SCREEN_WIDTH) * 40
                )
          })
    }),
    [
      barAxisMaxVal,
      barAxisMinVal,
      chartWrapWidth,
      extendNewStocksAbilityBarSerieData,
      extendNewStocksAbilityLineSerieData,
      isNeedHideZoomConfig,
      lineAxisMaxVal,
      lineAxisMinVal,
      newStockRatioTrend,
      newStockReturn
    ]
  );
  return (
    <div className={style.PositionImprovementAbilityWrap}>
      <SimilarRanking rank={rank} count={sameTypeCount} className={style.SimilarRank} />
      <div className={style.SubContent}>
        <ColorBlockTitle className={style.SubTitle}>{getMessage('fundManagerStealthTradingAbility')}</ColorBlockTitle>
        <div className={style.Text}>
          <Collapse
            title={getMessage('ableTurnoverAbility', {
              ableTurnoverAbility: turnoverAbility ? getMessage(turnoverAbility > 0 ? 'able' : 'unable') : '--'
            })}
          >
            {getMessage('turnoverAbility', {
              turnoverAbility: formatPercentage(turnoverAbility)
            })}
          </Collapse>
        </div>
        <div className={style.ChartWrap}>
          <BaseEcharts options={tradeAbilityOptions} height={210} notMerge />
          <div className={style.ScrollBarMask} style={{ bottom: 29 }} />
        </div>
      </div>
      <div className={style.SubContent}>
        <ColorBlockTitle className={style.SubTitle}>{getMessage('fundManagersWillingnessAndAbility')}</ColorBlockTitle>
        <div className={style.Text}>
          <Collapse
            title={getMessage('desireGrade', {
              desireGrade: formatNilMessage(LEVEL_MESSAGE_KEY_MAP_4, desireGrade),
              newStockQuality: formatNilMessage(LEVEL_MESSAGE_KEY_MAP_5, newStockQuality)
            })}
          >
            {getMessage('fundManagersWillingnessAndAbilitySummaryForMobile', {
              newStockRatio: formatPercentage(prop('value')(newStockRatio)),
              newStockRatioRank: formatNilMessage(LEVEL_MESSAGE_KEY_MAP_6, prop('sameTypeRank')(newStockRatio)),
              newStockReturnOfMarket: formatPercentage(prop('value')(newStockReturnOfMarket)),
              newStockReturnOfMarketRank: formatNilMessage(
                LEVEL_MESSAGE_KEY_MAP_6,
                prop('sameTypeRank')(newStockReturnOfMarket)
              )
            })}
          </Collapse>
        </div>
        <div className={style.ChartWrap} ref={chartWrapRef}>
          <BaseEcharts options={extendNewStocksAbilityOptions} height={400} notMerge />
          {!(size(newStockReturn) <= 4 && size(newStockRatioTrend) <= 4) && <div className={style.ScrollBarMask} />}
        </div>
      </div>
    </div>
  );
});
