import { ReactComponent as computerIcon } from './computerIcon.svg';
import { ReactComponent as medicalBiologyIcon } from './medicalBiologyIcon.svg';
import { ReactComponent as foodBeverageIcon } from './foodBeverageIcon.svg';
import { ReactComponent as electricityNewEnergyIcon } from './electricityNewEnergyIcon.svg';
import { ReactComponent as coalIcon } from './coalIcon.svg';
import { ReactComponent as electricityPublicUtilitiesIcon } from './electricityPublicUtilitiesIcon.svg';
import { ReactComponent as nonBankFinanceIcon } from './nonBankFinanceIcon.svg';
import { ReactComponent as chemicalIcon } from './chemicalIcon.svg';
import { ReactComponent as realEstateIcon } from './realEstateIcon.svg';
import { ReactComponent as buildIcon } from './buildIcon.svg';
import { ReactComponent as forestryIcon } from './forestryIcon.svg';
import { ReactComponent as bankIcon } from './bankIcon.svg';
import { ReactComponent as nonFerrousMetalIcon } from './nonFerrousMetalIcon.svg';
import { ReactComponent as communicationIcon } from './communicationIcon.svg';
import { ReactComponent as electronIcon } from './electronIcon.svg';
import { ReactComponent as buildMaterialsIcon } from './buildMaterialsIcon.svg';
import { ReactComponent as carIcon } from './carIcon.svg';
import { ReactComponent as mediaIcon } from './mediaIcon.svg';
import { ReactComponent as steelIcon } from './steelIcon.svg';
import { ReactComponent as houseElectricalIcon } from './houseElectricalIcon.svg';
import { ReactComponent as warIndustryIcon } from './warIndustryIcon.svg';

const industryIcons: Record<string, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  computerIcon,
  medicalBiologyIcon,
  foodBeverageIcon,
  electricityNewEnergyIcon,
  coalIcon,
  electricityPublicUtilitiesIcon,
  nonBankFinanceIcon,
  chemicalIcon,
  realEstateIcon,
  buildIcon,
  forestryIcon,
  bankIcon,
  nonFerrousMetalIcon,
  communicationIcon,
  electronIcon,
  buildMaterialsIcon,
  carIcon,
  mediaIcon,
  steelIcon,
  houseElectricalIcon,
  warIndustryIcon
};

export default industryIcons;
