import { getMessage } from '@betalpha/intl';
import { isEqual, isNaN, isNil, replace } from 'lodash/fp';
import { fastProp } from '@betalpha/stdlib/base';
import { includeStr } from '@betalpha/stdlib/lib/base';
import { FactorSelectionType, SameTypeRankParam } from '@/interface/fundDetail';
import { formatPercentage } from '@/util/numberFormatter';
import ColorNumber from '../../components/colorNumber';
import style from './index.module.less';

export const CHART_COLOR = ['#1A396A', '#CFB47F', '#9CACBC', '#957F54'];

export const LEVEL_MESSAGE_KEY_MAP_1 = {
  HIGH: 'high',
  MEDIUM: 'moderate',
  LOW: 'low'
};

export const LEVEL_MESSAGE_KEY_MAP_2 = {
  HIGH: 'higher',
  MEDIUM: 'commonly',
  LOW: 'lower'
};

export const LEVEL_MESSAGE_KEY_MAP_3 = {
  HIGH: 'higherThan',
  MEDIUM: 'EQUAL',
  LOW: 'lowerThan'
};

export const LEVEL_MESSAGE_KEY_MAP_4 = {
  HIGH: 'strong',
  MEDIUM: 'commonly',
  LOW: 'weak'
};

export const LEVEL_MESSAGE_KEY_MAP_5 = {
  HIGH: 'preferably',
  MEDIUM: 'commonly',
  LOW: 'backward'
};

export const LEVEL_MESSAGE_KEY_MAP_6 = {
  HIGH: 'forward',
  MEDIUM: 'commonly',
  LOW: 'backward'
};

export const LEVEL_MESSAGE_KEY_MAP_7 = {
  HIGH: 'higher',
  MEDIUM: 'ORDINARY',
  LOW: 'POOR'
};

export const LEVEL_MESSAGE_KEY_MAP_8 = {
  HIGH: 'highSide',
  MEDIUM: 'middleSide',
  LOW: 'lowSide'
};

export const LEVEL_MESSAGE_KEY_MAP_9 = {
  HIGH: 'powerful',
  MEDIUM: 'commonly',
  LOW: 'weaker'
};

export const LEVEL_MESSAGE_KEY_MAP_10 = {
  HIGH: 'high',
  MEDIUM: 'commonly',
  LOW: 'low'
};

export const LEVEL_MESSAGE_KEY_MAP_11 = {
  HIGH: 'forward',
  MEDIUM: 'middleSide',
  LOW: 'backward'
};

export const getCompareValueMsgKey = ({ fundValue, sameTypeAvg }: Partial<FactorSelectionType> | undefined = {}) => {
  if (isNil(fundValue) || isNil(sameTypeAvg) || isNaN(fundValue) || isNaN(sameTypeAvg)) return '--';
  if (fundValue < sameTypeAvg) {
    return 'LOW';
  }
  if (isEqual(fundValue)(sameTypeAvg)) return 'MEDIUM';
  if (fundValue > sameTypeAvg) return 'HIGH';
  return '--';
};

export const formatNilMessage = (map: Record<string, string>, key: string | undefined) => {
  return key ? getMessage(map[key]) : '--';
};

export const factorRatingsOrder = [
  'fund_rank_performance', // "综合业绩",
  'fund_rank_selection', // "个股选择能力",
  'fund_rank_rebalance', // "调仓改善能力",
  'fund_rank_capture', // "行情捕获能力",
  'fund_rank_allocation' // "行业配置能力",
];
export const factorRatingsTabOrder = [
  'fund_rank_performance', // "综合业绩",
  'fund_rank_selection', // "个股选择能力",
  'fund_rank_allocation', // "行业配置能力",
  'fund_rank_rebalance', // "调仓改善能力",
  'fund_rank_capture' // "行情捕获能力",
];

export const factorRatingsOrderMap = {
  fund_rank_performance: 'fund_rank_performance', // "综合业绩",
  fund_rank_selection: 'fund_rank_selection', // "个股选择能力",
  fund_rank_rebalance: 'fund_rank_rebalance', // "调仓改善能力",
  fund_rank_capture: 'fund_rank_capture', // "行情捕获能力",
  fund_rank_allocation: 'fund_rank_allocation' // "行业配置能力",
};

export const managerFactorRatingsOrder = [
  'fund_unrank_performance', // "综合业绩分位数",
  'fund_unrank_selection', // "个股选择能力分位数",
  'fund_unrank_rebalance', // "调仓改善能力分位数",
  'fund_unrank_capture', // "行情捕获能力分位数",
  'fund_unrank_allocation' // "行业配置能力分位数",
];

export const getColumns = () => {
  const colorNumberRender = (val: string | number) => {
    if (typeof val === 'string' && includeStr('%')(val)) {
      return <ColorNumber value={+replace('%')('')(val) / 100} formatter={formatPercentage} />;
    }
    return <ColorNumber value={val as number} />;
  };
  return [
    {
      title: getMessage('assetType'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      align: 'left'
    },
    {
      title: getMessage('FundPerformance'),
      dataIndex: 'fundPerformance',
      key: 'fundPerformance',
      width: 150,
      align: 'right',
      render: colorNumberRender
    },
    {
      title: getMessage('MarketPerformance'),
      dataIndex: 'marketPerformance',
      key: 'marketPerformance',
      width: 150,
      align: 'right',
      render: colorNumberRender
    },
    {
      title: getMessage('rankByCategory'),
      dataIndex: 'sameTypeFactorRank',
      key: 'sameTypeFactorRank',
      width: 150,
      align: 'right',
      render: (data: SameTypeRankParam) => {
        return (
          <span className={style.RankByCategory}>
            <span className={style.RankNum}>{data?.rank}</span>/{data?.count}
          </span>
        );
      }
    }
  ];
};

export const getComprePerformanceOptions = (yearOfDates: Record<string, any>) => ({
  grid: { left: 0, top: 20, bottom: 60, right: 20 },
  xAxis: {
    boundaryGap: false,
    axisTick: {
      show: false
    },
    axisLabel: {
      hideOverlap: false,
      interval: (index: number, value: string) => {
        const year = fastProp(value)(yearOfDates);
        return !!year;
      },
      formatter(value: string) {
        return `${fastProp(value)(yearOfDates)}${getMessage('year')}`;
      },
      align: 'right'
    }
  },
  yAxis: {
    splitLine: { show: false },
    axisLabel: {
      fontWeight: 'bold',
      color: '#222222'
    }
  },
  legend: {
    left: 'center',
    bottom: 28,
    top: 'auto',
    itemGap: 30
    // padding: 0,
  }
});

export const RatePerformanceMap = {
  1: 'GOOD',
  2: 'ORDINARY',
  3: 'POOR'
};
export const dispersionDegreeMap = {
  HIGH: 'high',
  MEDIUM: 'moderate',
  LOW: 'low',
  HIGHER: 'HIGHER',
  LOWER: 'LOWER'
};

export const CompareLevel = {
  LOWER: 'lowerThan',
  HIGHER: 'overtop',
  EQUAL: 'EQUAL'
};
export const riskLevelMap = {
  1: 'highYieldLowRisk',
  2: 'matchBenefitsWithRisks',
  3: 'lowYieldAndLowRisk'
};
export const riskLevelMap1 = {
  1: 'higher',
  2: 'ORDINARY',
  3: 'POOR'
};

export const compareLevel = (firstVal: number | undefined, secondVal: number | undefined) => {
  if (isNil(firstVal) || isNil(secondVal)) return '--';
  if (firstVal > secondVal) {
    return getMessage('overtop');
  }
  if (firstVal < secondVal) {
    return getMessage('lowerThan');
  }
  if (firstVal === secondVal) {
    return getMessage('EQUAL');
  }
  return '--';
};
