import React, { FC, memo, ReactNode, useEffect, useState, useMemo } from 'react';
import cn from 'classnames';
import { ErrorBlock } from 'antd-mobile';
import { useParams } from '@betalpha/router';
import { normalize } from '@betalpha/stdlib/base';
import Footer from '@/views/footer';
import noResult from '@/assets/no-result.svg';
import { useTargetFunds } from '../fundSearch/search-popup';
import style from './index.module.less';

type PageProps = {
  // ...
  actionBar?: ReactNode;
  children?: ReactNode;
  className?: string;
  needFund?: boolean;
  type?: 'FUND_EVALUATION' | 'ETF_COMPASS' | 'BOND_FUND';
};

const Page: FC<PageProps> = ({ actionBar, children, className, needFund = false, type }) => {
  const [showPage, setPageStatus] = useState(true);
  const allFunds: any = useTargetFunds(type);
  const { fundId } = useParams();
  const fundMap = normalize(allFunds, 'fundId');
  const fund = useMemo(() => fundMap?.[fundId || ''], [fundMap, fundId]);
  useEffect(() => {
    if (needFund && !fund) {
      setPageStatus(false);
    } else {
      setPageStatus(true);
    }
  }, [fund, needFund]);
  if (showPage) {
    return (
      <div className={style.Container}>
        {actionBar}
        <div className={cn(style.Content, actionBar && style.ContentWithBar, className)}>{children}</div>
        {/* <div className={style.ActionArea}>sf</div> */}
        <Footer />
      </div>
    );
  }
  return (
    <div className={style.Container}>
      {actionBar}
      <ErrorBlock image={noResult} fullPage title="" description="抱歉！本基金不符合评级条件暂无评级" />
    </div>
  );
};

export default memo(Page);
