import { useParams } from '@betalpha/router';
import React, { useMemo } from 'react';
import { map } from 'lodash/fp';
import { getMessage } from '@betalpha/intl';
import { useGetETFFundBasicInfoQuery } from '@/infra/api/etf-funds';
import CardLayout from '@/components/cardLayout';
import { ReactComponent as managerSvg } from '@/assets/etfCompass/manager.svg';
import { ReactComponent as trackIndexSvg } from '@/assets/etfCompass/track-index.svg';
import { ReactComponent as currentScaleSvg } from '@/assets/etfCompass/current-scale.svg';
import { ReactComponent as fundShareSvg } from '@/assets/etfCompass/fund-share.svg';
import { ReactComponent as etfTypeSvg } from '@/assets/etfCompass/etf-type.svg';
import { ReactComponent as foundDateSvg } from '@/assets/etfCompass/found-date.svg';
import backgroundImgLeft from '@/assets/etfCompass/basic-info-bg-1.svg';
import backgroundImgRight from '@/assets/etfCompass/basic-info-bg-2.svg';
import Icon from '@/components/icon';
import { fixedNumber } from '@/util/numberFormatter';
import style from './index.module.less';

type InfoItem = {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  value: string | number | undefined;
};
const BasicInfo = React.memo(() => {
  const { fundId } = useParams();
  const { data, isFetching } = useGetETFFundBasicInfoQuery(fundId ?? '', { skip: !fundId });
  const { fundManager, trackIndexName, fundScale, fundShare, etfTypeName, foundDate } = data ?? {};
  const infos = useMemo<InfoItem[]>(
    () => [
      {
        label: getMessage('fundManager'),
        icon: managerSvg,
        value: fundManager
      },
      {
        label: getMessage('trackIndex'),
        icon: trackIndexSvg,
        value: trackIndexName
      },
      {
        label: getMessage('currentScale'),
        icon: currentScaleSvg,
        value: fixedNumber(+(fundScale ?? NaN))
      },
      {
        label: getMessage('fundPortion'),
        icon: fundShareSvg,
        value: fixedNumber(+(fundShare ?? NaN))
      },
      {
        label: getMessage('ETFCategory'),
        icon: etfTypeSvg,
        value: etfTypeName ? `${etfTypeName.slice(3, -2)}${getMessage('ETFTypeSuffix')}` : getMessage('unkownFundType')
      },
      {
        label: getMessage('foundDate'),
        icon: foundDateSvg,
        value: foundDate
      }
    ],
    [etfTypeName, foundDate, fundManager, fundScale, fundShare, trackIndexName]
  );

  return (
    <CardLayout loading={isFetching} title="" className={style.BasicInfoWrap}>
      <div className={style.BasicInfo}>
        <img className={style.BackgroundLeft} src={backgroundImgLeft} />
        <img className={style.BackgroundRight} src={backgroundImgRight} />
        {map((item: InfoItem) => (
          <div key={item.label} className={style.InfoItem}>
            <div className={style.LabelWrap}>
              <Icon svg={item.icon} />
              <div className={style.Label}>{item.label}</div>
            </div>
            <div className={style.Value}>{item.value || '--'}</div>
          </div>
        ))(infos)}
      </div>
    </CardLayout>
  );
});

export default BasicInfo;
