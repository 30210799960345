import React, { ReactNode, useMemo, useState } from 'react';
import { JumboTabs, JumboTabsProps } from 'antd-mobile';
import cn from 'classnames';
import { map } from 'lodash/fp';
import style from './index.module.less';

type TabProps = {
  children: ReactNode;
  isShowSwiper?: boolean;
} & JumboTabsProps;

export default React.memo<TabProps>(({ children, className, isShowSwiper, defaultActiveKey, ...restProps }) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);
  const childrenTabs = useMemo(() => map('key')(children as any), [children]);

  return (
    <div className={style.JumboTabs}>
      <JumboTabs className={cn(style.Tab, className)} {...restProps} activeKey={activeKey} onChange={setActiveKey}>
        {children}
      </JumboTabs>
      {isShowSwiper && (
        <div className={style.JumboSwiper}>
          {map((key: string) => (
            <div
              key={key}
              className={cn(style.SwiperItem, activeKey === key ? style.selectedItem : style.noSelectedItem)}
            />
          ))(childrenTabs || [])}
        </div>
      )}
    </div>
  );
});

export const { Tab } = JumboTabs;
