import React, { ReactNode, useImperativeHandle, useState } from 'react';
import { getMessage } from '@betalpha/intl';
import { Modal } from 'antd-mobile';
import { ReactComponent as questionImg } from '@/assets/fundEvaluationReport/question.svg';
import Icon from '../icon';
import style from './index.module.less';

export interface DetailModalProps {
  title?: ReactNode;
  content?: ReactNode;
  needTrigger?: boolean;
  triggerClassName?: string;
}
const DetailModal = React.forwardRef<{ open: (props: DetailModalProps) => void }, DetailModalProps>(
  ({ title: initalTitle, content: initialContent, needTrigger = true, triggerClassName }, ref) => {
    const [openModal, setOpenModal] = useState(false);
    const [title, setTitle] = useState<ReactNode>(initalTitle);
    const [content, setContent] = useState<ReactNode>(initialContent);

    useImperativeHandle(
      ref,
      () => ({
        // eslint-disable-next-line @typescript-eslint/no-shadow
        open: ({ title, content }) => {
          setOpenModal(true);
          setTitle(title);
          setContent(content);
        }
      }),
      []
    );
    return (
      <>
        <Modal
          visible={openModal}
          content={content}
          title={title}
          className={style.Modal}
          closeOnAction
          getContainer={document.body}
          closeOnMaskClick
          onClose={() => {
            setOpenModal(false);
          }}
          actions={[
            {
              key: 'confirm',
              text: getMessage('modalOKText'),
              className: style.OkBtn
            }
          ]}
        />
        {needTrigger && (
          <Icon
            svg={questionImg}
            className={triggerClassName}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpenModal(true);
            }}
          />
        )}
      </>
    );
  }
);

export default DetailModal;
