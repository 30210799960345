import React from 'react';
import cn from 'classnames';
import { isNumber } from 'lodash/fp';
import { ReactComponent as StarSvg } from '@/assets/fundEvaluationReport/starWithoutFillColor.svg';
import Icon from '../icon';
import style from './index.module.less';

export default React.memo(
  ({ value, className, wrapClassName }: { value: number | undefined; className?: string; wrapClassName?: string }) => (
    <div className={cn(style.RateWrap, wrapClassName)}>
      {isNumber(value) &&
        new Array(Math.round(value)).fill(0).map((_: any, idx: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Icon svg={StarSvg} className={cn(style.Star, className)} key={value - idx} />
        ))}
    </div>
  )
);
