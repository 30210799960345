import { createSlice, getActionType } from '@betalpha/redux-toolkit';

interface State {
  industryKey: string;
}
const etfSlice = createSlice({
  name: getActionType(__filename),
  initialState: {
    industryKey: 'keyFocusIndustry'
  },
  reducers: {
    changeIndustryKey: (state: State, action) => {
      state.industryKey = action.payload;
    }
  }
});
export const { changeIndustryKey } = etfSlice.actions;

export default etfSlice.reducer;
