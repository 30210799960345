import React from 'react';
import Icon from '@ant-design/icons';

interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
  style?: React.CSSProperties;
  fill?: string;
  size?: string | number;
  svg: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export default ({ svg, fill, size, style, ...restProps }: IconProps) => {
  const constructStyle = {
    ...style,
    fontSize: size,
    color: fill
  };
  return <Icon component={svg} style={constructStyle} {...restProps} />;
};
