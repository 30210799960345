import { findIndex, first, last, omit, slice, size, set, merge } from 'lodash/fp';
import dayjs from 'dayjs';
import { mapIndexed } from '@betalpha/stdlib/lib/base';
import cumulativeReturns from '@betalpha/stdlib/lib/quant/cumulativeReturns';
import dynamicMaxDrawdown from '@betalpha/stdlib/lib/quant/dynamicMaxDrawdown';
import { formatPercentage } from '@/util/numberFormatter';
import { getDateReturnFitTargetDates } from '@/util/business-core/dailyReturn';
import { dataZoomConfig, tickLineColor, xAxisLabelColor } from '../helper';
import { ChartProps, ValueType, SeriesOption } from './type';

export const valueFormatter = (type?: ValueType, formatter?: any) => (value: number) => {
  if (type === 'netValue') {
    return formatPercentage(value, { digit: 4, symbol: '', scale: 1 });
  }
  if (formatter) {
    return formatter(value);
  }
  return formatPercentage(value);
};

export const getSplitNumber = ({
  splitNumber,
  height,
  showLegend,
  showDataZoom
}: Pick<ChartProps, 'splitNumber' | 'height' | 'showLegend' | 'showDataZoom'>) => {
  if (splitNumber) {
    return splitNumber;
  }
  const availableHeight = (height || 300) - (showLegend ? 50 : 20) - (showDataZoom ? 60 : 12);
  return Math.floor(availableHeight / 50);
};

export const createChartOptionTitle = (title: ChartProps['title']) => {
  if (title) {
    return {
      text: title,
      textAlign: 'left',
      textStyle: { fontWeight: 'normal', fontSize: 14 }
    };
  }
  return { text: '' };
};

export const createChartOptionTooltip = (
  type?: ChartProps['type'],
  formatter?: unknown,
  tooltipOptions?: ChartProps['tooltipOptions']
) =>
  merge({
    trigger: 'axis',
    valueFormatter: valueFormatter(type, formatter),
    backgroundColor: 'rgba(255,  255, 255, 0.7)'
  })(tooltipOptions);

export const createChartOptionGrid = ({ gridOptions }: Pick<ChartProps, 'gridOptions'>) =>
  merge({
    top: 56,
    bottom: 0,
    left: -6,
    right: 0,
    containLabel: true
  })(gridOptions);

export const createChartOptionDataZoom = (showDataZoom: ChartProps['showDataZoom']) =>
  showDataZoom ? dataZoomConfig : null;

export const createChartOptionToolbox = (saveImgEnable: ChartProps['saveImgEnable']) => ({
  feature: {
    saveAsImage: saveImgEnable ? {} : null
  },
  right: 20
});

export const createChartOptionDateXAxis = ({
  dates,
  boundaryGap,
  dateMode,
  xAxisOptions
}: Pick<ChartProps, 'dates' | 'boundaryGap' | 'dateMode' | 'xAxisOptions'>) => {
  let option = {
    type: 'category',
    data: dates,
    boundaryGap: boundaryGap || false,
    position: 'bottom',
    splitLine: {
      // show: false,
    },
    axisLine: {
      show: true,
      onZero: false,
      lineStyle: {
        color: tickLineColor
      }
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: tickLineColor
      }
    },
    axisLabel: {
      margin: 14,
      color: xAxisLabelColor,
      hideOverlap: true,
      interval: Math.floor(size(dates) / 2),
      fontFamily: 'DINAlternate',
      showMinLabel: true,
      fontSize: 10,
      fontWeight: 400,
      showMaxLabel: true,
      formatter(value: string, idx: number) {
        if (idx === size(dates) - 1) {
          return `{alignRightLabel|${value}}`;
        }
        if (idx === 0) {
          return `{alignLeftLabel|${value}}`;
        }
        return value;
      },
      rich: {
        alignRightLabel: {
          fontFamily: 'DINAlternate',
          color: xAxisLabelColor,
          padding: [0, 48, 0, 0],
          fontWeight: 400,
          fontSize: 10
        },
        alignLeftLabel: {
          fontFamily: 'DINAlternate',
          color: xAxisLabelColor,
          padding: [0, 0, 0, 48],
          fontWeight: 400,
          fontSize: 10
        }
      }
    }
  };
  if (dateMode === 'twoLine') {
    option = merge(option)({
      axisLabel: {
        interval: 'auto',
        formatter(value: string) {
          return `${dayjs(value).format('YYYY')}\n${dayjs(value).format('MM-DD')}`;
        },
        lineHeight: 18
      }
    });
  }
  return merge(option)(xAxisOptions);
};

export const createBarChartOptionDateXAxis = ({ dates }: Pick<ChartProps, 'dates'>) => ({
  type: 'category',
  data: dates,
  position: 'bottom',
  axisLine: {
    show: true,
    onZero: false,
    lineStyle: {
      color: tickLineColor
    }
  },
  axisTick: {
    show: false
  },
  axisLabel: {
    margin: 15,
    color: xAxisLabelColor,
    formatter(value: string) {
      return `${dayjs(value).format('YYYY')}\n${dayjs(value).format('MM-DD')}`;
    },
    lineHeight: 18
  }
});

export const createChartOptionValueYAxis = ({
  splitNumber,
  height,
  showLegend,
  showDataZoom,
  type,
  formatter,
  yAxisOptions
}: Pick<
  ChartProps,
  'type' | 'splitNumber' | 'height' | 'showLegend' | 'showDataZoom' | 'formatter' | 'yAxisOptions'
>) =>
  merge({
    type: 'value',
    offset: -6,
    splitNumber: getSplitNumber({
      splitNumber,
      height,
      showLegend,
      showDataZoom
    }),
    splitLine: {
      show: true,
      lineStyle: {
        color: '#ECECEC',
        type: 'dotted'
      }
    },
    axisLabel: {
      fontWeight: 400,
      color: '#222222',
      fontFamily: 'DINAlternate',
      formatter: valueFormatter(type, formatter)
    }
  })(yAxisOptions);

export const createChartOptionLegend = ({
  showLegend,
  legendOptions
}: Pick<ChartProps, 'showLegend' | 'legendOptions'>) =>
  merge({
    top: 0,
    icon: 'path://M30 0 L200 0 Q 230 30 200 60 L30 60 Q 0 30 30 0 Z',
    left: 'left',
    itemHeight: 4,
    itemWidth: 11,
    show: showLegend
  })(legendOptions);

export const getChartOption = ({
  title,
  height,
  showLegend,
  showDataZoom,
  saveImgEnable,
  dates,
  series,
  splitNumber,
  boundaryGap,
  dateMode,
  type,
  formatter,
  gridOptions,
  legendOptions,
  xAxisOptions,
  yAxisOptions,
  graphicOptions,
  tooltipOptions
}: ChartProps) => {
  const result = {
    title: createChartOptionTitle(title),
    tooltip: createChartOptionTooltip(type, formatter, tooltipOptions),
    legend: createChartOptionLegend({ showLegend, legendOptions }),
    grid: createChartOptionGrid({
      gridOptions
    }),
    dataZoom: createChartOptionDataZoom(showDataZoom),
    toolbox: createChartOptionToolbox(saveImgEnable),
    xAxis: createChartOptionDateXAxis({
      dates,
      boundaryGap,
      dateMode,
      xAxisOptions
    }),
    yAxis: createChartOptionValueYAxis({
      splitNumber,
      height,
      showLegend,
      showDataZoom,
      type,
      formatter,
      yAxisOptions
    }),
    series,
    ...(graphicOptions ? { graphic: graphicOptions } : {})
  };
  return result;
};

export const generateSerieDataOfYieldTrendChart = (
  yields: number[],
  dates: string[]
): [date: string, value: number][] => {
  return mapIndexed((date: string, index: number) => [date, yields?.[index]])(dates);
};

const generateSerieFieldData = (targetDates: string[], dates: string[], returns: number[], fillEmptyDate: boolean) => {
  const serieData = mapIndexed((date: string, index: number) => [
    date,
    returns?.[index] === undefined ? null : returns?.[index]
  ])(dates);
  if (!fillEmptyDate) return serieData;
  // 前置空数据补足
  const endIndexFromLeft = findIndex((date) => date === first(dates))(targetDates);
  // 后置空数据补足
  const startIndexFromEnd = findIndex((date) => date === last(dates))(targetDates);
  return [
    ...mapIndexed((date: string) => [date, 0])(slice(0, endIndexFromLeft)(targetDates)),
    ...serieData,
    ...mapIndexed((date: string) => [date, 0])(slice(startIndexFromEnd + 1, size(targetDates) + 1)(targetDates))
  ];
};

export const generateDataFieldInSerie =
  (quantType: 'cumulative' | 'maxDrowdown') =>
  (targetDates: string[], type: ValueType, fillEmptyDate: boolean) =>
  (serie: SeriesOption): SeriesOption => {
    let data = [];
    if (serie.data) {
      data = serie.data;
    } else if (serie.dates && serie.returns) {
      data = generateSerieFieldData(targetDates, serie.dates, serie.returns, fillEmptyDate);
    } else if (serie.dates && serie.dailyReturns) {
      const result = getDateReturnFitTargetDates(targetDates, {
        dates: serie.dates,
        dailyReturns: serie.dailyReturns
      });
      let resultReturns: number[] = [];
      switch (quantType) {
        case 'cumulative':
          resultReturns = result.dailyReturns
            ? cumulativeReturns(set(0, 0)(result.dailyReturns), type === 'yield')
            : [];
          break;
        case 'maxDrowdown':
          resultReturns = result.dailyReturns ? dynamicMaxDrawdown(set(0, 0)(result.dailyReturns)) : [];
          break;
        default:
          break;
      }
      data = generateSerieFieldData(targetDates, result.dates || [], resultReturns, fillEmptyDate);
    }
    return {
      ...(omit(['data', 'dates', 'dailyReturns', 'returns'])(serie) as SeriesOption),
      data
    };
  };
