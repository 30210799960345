import React, { useRef } from 'react';
import { getMessage } from '@betalpha/intl';
import SearchPopup, { SearchPopupProps, SearchPopupRef } from './search-popup';
import style from './index.module.less';

export default React.memo<Pick<SearchPopupProps, 'type'>>(({ type = 'FUND_EVALUATION' }) => {
  const searchPopupRef = useRef<SearchPopupRef>(null);
  return (
    <>
      <div className={style.DefaultTrigger} onClick={() => searchPopupRef.current?.openPopup()}>
        {getMessage(type === 'ETF_COMPASS' ? 'etfSearchPlaceholderMobile' : 'fundSearchPlaceholderMobile')}
      </div>
      <SearchPopup type={type} ref={searchPopupRef} />
    </>
  );
});
