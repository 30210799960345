import { getMessage } from '@betalpha/intl';
import React from 'react';
import { prop } from 'lodash/fp';
import CardLayout from '@/components/cardLayout';
import DetailModal from '@/components/detail-modal';
import { useGetIndustryModelQuery } from '@/infra/api/etf-funds';
import { tipModalMessage } from '../../contants';
import FocusIndustry from './components/focusIndustry';
import EtfModel from './components/etfModel';
import style from './index.module.less';

export default React.memo(() => {
  const { isFetching } = useGetIndustryModelQuery();
  return (
    <CardLayout
      title={
        <div className={style.TitleWrap}>
          <span>{getMessage('ETFModelIndustryRecommend')}</span>
          <DetailModal
            title={getMessage('ETFModelIndustryRecommend')}
            content={
              <div>
                <div>{prop('ETFModelIndustryRecommend')(tipModalMessage)}</div>
                <div className={style.FocusIndustryName}>1、{prop('focusKeyIndustry')(tipModalMessage)}</div>
                <div className={style.FocusIndustryTip}>{prop('focusKeyIndustryTip')(tipModalMessage)}</div>
                <div className={style.FocusIndustryName}>2、{prop('focusIndustry')(tipModalMessage)}</div>
                <div className={style.FocusIndustryTip}>{prop('focusIndustryTip')(tipModalMessage)}</div>
              </div>
            }
            triggerClassName={style.Question}
          />
        </div>
      }
      loading={isFetching}
      className={style.CardLayout}
    >
      <FocusIndustry />
      <EtfModel />
    </CardLayout>
  );
});
