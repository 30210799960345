import { getMessage } from '@betalpha/intl';
import React from 'react';
import { mapIndexed } from '@betalpha/stdlib/base';
import Tabs, { Tab } from '@/components/tabs';

import { etfModalType } from '@/views/fund-compass-home/contants';
import style from '../index.module.less';
import EtfModelDetail from './etfModelDetail';

type industryTabProp = {
  id: string;
  message: string;
  summary: string;
};

export default React.memo(() => {
  return (
    <Tabs className={style.IndustryTabs} defaultActiveKey="momentumModelIndustry">
      {mapIndexed((item: industryTabProp, idx: number) => (
        <Tab key={item?.id || idx} title={getMessage(item?.message)} description={undefined}>
          <EtfModelDetail key={item?.id} id={item?.id} summary={item?.summary} />
        </Tab>
      ))(etfModalType)}
    </Tabs>
  );
});
