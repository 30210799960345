import React, { useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { graphic, EChartsOption } from 'echarts';
import { first, identity, isEmpty, isNumber, last, merge, zip } from 'lodash/fp';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';
import ColorBlockTitle from '@/components/color-block-title';
import { ETFRiskProbeBody } from '@/interface/etfFund';
import BaseEcharts from '@/components/baseEcharts';
import EmptyContent from '@/components/emptyContent';
import { useCalculateMarkLabelPosition, useGetDefaultChartOptions } from '../../hooks';
import style from './index.module.less';

interface ValuationDimensionProps {
  data: ETFRiskProbeBody['valuationDimension'] | undefined;
}
export default React.memo<ValuationDimensionProps>(({ data }) => {
  const { dates, historyValues, currentValue } = data?.trackIndexTrend ?? { dates: [], historyValues: [] };
  const chartData = useMemo(() => zip(dates)(historyValues) as [string, number][], [dates, historyValues]);

  const defaultOptions = useGetDefaultChartOptions(dates);
  const options = useMemo<EChartsOption>(
    () =>
      merge({
        grid: { left: 22, top: 32, bottom: 24, right: 0 },
        legend: {
          top: 0,
          bottom: 'auto',
          selectedMode: false,
          left: 0,
          icon: 'path://M30 0 L200 0 Q 230 30 200 60 L30 60 Q 0 30 30 0 Z'
        },
        xAxis: {
          axisLine: { show: false }
        },
        yAxis: {
          axisLabel: { formatter: identity, fontWeight: 'lighter', fontSize: 10, color: '#666' },
          axisLine: { show: false, onZero: false },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ECECEC',
              type: 'dotted'
            }
          }
        },
        series: {
          type: 'line',
          name: getMessage('trackIndexValuation'),
          color: '#CFB47F',
          data: chartData,
          silent: true,
          markLine: {
            symbol: 'none',
            lineStyle: {
              type: 'dotted',
              color: '#244579',
              width: 2
            },
            data: [
              {
                yAxis: last(chartData)?.[1] ?? NaN,
                label: {
                  show: false
                }
              }
            ]
          },
          areaStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#FDF5E7'
              },
              {
                offset: 1,
                color: '#FFFFFF'
              }
            ])
          }
        }
      })(defaultOptions),
    [chartData, defaultOptions]
  );

  const { onChartReady, markLabelPositionInfo, triangleNode } = useCalculateMarkLabelPosition('#244579', '#1a396a', 56);

  return (
    <div className={style.ValuationDimensionWrap}>
      <div className={style.TabDescription}>{getMessage('valuationDimensionTips')}</div>
      <div className={style.ContentWrap}>
        <ColorBlockTitle className={style.Title}>
          <div>{getMessage('trackIndexValuationTrend')}</div>
          {!isEmpty(dates) && <div className={style.Range}>{`(${first(dates)}~${last(dates)})`}</div>}
        </ColorBlockTitle>
        {data ? (
          <div className={style.ChartWrap}>
            <BaseEcharts className={style.Chart} options={options} height={184} onChartReady={onChartReady} />
            {isNumber(currentValue) && (
              <div className={style.MarkWrap}>
                <div className={style.MarkLabel} style={{ top: markLabelPositionInfo?.[0].top }}>
                  <div className={style.Value}>{percentage(currentValue)}</div>
                  <div className={style.Label}>{getMessage('indexCurrentQuantile')}</div>
                </div>
                {triangleNode}
              </div>
            )}
          </div>
        ) : (
          <EmptyContent message={getMessage('noData')} />
        )}
      </div>
    </div>
  );
});
